
export const tableHeaders= [
    { name: 'USER', style: { minWidth: '180px' }, key: 'firstName' },
    { name: 'JOB', style: { minWidth: '140px' }, key: 'job' },
    { name: 'PHONE NUMBER', style: { minWidth: '120px' }, key: 'phoneNo' },
    { name: 'GENDER', style: { minWidth: '60px' },key: 'gender' },
    { name: 'AGE', style: { minWidth: '100px' },key: 'dob' },
    { name: 'CITY', style: { minWidth: '90px' }, key: 'primaryCity' },
    { name: 'INCOME', style: { minWidth: '100px' }, key: 'salary' },
    { name: 'INSTAGRAM', style: { minWidth: '120px' },key: 'instagram' },
    { name: 'LINKEDIN', style: { minWidth: '120px' },key: 'linkedin' },
    { name: 'DATE APPLIED', style: { minWidth: '100px' },key: 'dateCreated' },
    {name: 'FLAGGED', style: { minWidth: '100px' },key: 'flag' },
  ];