import React, { useState, useEffect } from "react";
import "./style.css";
import { SubmitHandler, useForm } from "react-hook-form";
import { ResetData } from "@/@Interfaces/SignIn";
import Button from "@components/Button";
import { useNavigate } from "react-router-dom";
import logo from "@images/logo.svg";
import Footer from "@components/Footer";
import Input from "@components/Input";
import { useLocation } from "react-router-dom";
import { resetPasswordApi } from "@/services/resetPassword";
import Loader from "../Spinner";
import showToast from "../cogo-toast";

const ResetPassword: React.FC = () => {
  const [pw, setPw] = useState("");
  const [cpw, setCPw] = useState("");
  const [token, setToken] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [errorCode, setErrorCode] = useState(0);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: formValues,
  } = useForm<ResetData>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<ResetData> = async (data: ResetData) => {
    setLoading(true);
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    const isValid = regex.test(data.n_password);
    if (pw !== cpw) {
      setPasswordMismatch(true);
      showToast("Password did not match", "error")
      setLoading(false);
      return;
    } 
    else if (isValid) {
      setPasswordValidation(false);
      setPasswordMismatch(false);
      try {
        const response = await resetPasswordApi(token, data.n_password);
        navigate("/portal/password-changed");
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        const errorMessage =
          error.response?.data?.message || "Error during authentication";
        setErrorCode(error.response?.data?.code)
      }
    } else if (!isValid) {
      setLoading(false);
      setPasswordValidation(true);
      showToast("Password validation failed", "error")
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get("token");
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, [location.search]);

  const handleNewPasswordChange = (value: string) => {
    setPw(value);
  };
  const handleConfirmPasswordChange = (value: string) => {
    setCPw(value);
  };

  const handleNext = () => {
    formValues("n_password", pw);
    formValues("c_password", cpw);
  };

  return (
    <section className="reset-password-section">
      <div className="reset-content">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-9">
            <div className="reset-login-content">
              <div className="reset-login-header">
                <img src={logo} />
              </div>
              <div className="reset-password-header">
                <h6>Reset password</h6>
              </div>
              <div style={{ backgroundColor: " #1a1a1c" }}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="reset-form-parent"
                >
                  <div className="reset-login-form">
                    <Input
                      type="password"
                      id="n_password"
                      placeholder=""
                      register={register}
                      label="New password"
                      error={(errors as any).n_password?.message}
                      value={pw}
                      onChange={handleNewPasswordChange}
                      passwordValidation={passwordValidation}
                      maxLength={25}
                    />
                    {/* <div className="new-pass-form-text">
                      Cannot be a previously used password
                    </div> */}
                    {errorCode === 10029 ? <div className="pw-already-used">Cannot be a previously used password</div> : <div className="new-pass-form-text">
                      Cannot be a previously used password
                    </div>}
                    <Input
                      type="password"
                      id="c_password"
                      placeholder=""
                      register={register}
                      label="Confirm password"
                      error={(errors as any).c_password?.message}
                      value={cpw}
                      onChange={handleConfirmPasswordChange}
                      maxLength={25}
                      isError={passwordMismatch}
                    />
                    <div className="form-password-text">
                      Password must contain:
                      <ul>
                        <li>Minimum of 6 characters</li>
                        <li>At least one uppercase letter</li>
                        <li>At least one special character</li>
                      </ul>
                    </div>
                  </div>
                  <div className="reset-btn-wrapper">
                  <button
                      type="submit"
                      onClick={handleNext}
                      className="login-button"
                      style={{
                        padding: loading ? "0px 176.5px" : "0px 167.5px",
                      }}
                    >
                      {loading === true ? <Loader /> : "RESET"}
                    </button>
                 
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "5rem" }}> </div>
      </div>
      <Footer></Footer>
    </section>
  );
};

export default ResetPassword;
