import React, { useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { NavigationPaths } from "@/constants/paths";
import { StylesConfig } from "react-select";
import {
  CampaignIcon,
  ExitIcon,
  calenderIcon,
  searchIcon,
} from "@/constants/utils/svgs";
import Modal from "../ExitModal";
import DatePicker from "react-datepicker";
import Input from "../Input";
import { countries } from "@/constants/countries";
import Button from "../Button";
import Loader from "../Spinner";
import SelectWrapper from "../selectWrapper";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { updateLocation } from "@/redux/slices/locationSlice";
import {
  BenefitFormData,
  BenefitStepTwoProps,
} from "@/@Interfaces/BenefitsProps";
import { onlineOptions } from "@/constants/onlineOptions";

const BenefitStepsTwo: React.FC<BenefitStepTwoProps> = ({
  setSteps,
  isOnline,
  urlError,
  setUrlError,
  setIsOnline,
  loading,
  locationInfo,
  setLocationInfo,
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
  rawAddress,
  setRawAddress,
  handleSubmitCampaign,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [dateError, setDateError] = useState<string | null>(null);
  const [value, setValue] = useState<any | null>(null);
  const API_KEY = process.env.REACT_APP_BASE_API_KEY;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState<string>("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const {
    register,
    formState: { errors },
    setValue: formValues,
  } = useForm<BenefitFormData>({
    mode: "onChange",
  });

  const country_country = useSelector(
    (state: any) => state.applicant.dropdownOption
  );
  const city = useSelector((state: any) => state.location.city);
  const addressLine1 = useSelector((state: any) => state.location.addressLine1);
  const addressLine2 = useSelector((state: any) => state.location.addressLine2);
  const postalCode = useSelector((state: any) => state.location.postalCode);
  const latitudes_1 = useSelector((state: any) => state.location.latitudes);
  const longitudes_1 = useSelector((state: any) => state.location.longitudes);

  useEffect(() => {
    setSelectedCountries(locationInfo?.country);
  }, [locationInfo]);

  useEffect(() => {
    if (city) {
      formValues("addressLine1", addressLine1);
      formValues("addressLine2", addressLine2);
      formValues("city", city);
      formValues("postalCode", postalCode);
      formValues("coordinates", `${longitudes_1},${latitudes_1}`);
    }
  }, []);

  useEffect(() => {
    //extract city, country and address from the selected location
    if (value && value.label) {
      setRawAddress(value);
      const formattedAddress = value.label;
      const addressParts = formattedAddress.split(", ");
      const country = addressParts.pop() || "";
      const city = addressParts.pop() || "";
      const address = addressParts.join(", ");

      geocodeByAddress(address)
        .then((results) => {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();

          setLocationInfo((prevState) => ({
            ...prevState,
            address: address,
            latitudes: lat,
            longitudes: lng,
          }));
        })
        .catch((error) => {
          console.error(error)       
        });
      setLocationInfo((prevState) => ({
        ...prevState,
        country: country,
        city: city,
        addressLine1: address,
      }));
    }
  }, [value]);

  useEffect(() => {
    if (locationInfo.addressLine1) {
      formValues("addressLine1", locationInfo.addressLine1);
      formValues("city", locationInfo.city);
      if(locationInfo.latitudes != null){
        formValues(
          "coordinates",
          `${locationInfo.longitudes},${locationInfo.latitudes}`
        );
      }
    }
  }, [locationInfo]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExit = () => {
    navigate(NavigationPaths.BENEFITS);
  };

  const onSubmit: SubmitHandler<BenefitFormData> = async (
    data: BenefitFormData
  ) => {
    //split coordinates in longitude, latitude form
    const coordinates = data.coordinates
      .split(",")
      .map((coord: any) => parseFloat(coord.trim()));
    const [longitudes, latitudes] = coordinates;
    dispatch(
      updateLocation({
        ...data,
        address: value,
        country: country_country,
        longitudes: longitudes,
        latitudes: latitudes,
        eventDate: selectedStartDate,
        endDate: selectedEndDate,
      })
    );
    setSteps(3);
  };

  const handleBack = () => {
    setSteps(1);
  };

  const customStyles: StylesConfig<any, false> = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      // backgroundColor: "#1f1f22",
      borderColor: state.isFocused ? "none" : "#8E929A",
      boxShadow: state.isFocused ? "0 0 0 1px #BBA383" : "none",
      backgroundColor: "transparent",
      borderRadius: "6px",
      height: "48px",
      padding: "5px 0px",
      "&:focus": {
        outline: "1px solid #BBA383",
        border: "none !important",
      },
      "&:hover": {
        outline: "none",
        borderColor: "#8E929A",
        boxShadow: "none !important",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#BBA383" : "#1f1f22",
      color: state.isSelected ? "white" : "inherit",
      "&:hover": {
        backgroundColor: "#BBA383",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      marginLeft: "25px",
      fontSize: "12px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#1f1f22",
      color: "white",
      zIndex: 9999,
      fontSize: "12px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      // display: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      // display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "12px",
      marginLeft: "25px",
    }),
  };

  const handleLocationChange = (e: any) => {
    const { name, value } = e.target;
    setLocationInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleStepTwoSubmit = (e: any) => {
    e.preventDefault();
    setFormSubmitted(true);
  };

  return (
    <section className="benefit-details-section w-100">
      <div className="row">
        <div className="col-lg-6 col-md-9 p-0 adjust-height-fhbsh">
          <div className="benefit-form-2-section">
            <div className="benefit-form-header">
              <h2>Benefit Details</h2>
              <p>Step 2 of 2</p>
            </div>
            <form className="form-area" onSubmit={handleStepTwoSubmit}>
              <div className="benefit-form-wrapper row mb-4">
                <div className="col-6">
                  <label className="form-label">Benefit Date(s) *</label>
                  <DatePicker
                    showIcon
                    toggleCalendarOnIconClick
                    icon={calenderIcon()}
                    placeholderText="MM.DD.YY - MM.DD.YY"
                    dateFormat="MM-dd-yy"
                    minDate={new Date()}
                    selectsRange={true}
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    onChange={(update: any) => {
                      if (update) {
                        setSelectedStartDate(update[0]);
                        setSelectedEndDate(update[1]);
                        setDateRange(update);
                      } else {
                        setDateError("Benefit date required");
                      }
                    }}
                  />
                  {formSubmitted && !selectedStartDate && (
                    <div className="my-1 error-text-asd errorField">
                      Benefit date required
                    </div>
                  )}
                </div>
                <div className="benefits-categories-wrapper benefit-online col-6">
                  <label className="form-label">Is the benefit online? *</label>
                  <SelectWrapper
                    options={onlineOptions}
                    value={isOnline}
                    setValue={setIsOnline}
                    placeholder="Please choose"
                  />
                  {formSubmitted && !isOnline && (
                    <div className="my-1 error-text-asd errorField">
                      *Benefit required
                    </div>
                  )}
                </div>
              </div>
              {/* if benefit is online */}
              {isOnline === "Yes" && (
                <div className="benefit-url position-relative row mb-4">
                  <div className="col-12">
                    <label className="form-label">Benefit Url *</label>
                    <input
                      type="text"
                      value={locationInfo.benefitUrl}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setLocationInfo((prevState) => ({
                          ...prevState,
                          [name]: value,
                        }));
                        const isValidUrl =
                          value.startsWith("http://") ||
                          value.startsWith("https://");
                        if (!isValidUrl) {
                          setUrlError("Please enter a valid URL");
                        } else {
                          setUrlError("");
                        }
                      }}
                      name="benefitUrl"
                      className="input-value-field w-100"
                      placeholder="Benefit Url"
                    />
                  </div>
                  {formSubmitted && !locationInfo?.benefitUrl && (
                    <div className="my-1 error-text-asd errorField">
                      Benefit Url required
                    </div>
                  )}
                  {urlError && locationInfo?.benefitUrl && (
                    <div className="my-1 error-text-asd errorField">
                      {urlError}
                    </div>
                  )}
                </div>
              )}
              {/* if benefit is physical */}
              {isOnline === "No" && (
                <div>
                  <div className="benefit-address position-relative row  mb-4">
                    <label className="form-label">Benefit Address *</label>
                    <span className="search-icon-illusion">{searchIcon()}</span>
                    <GooglePlacesAutocomplete
                      apiKey={API_KEY}
                      selectProps={{
                        placeholder: "Search Address",
                        defaultValue: rawAddress,
                        onChange: (values) => {
                          setValue(values);
                        },
                        styles: customStyles,
                        isClearable: true,
                      }}
                    />
                  </div>
                  <div className="benefit-form-wrapper row mb-4">
                    <div className="address-line-1 col-6">
                      <input
                        type="text"
                        value={locationInfo.addressLine1}
                        onChange={handleLocationChange}
                        name="addressLine1"
                        className="input-value-field w-100"
                        placeholder="Address line one"
                      />
                      {formSubmitted && !locationInfo?.addressLine1 && (
                        <div className="my-1 error-text-asd errorField">
                          Address line one required
                        </div>
                      )}
                    </div>
                    <div className="address-line-2 col-6">
                      <input
                        type="text"
                        value={locationInfo.addressLine2}
                        onChange={handleLocationChange}
                        name="addressLine2"
                        className="input-value-field w-100"
                        placeholder="Address line two"
                      />
                    </div>
                  </div>
                  <div className="benefit-form-wrapper row mb-4">
                    <div className="city col-6">
                      <input
                        type="text"
                        value={locationInfo.city}
                        onChange={handleLocationChange}
                        name="city"
                        className="input-value-field w-100"
                        placeholder="City"
                      />
                      {formSubmitted && !locationInfo?.city && (
                        <div className="my-1 error-text-asd errorField">
                          City required
                        </div>
                      )}
                    </div>
                    <div className="country col-6">
                      <SelectWrapper
                        options={countries}
                        value={selectedCountries}
                        setValue={setSelectedCountries}
                        placeholder="Country"
                      />
                      {formSubmitted && !selectedCountries && (
                        <div className="my-1 error-text-asd errorField">
                          Country required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="benefit-form-wrapper row">
                    <div className="post-code col-6">
                      <input
                        type="text"
                        value={locationInfo.postalCode}
                        onChange={handleLocationChange}
                        name="postalCode"
                        className="input-value-field w-100"
                        placeholder="Post or ZIP Code"
                      />
                      {formSubmitted && !locationInfo?.postalCode && (
                        <div className="my-1 error-text-asd errorField">
                          Post or ZIP code required
                        </div>
                      )}
                    </div>
                    <div className="b-longitude longitude col-6">
                      <Input
                        type="text"
                        id="coordinates"
                        text="Longitude, Latitude"
                        padding="10px 12px 11px"
                        placeholder="Longitude, Latitude"
                        value={
                          locationInfo.longitudes !== null && locationInfo.latitudes !== null
                            ? `${locationInfo.longitudes}, ${locationInfo.latitudes}`
                            : ""
                        }
                        register={register}
                        error={(errors as any).coordinates?.message}
                        backgroundColor="#1f1f22"
                      />
                      {formSubmitted && !locationInfo?.longitudes && (
                        <div className="my-1 error-text-asd errorField">
                          Longitude and latitude required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="button-wrapper">
                <div className="back-btn-area">
                  <Button
                    title={"BACK"}
                    type={"button"}
                    backgroundColor="#1A1A1C"
                    color="white"
                    border="1px solid white"
                    borderRadius="6px"
                    padding="10px 48px"
                    fontSize="12px"
                    fontWeight="600"
                    onButtonClick={handleBack}
                  />
                </div>
                <div className="next-btn-area">
                  <button
                    type="submit"
                    onClick={handleSubmitCampaign}
                    className="benefit-submit-button"
                    disabled={loading}
                  >
                    {loading === true ? <Loader /> : "FINISH"}
                  </button>
                  {/* Exit Modal */}
                  <Modal
                    showModal={showModal}
                    handleClose={handleCloseModal}
                    buttonTitle="EXIT"
                    handleExit={handleExit}
                    title="Are you sure you want to exit?"
                    message="Are you sure you want to exit creating a new benefit? The information will not be saved."
                  />
                </div>
              </div>
            </form>
          </div>
          {/* progress bar at the bottom */}
          <div style={{ position: "relative" }}>
            <div className=" row stage-bar">
              <div className="col-6 step-1-event"></div>
              <div className="col-6 step-2"></div>
            </div>
          </div>
        </div>
        {/* image section */}
        <div className="col-lg-6 col-md-3 p-0 form-image-right">
          <div className="benefit-image">
            <div className="overlay-2"></div>
            <span onClick={handleOpenModal} className="exit-icon">
              {ExitIcon()}
            </span>
            <div className="campaign-icon">{CampaignIcon()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitStepsTwo;
