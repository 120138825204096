import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Button from "../Button";
import Loader from "../Spinner";
import { format } from "date-fns";
import "./style.css";
import { ViewMemberModalProps } from "@/@Interfaces/ModalProps";
import pink from "../../assets/images/pink.jpg";
import purple from "../../assets/images/purple.jpg";
import blue from "../../assets/images/blue.png";
import { genderOptions } from "@/constants/genderOptions";
import SelectWrapper from "../selectWrapper";
import { industryOptions } from "@/constants/industryOptions";
import { incomeOptions } from "@/constants/incomeOptions";
import { membershipOptions } from "@/constants/membershipTypes";
import showToast from "../cogo-toast";
import { editApplicant } from "@/services/editUser";
import DatePicker from "react-date-picker";
import { userStatus } from "@/constants/userStatus";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { calenderIcon } from "@/constants/utils/svgs";

const ViewApplicantModal: React.FC<ViewMemberModalProps> = ({
  handleClose,
  membersData,
  viewMember,
  applicantStatus,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [firstName, setFirstName] = useState<string>(
    membersData?.firstName ?? ""
  );
  const [userId, setUserId] = useState(membersData?._id ?? "");
  const [lastName, setLastName] = useState<string>(membersData?.lastName ?? "");
  const [email, setEmail] = useState<string>(membersData?.email ?? "");
  const [phoneNumber, setPhoneNumber] = useState<string>(
    membersData?.phoneNo ?? ""
  );
  const [dob, setDob] = useState<any>(membersData?.dob ? membersData.dob : null); // Initialize with membersData?.dob or null if not available

  // const [dob, setDob] = useState<any>(membersData?.dob ?? "");
  const [dobError, setDobError] = useState("");
  const [gender, setGender] = useState<string>(membersData?.gender ?? "");
  const [primaryCity, setPrimaryCity] = useState<string>(
    membersData?.primaryCity ?? ""
  );
  const [job, setJob] = useState<string>(membersData?.job ?? "");
  const [industry, setIndustry] = useState<string>(membersData?.industry ?? "");
  const [salary, setSalary] = useState<string>(membersData?.salary ?? "");
  const [membershipType, setMembershipType] = useState<string>(
    membersData?.membershipType ?? ""
  );
  const [status, setStatus] = useState<string>(membersData?.userStatus ?? "");
  const [userImage, setUserImage] = useState<string>(membersData?.image ?? "");
  const [instagram, setInstagram] = useState<string>(
    membersData?.instagram ?? ""
  );
  const [linkedin, setLinkedin] = useState<string>(membersData?.linkedin ?? "");
  const [linkedinError, setLinkedinError] = useState<string>("");
  const [phoneError, setPhoneError] = useState("");
  const [value, onChange] = useState(new Date());

  const [date, setDate] = useState<string>(
    applicantStatus === "Approved"
      ? membersData?.approvedDate ?? ""
      : membersData?.dateCreated ?? ""
  );
  const [approvedDate, setApprovedDate] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const formatDateTime = (isoString: string) => {
    const date = new Date(isoString);

    const padZero = (num: number) => num.toString().padStart(2, "0");

    const month = padZero(date.getMonth() + 1); // getMonth() is zero-based
    const day = padZero(date.getDate());
    const year = date.getFullYear().toString(); // Get last two digits of the year

    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());

    const formattedDateTime = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  };

  useEffect(() => {
    const formattedDateTime = formatDateTime(date);
    if (date === "NaN/NaN/NaN Nan:Nan:Nan" || !date) {
      setApprovedDate("");
    } else {
      setApprovedDate(formattedDateTime);
    }
  }, [date]);

  const handleDobChange = (date: any) => {
   if(date){
    const formattedDob = format(date, "MM/dd/yyyy");
    setDob(formattedDob);
   }
  };

  const handlePhoneNumberChange = (e: any) => {
    const value = e.target.value;
    // Regex pattern to allow only numbers and an optional + sign at the start
    const phonePattern = /^\+?[0-9]*$/;

    if (!phonePattern.test(value)) {
      setPhoneError("Invalid format");
    } else {
      setPhoneError("");
      setPhoneNumber(value);
    }
  };

  const handleUpdateApplicant = async () => {
    setLoading(true);
    setFormSubmitted(true);
    const id = userId;
    const user = {
      firstName: firstName,
      lastName: lastName,
      // email: email,
      // phoneNo: phoneNumber,
      dob: dob,
      userStatus: status,
      gender: gender,
      primaryCity: primaryCity,
      job: job,
      industry: industry,
      salary: salary,
      membershipType: membershipType,
      ...(instagram && { instagram }),
      ...(linkedin && { linkedin }),
    };
    const isApplicantDataValid = Object.entries(user).every(([key, value]) => {
      if (key === "instagram" || key === "linkedin") {
        return true;
      }
      return value !== null && value !== "";
    });
    if (dobError === "" && isApplicantDataValid && linkedinError === "") {
      try {
        const response = await editApplicant(id, user);
        setLoading(false);
        handleClose();
        showToast("Applicant updated successfully", "success");
      } catch (e) {
        setLoading(false);
        showToast("Error in updating Applicant", "error");
      }
    } else if (linkedinError) {
      showToast("Please enter a valid linkedin Url. Cannot update.", "error");
    } else {
      showToast("Cannot update! Please complete all required fields.", "error");
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        marginTop: "5.73vh",
        padding: "0 4.6vw",
        overflowY: "scroll",
      }}
    >
      <div className="modelHeading-oi9 mb-8">
        {" "}
        {viewMember ? (
          <>
            {applicantStatus === "Approved" && "View Member"}
            {applicantStatus === "Pending" && "View Pending Applicant"}
            {applicantStatus === "Waiting List" && "View Waiting Applicant"}
            {applicantStatus === "Not Approved" &&
              "View Not Approved Applicant"}
          </>
        ) : (
          <>
            {applicantStatus === "Approved" && "Edit Member"}
            {applicantStatus === "Pending" && "Edit Pending Applicant"}
            {applicantStatus === "Waiting List" && "Edit Waiting Applicant"}
            {applicantStatus === "Not Approved" &&
              "Edit Not Approved Applicant"}
          </>
        )}
      </div>
      <div className="form-wrapper-3 row mt-4 mb-4">
        <div className="image-wrapper col-4">
          <div
            style={{
              borderRadius: "32px",
              overflow: "hidden",
              width: "10vw",
              height: "10vw",
            }}
          >
            <img
              style={{
                borderRadius: "32px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={
                userImage
                  ? userImage
                  : gender === "Female"
                  ? pink
                  : gender === "Male"
                  ? blue
                  : purple
              }
              alt="hye"
            />
          </div>
        </div>
        <div
          className="image-wrapper col-8"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{ color: "#ffffff", fontSize: "16px", fontWeight: "400" }}
          >
            {firstName} {lastName}
          </div>
          <span className="business-text" style={{ color: "#8E929A" }}>
            The application status of this user is{" "}
            {applicantStatus === "Approved" && " approved."}
            {applicantStatus === "Pending" && " pending."}
            {applicantStatus === "Waiting List" && " on the waiting list."}
            {applicantStatus === "Not Approved" && " not approved."}
          </span>
        </div>
      </div>
      <div className="mt-4 row">
        <div className="col-6">
          <label className="form-label">First Name *</label>
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="w-100 input-value-field"
            name="first name"
            placeholder="First Name"
            maxLength={50}
            disabled={viewMember}
          />
          {formSubmitted && !firstName && (
            <div className="my-1 error-text-asd errorField">
              *First name required
            </div>
          )}
        </div>
        <div className="col-6">
          <label className="form-label">Last Name *</label>
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="w-100 input-value-field"
            name="last name"
            placeholder="Last Name"
            maxLength={50}
            disabled={viewMember}
          />
          {formSubmitted && !lastName && (
            <div className="my-1 error-text-asd errorField">
              *Last Name required
            </div>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6">
          <label className="form-label">Email Address *</label>
          <input
            value={email}
            // onChange={(e) => setEmail(e.target.value)}
            className="w-100 input-value-field"
            name="email address"
            placeholder="Email Address"
            maxLength={50}
            disabled
          />
          {formSubmitted && !email && (
            <div className="my-1 error-text-asd errorField">
              *Email required
            </div>
          )}
        </div>
        <div className="col-6 categories-wrapper">
          <label className="form-label">Phone Number *</label>
          <input
            value={phoneNumber}
            // onChange={handlePhoneNumberChange}
            className="w-100 input-value-field"
            name="phone number"
            placeholder="Phone Number"
            maxLength={50}
            disabled
          />
          {formSubmitted && !phoneNumber && (
            <div className="my-1 error-text-asd errorField">
              *Phone Number required
            </div>
          )}
          {phoneError && phoneNumber && (
            <div className="my-1 error-text-asd errorField">*{phoneError}</div>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6 city dob-city">
          <label className="form-label">DOB *</label>
          <DatePicker
            calendarIcon
            // onInvalidChange={() => {
            //   setDobError("Invalid Date of birth")
            // }}
            className="w-100 input-value-field"
            value={dob}
            clearIcon={false}
            disabled={viewMember} 
            closeCalendar={true}
            format={'MM/dd/yyyy'}
            maxDate={new Date()}
            required
            
            onChange={
              handleDobChange
            }  
          />
          {/* <input
            value={dob}
            onChange={handleDobChange}
            className="w-100 input-value-field"
            name="dob"
            placeholder="Date of Birth"
            maxLength={50}
            disabled={viewMember} 
          /> */}
          {formSubmitted && !dob && (
            <div className="my-1 error-text-asd errorField">*DOB required</div>
          )}
          {dobError && dob && (
            <div className="my-1 error-text-asd errorField">*{dobError}</div>
          )}
        </div>
        <div className="col-6 country">
          <label className="form-label">Gender *</label>
          <SelectWrapper
            options={genderOptions}
            value={gender}
            setValue={setGender}
            placeholder="Choose gender type"
            disabled={viewMember}
          />
          {formSubmitted && !gender && (
            <div className="my-1 error-text-asd errorField">
              *Gender required
            </div>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6 event-wrapper">
          <label className="form-label">Primary City *</label>
          <input
            value={primaryCity}
            onChange={(e) => setPrimaryCity(e.target.value)}
            className="w-100 input-value-field"
            name="city"
            placeholder="Primary City"
            maxLength={50}
            disabled={viewMember}
          />
          {formSubmitted && !primaryCity && (
            <div className="my-1 error-text-asd errorField">
              *Primary City required
            </div>
          )}
        </div>
        <div className="col-6 categories-wrapper">
          <label className="form-label">Job Title *</label>
          <input
            value={job}
            onChange={(e) => setJob(e.target.value)}
            className="w-100 input-value-field"
            name="job"
            placeholder="Job Title"
            maxLength={50}
            disabled={viewMember}
          />
          {formSubmitted && !job && (
            <div className="my-1 error-text-asd errorField">
              *Job Title required
            </div>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6 country">
          <label className="form-label">Industry *</label>
          <SelectWrapper
            options={industryOptions}
            value={industry}
            setValue={setIndustry}
            placeholder="Industry"
            disabled={viewMember}
          />

          {formSubmitted && !industry && (
            <div className="my-1 error-text-asd errorField">
              *Industry required
            </div>
          )}
        </div>
        <div className="col-6 country">
          <label className="form-label">Income *</label>
          <SelectWrapper
            options={incomeOptions}
            value={salary}
            setValue={setSalary}
            placeholder="Income"
            disabled={viewMember}
          />
          {formSubmitted && !salary && (
            <div className="my-1 error-text-asd errorField">
              *Income required
            </div>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6 country">
          <label className="form-label">Membership Type *</label>
          <SelectWrapper
            options={membershipOptions}
            value={membershipType}
            setValue={setMembershipType}
            placeholder="Membership Type"
            disabled={viewMember}
          />
          {formSubmitted && !membershipType && (
            <div className="my-1 error-text-asd errorField">
              *Type of benefit required
            </div>
          )}
        </div>
        <div className="col-6 categories-wrapper">
          <label className="form-label">Status</label>
          <SelectWrapper
            options={userStatus}
            value={status}
            setValue={setStatus}
            placeholder="Applicant Status"
            disabled={viewMember}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6 categories-wrapper">
          <label className="form-label">Instagram Handle</label>
          <input
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
            className="w-100 input-value-field"
            name="instagram"
            placeholder="Instagram Handle"
            maxLength={50}
            disabled={viewMember}
          />
        </div>
        <div className="d-flex flex-column col-6 event-wrapper">
          <label className="form-label">LinkedIn</label>
          <input
            value={linkedin}
            onChange={(e) => {
              const url = e.target.value;
              setLinkedin(e.target.value);
              if (
                !url ||
                !/^https:\/\/(www\.)?linkedin\.com\/(in|company|school|groups|pub|jobs)\/[a-zA-Z0-9-_/]+$/.test(
                  url
                )
              ) {
                setLinkedinError("Please enter a valid LinkedIn URL");
              } else {
                setLinkedinError("");
              }
            }}
            className="w-100 input-value-field"
            name="linkedin"
            placeholder="LinkedIn"
            maxLength={50}
            disabled={viewMember}
          />
          {linkedinError && (
            <div className="my-1 error-text-asd errorField">
              {linkedinError}
            </div>
          )}
        </div>
      </div>
      <div className="row mt-3">
        {viewMember && (
          <div className="col-6 categories-wrapper">
            <label className="form-label">
              {applicantStatus === "Approved"
                ? "Approved Date/Time"
                : "Applied Date/Time"}
            </label>
            <input
              value={approvedDate}
              className="w-100 input-value-field"
              name="benefit name"
              placeholder="Approved Date"
              maxLength={50}
              disabled
            />
          </div>
        )}
      </div>

      <div className="row mt-4 mb-4">
        <div className="col-lg-6"></div>
        <div className="col-lg-6">
          <div className="ticket-footer-wrapper">
            <div className="ticket-back">
              <Button
                title={viewMember ? "EXIT" : "CANCEL"}
                type={"button"}
                backgroundColor="#1A1A1C"
                color="white"
                border="1px solid white"
                borderRadius="6px"
                padding="10.12px 47.3px"
                fontSize="12px"
                fontWeight="600"
                onButtonClick={handleClose}
              />
            </div>
            {!viewMember && (
              <div className="ticket-finish">
                <button
                  type="submit"
                  className="benefit-submit-button"
                  disabled={viewMember}
                  onClick={handleUpdateApplicant}
                >
                  {loading === true ? <Loader /> : "FINISH"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewApplicantModal;
