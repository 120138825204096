import { uploadImages} from './api';
import URLS from '@/constants/api';

export const uploadFile = async (files: any) => { 
  try{
    const response = await uploadImages(URLS.UPLOAD_FILES, files);
    return response;
  }
  catch (error) {
        throw new Error("An error occurred.");
      }
};
