import { NotificationsRow } from "@/@Interfaces/NavbarProps";
import { getNotifications } from "@/services/getNotifications";
import { useEffect, useState } from "react";
import showToast from "../cogo-toast";

const usePolling = () => {
  const [notificationsData, setNotificationsData] = useState<
    NotificationsRow[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewNotification, setIsNewNotification] = useState<boolean>(false);

  const getNotify = async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await getNotifications();
      const newNotifications = response.data;
      const hasUnreadNotifications = newNotifications.some(
        (notification: any) => !notification.isRead
      );

      if (hasUnreadNotifications) {
        setIsNewNotification(true);
      } else {
        setIsNewNotification(false);
      }

      setNotificationsData(newNotifications);
      setIsLoading(false);
    } catch (error: any) {
      if (error.message === "Network Error") {
        showToast(
          "It seems you're offline. Please check your connection and try again.",
          "error"
        );
      } else {
        showToast("Error in updating notifications.", "error");
      }
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // Function to fetch data immediately when component mounts
    // Setup polling interval to fetch data periodically
    // Polling interval of 5 minutes

    const fetchData = async () => {
      await getNotify();
    };
    fetchData();

    const intervalId = setInterval(fetchData, 300000);
    // Cleanup function for useEffect to clear interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return { notificationsData, isLoading, isNewNotification, getNotify };
};

export default usePolling;
