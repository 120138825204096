import { post } from './api';
import URLS from "../constants/api";

export const getAllAtendees = async (
  pageNumber: number,
  pageLimit: number,
  search: string,
  sortOn: string,
  sortBy: string,
  eventId: string,
) => {
  // Create the requestData object with all parameters
  const requestData = {
    pageNumber,
    pageLimit,
    eventId,
    ...(search !== "" && { search }), // Include search if not an empty string
    ...(sortOn !== "" && { sortOn }), // Include sortOn if not an empty string
    ...(sortBy !== "" && { sortBy }), // Include sortBy if not an empty string
  };


  const response = await post(URLS.GET_ALL_ATTENDEES, requestData);
  return response;
};

