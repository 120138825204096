import { setApplicantStatus } from "@/redux/slices/sidebarSlice";
import { getDashboardData } from "@/services/dashboard";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Card from "../Card";
import Loader from "../Spinner";
import showToast from "../cogo-toast";
import "./style.css";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [pendingData, setPendingData] = useState<number>(0);
  const [waitingData, setWaitingData] = useState<number>(0);
  const [notApprovedData, setNotApprovedData] = useState<number>(0);
  const [approvedData, setApprovedData] = useState<number>(0);
  const [benefitsData, setBenefitsData] = useState<number>(0);
  const [eventsData, setEventsData] = useState<number>(0);
  const [businessData, setBusinessData] = useState<number>(0);
  const [rsvpsData, setRsvpsData] = useState<number>(0);
  const dispatch = useDispatch();

  const titleNavigateMap: Record<string, { title: string; navigate: string }> =
    {
      Pending: { title: "Pending Applicants", navigate: "applications" },
      WaitingList: { title: "Waiting Applicants", navigate: "applications" },
      NotApproved: { title: "Not Approved Members", navigate: "applications" },
      Approved: { title: "Approved Members", navigate: "members" },
      Benefits: { title: "Benefits", navigate: "benefits" },
      Events: { title: "Events", navigate: "events" },
      Businesses: { title: "Businesses", navigate: "businesses" },
      RSVPs: { title: "Guest List", navigate: "attendees" },
    };
  const fetchData = async (type: string) => {
    try {
      setLoading(true);
      const response = await getDashboardData(type);
      switch (type) {
        case "pending":
          setPendingData(response.data.data);
          break;
        case "waiting":
          setWaitingData(response.data.data);
          break;
        case "notApproved":
          setNotApprovedData(response.data.data);
          break;
        case "approved":
          setApprovedData(response.data.data);
          break;
        case "benefits":
          setBenefitsData(response.data.data);
          break;
        case "events":
          setEventsData(response.data.data);
          break;
        case "business":
          setBusinessData(response.data.data);
          break;
        case "attendees":
          setRsvpsData(response.data.data);
          break;
      }
    } catch (error: any) {
      setLoading(false);
      if (error.message === "Network Error") {
        showToast(
          "It seems you're offline. Please check your connection and try again.",
          "error"
        );
      } else {
        showToast("Something went wrong. ", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  //get data of all tabs in cards
  useEffect(() => {
    fetchData("pending");
    fetchData("waiting");
    fetchData("notApproved");
    fetchData("approved");
    fetchData("benefits");
    fetchData("events");
    fetchData("business");
    fetchData("attendees");
  }, []);

  //on click of card navigates to that tab
  const navigateToTab = (key: string) => {
    switch (key) {
      case "WaitingList":
        dispatch(setApplicantStatus("Waiting List"));
        break;
      case "Pending":
        dispatch(setApplicantStatus("Pending"));
        break;
      case "NotApproved":
        dispatch(setApplicantStatus("Not Approved"));
        break;
    }

    navigate(`/home/${titleNavigateMap[key].navigate}`);
  };

  //Card
  const cardRenderer = (data: any, title: string, key: string) => {
    return (
      <div className="col-lg-3" onClick={() => navigateToTab(key)}>
        <Card title={title} data={Loading === false ? data : <Loader />} />
      </div>
    );
  };
  return (
    <section className="dashboard-section">
      <div className="table-header d-flex flex-1 align-items-center flex-wrap justify-content-between">
        <h4 className="t-head">Dashboard</h4>
      </div>
      <div className="row gy-4 ">
        {/* set default number to 0 */}
        {pendingData
          ? cardRenderer(pendingData, "Pending Applicants", "Pending")
          : cardRenderer("0", "Pending Applicants", "Pending")}
        {waitingData
          ? cardRenderer(waitingData, "Waiting Applicants", "WaitingList")
          : cardRenderer("0", "Waiting Applicants", "WaitingList")}
        {notApprovedData
          ? cardRenderer(notApprovedData, "Not Approved Members", "NotApproved")
          : cardRenderer("0", "Not Approved Members", "NotApproved")}
        {approvedData
          ? cardRenderer(approvedData, "Approved Members", "Approved")
          : cardRenderer("0", "Approved Members", "Approved")}
        {benefitsData
          ? cardRenderer(benefitsData, "Benefits", "Benefits")
          : cardRenderer("0", "Benefits", "Benefits")}
        {eventsData
          ? cardRenderer(eventsData, "Events", "Events")
          : cardRenderer("0", "Events", "Events")}
        {businessData
          ? cardRenderer(businessData, "Businesses", "Businesses")
          : cardRenderer("0", "Businesses", "Businesses")}
        {rsvpsData
          ? cardRenderer(rsvpsData, "Attendees", "RSVPs")
          : cardRenderer("0", "Attendees", "RSVPs")}
      </div>
    </section>
  );
};

export default Dashboard;
