import React, { useState } from "react";
import "react-toggle/style.css";
import { useNavigate } from "react-router";
import showToast from "../cogo-toast";
import Sidebar from "../Sidebar";
import { DashboardIcon } from "@/constants/utils/svgs";
import StepOneBusiness from "./stepOneBusiness";
import StepTwoBusiness from "./stepTwoBusiness";
import StepThreeBusiness from "./stepThreeBusiness";
import { createBusiness } from "@/services/businesses";
import { useSelector } from "react-redux";
import { uploadBusinessLogo } from "@/services/api";
import URLS from "@/constants/api";
import { businessImageUpdate } from "@/services/imageUpdate";
import { resetBusinessImage } from "@/redux/slices/businessImageSlice";
import { useDispatch } from "react-redux";
import Modal from "../ExitModal";
import { TicketFormData } from "@/@Interfaces/TicketFormData";
import StepFourBusiness from "./stepFourBusiness";
import {
  DocumentFormData,
  DocumentImages,
} from "@/@Interfaces/BusinessStepFourProps";
import { uploadDocumentFile } from "@/services/uploadDoc";
import { updateDocFiles } from "@/services/updateWithDocs";

const CreateNewBusiness: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState<number>(1);
  const [select, setSelect] = useState<string>("");
  const [exitTab, setExitTab] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [businessLogo, setBusinessLogo] = useState(null);
  const [businessDocument, setBusinessDocument] = useState(null);
  const [businessName, setBusinessName] = useState<string>("");
  const [businessWebsite, setBusinessWebsite] = useState<string>("");
  const [primaryEmailAddress, setPrimaryEmailAddress] = useState<string>("");
  const [businessCategory, setBusinessCategory] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [instagramHandler, setInstagramHandler] = useState<string>("");
  const [linkedInURL, setLinkedInURL] = useState<string>("");
  const [documents, setDocuments] = useState<DocumentFormData[]>([]);
  const [urlArray, setUrlArray] = useState<string[]>([]);
  const [docFiles, setDocFiles] = useState<DocumentImages>({
    file: null,
    name: "",
  });
  const [documentType, setDocumentType] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");

  const [accountManagerFirstName, setAccountManagerFirstName] =
    useState<string>("");
  const [accountManagerLastName, setAccountManagerLastName] =
    useState<string>("");
  const [accountManagerEmailAddress, setAccountManagerEmailAddress] =
    useState<string>("");
  const [accountManagerPhoneNumber, setAccountManagerPhoneNumber] =
    useState<string>("");
  const [accountManagerJobTitle, setAccountManagerJobTitle] =
    useState<string>("");

  const [locationInfo, setLocationInfo] = useState<any>({
    country: "",
    city: "",
    addressLine1: "",
    addressLine2: "",
    instagramHandler: "",
    linkedInURL: "",
    latitudes: null,
    longitudes: null,
    postalCode: "",
    coordinates: "",
  });
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [timeRange, setTimeRange] = useState("");
  const [rawAddress, setRawAddress] = useState();
  const [ticketName, setTicketName] = useState<string>("");
  const [ticketPrice, setTicketPrice] = useState();
  const [ticketDesc, setTicketDesc] = useState<string>("");
  const [availableTo, setAvailableTo] = useState<string>("");
  const [quantityAvailable, setQuantityAvailable] = useState();
  const navigate = useNavigate();

  const [tickets, setTickets] = useState<TicketFormData["tickets"]>([
    {
      name: "",
      price: "",
      description: "",
      availableTo: "",
      quantityAvailable: "",
    },
  ]);
  const businessImage = useSelector(
    (state: any) => state.business_image_path.businessImage
  );

  const pathParts = location.pathname.split("/");
  const storedTab = pathParts[2] || "dashboard";

  const [activeTab, updateActiveTab] = useState(storedTab);

  //Confirmation Modal on Exit
  const handleModalConfirmation = (tab: string) => {
    setExitTab(tab);
    setShowModal(true);
  };

  const handleTabChange = (tab: string) => {
    updateActiveTab(tab);
    handleModalConfirmation(tab);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExit = () => {
    if (exitTab) {
      navigate(`/home/${exitTab}`);
      updateActiveTab(exitTab);
    } else {
      console.log("No exit tab specified");
    }
  };
  //Create a Business
  const handleSubmitBusiness = async () => {
    try {
      setLoading(true);
      //payload
      const businessData = {
        businessName: businessName,
        businessWebsite: businessWebsite,
        primaryEmailAddress: primaryEmailAddress,
        businessCategory: businessCategory,
        description: description,
        instagramHandler: instagramHandler,
        linkedInURL: linkedInURL,
        addressLine1: locationInfo?.addressLine1 || "",
        addressLine2: locationInfo?.addressLine2 || "",
        city: locationInfo?.city || "",
        country: locationInfo?.country || "",
        postalCode: locationInfo?.postalCode || "",
        latitudes: locationInfo?.latitudes || null,
        longitudes: locationInfo?.longitudes || null,
        //added new fields
      };
      // const documentsTransformed = documents.map((doc: any) => ({
      //   name: doc.file?.name,
      //   description: doc.description,
      //   type: doc.type,
      // }));

      const accountManager = {
        firstName: accountManagerFirstName,
        lastName: accountManagerLastName,
        email: accountManagerEmailAddress,
        phoneNumber: accountManagerPhoneNumber,
        jobTitle: accountManagerJobTitle,
      };

      const combinedData = {
        business: businessData,
        accountManager: accountManager,
        // documents: documentsTransformed
      };
      const apiResponse = await createBusiness(combinedData);

      if (documents.length > 0) {
        const uploadDoc = await uploadDocumentFile(
          documents,
          apiResponse.data.businessId
        );
        setUrlArray(uploadDoc.urlArray);

        const urlMap: { [key: number]: string } = {};
        uploadDoc.urlArray.forEach((item: { key: number; value: string }) => {
          urlMap[item.key] = item.value;
        });

        const documentsTransformed = documents.map(
          (doc: any, index: number) => ({
            businessId: apiResponse.data.businessId,
            name: doc.file?.name,
            description: doc.description,
            createdAt: doc.createdAt,
            type: doc.type,
            url: urlMap[index],
          })
        );
        const updateDoc = await updateDocFiles(
          apiResponse.data.businessId,
          documentsTransformed
        );
      }

      if (businessLogo) {
        const uploadFiles = {
          type: "Businesses",
          id: apiResponse.data.businessId,
          logo: businessImage,
        };
        //upload business image to cloud front
        const upload = await uploadBusinessLogo(URLS.UPLOAD_FILES, uploadFiles);

        const updateImage = {
          businessId: apiResponse.data.businessId,
          logo: upload[0],
        };

        businessImageUpdate(updateImage);
        dispatch(resetBusinessImage());
      }

      setLoading(false);
      navigate("/home/businesses");
      showToast("Business added successfully!", "success");
    } catch (err) {
      setLoading(false);
      showToast(`Error creating business! Please try again later.`, "error");
    }
  };

  return (
    <div>
      <Modal
        showModal={showModal}
        handleClose={handleCloseModal}
        buttonTitle="EXIT"
        handleExit={handleExit}
        title="Are you sure you want to exit?"
        message="Are you sure you want to exit creating a new business? The information will not be saved."
      />

      {steps === 1 && (
        <div className="d-flex gap-2" style={{ height: "100vh" }}>
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <StepOneBusiness
            steps={steps}
            setSteps={setSteps}
            select={select}
            setSelect={setSelect}
            businessCategory={businessCategory}
            setBusinessCategory={setBusinessCategory}
            businessName={businessName}
            setBusinessName={setBusinessName}
            businessWebsite={businessWebsite}
            setBusinessWebsite={setBusinessWebsite}
            primaryEmailAddress={primaryEmailAddress}
            setPrimaryEmailAddress={setPrimaryEmailAddress}
            descriptionProp={description}
            setDescription={setDescription}
            businessLogo={businessLogo}
            setBusinessLogo={setBusinessLogo}
          />
        </div>
      )}
      {steps === 2 && (
        <div className="d-flex gap-2 w-100">
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <StepTwoBusiness
            steps={steps}
            setSteps={setSteps}
            locationInfo={locationInfo}
            setLocationInfo={setLocationInfo}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
            rawAddress={rawAddress}
            setRawAddress={setRawAddress}
            instagramHandler={instagramHandler}
            setInstagramHandler={setInstagramHandler}
            linkedInURL={linkedInURL}
            setLinkedInURL={setLinkedInURL}
          />
        </div>
      )}
      {steps === 3 && (
        <div className="d-flex gap-2 w-100">
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <StepThreeBusiness
            loading={loading}
            setLoading={setLoading}
            steps={steps}
            setSteps={setSteps}
            ticketName={ticketName}
            setTicketName={setTicketName}
            ticketPrice={ticketPrice}
            setTicketPrice={setTicketPrice}
            ticketDesc={ticketDesc}
            setTicketDesc={setTicketDesc}
            availableTo={availableTo}
            setAvailableTo={setAvailableTo}
            quantityAvailable={quantityAvailable}
            setQuantityAvailable={setQuantityAvailable}
            tickets={tickets}
            setTickets={setTickets}
            accountManagerFirstName={accountManagerFirstName}
            setAccountManagerFirstName={setAccountManagerFirstName}
            accountManagerLastName={accountManagerLastName}
            setAccountManagerLastName={setAccountManagerLastName}
            accountManagerEmailAddress={accountManagerEmailAddress}
            setAccountManagerEmailAddress={setAccountManagerEmailAddress}
            accountManagerPhoneNumber={accountManagerPhoneNumber}
            setAccountManagerPhoneNumber={setAccountManagerPhoneNumber}
            accountManagerJobTitle={accountManagerJobTitle}
            setAccountManagerJobTitle={setAccountManagerJobTitle}
            handleSubmitBusiness={handleSubmitBusiness}
          />
        </div>
      )}
      {steps > 3 && (
        <div className="d-flex gap-2 w-100">
          <div className="temp-header">
            <Sidebar name={""} icon={DashboardIcon} />
          </div>
          <StepFourBusiness
            loading={loading}
            setLoading={setLoading}
            steps={steps}
            setSteps={setSteps}
            businessDocument={businessDocument}
            setBusinessDocument={setBusinessDocument}
            documents={documents}
            setDocuments={setDocuments}
            docFiles={docFiles}
            setDocFiles={setDocFiles}
            documentType={documentType}
            setDocumentType={setDocumentType}
            documentDescription={documentDescription}
            setDocumentDescription={setDocumentDescription}
            handleSubmitBusiness={handleSubmitBusiness}
          />
        </div>
      )}
    </div>
  );
};

export default CreateNewBusiness;
