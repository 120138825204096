import URLS from '@/constants/api';
import { patch_2} from "./api";

export const flagThisUserAPI = async (
    id: string | number,
    key:string,
    value: boolean
  ) => {
    const requestData= {
        key: key,
        value: value,
      };
    const endpoint = `${URLS.FLAG_USER}?id=${id}`;
    const response = await patch_2(endpoint, requestData);
    return response;
  };
  
