import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-data-grid/lib/styles.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { tableHeaders } from "@constants/tableMembership";
import { approvedMembers } from "@/constants/approvedMembers";
import pink from "@/assets/images/pink.jpg";
import blue from "@/assets/images/blue.png";
import purple from "@/assets/images/purple.jpg";
import Loader from "../Spinner";
import { DownloadIcon } from "@constants/utils/svgs";
import { ApplicantData, MembersData, TableRow } from "@/@Interfaces/TableProps";
import { getApplicants } from "@services/applications";
import { editApplicantStatus } from "@services/edit";
import { useSelector } from "react-redux";
import { downloadFileAPI } from "@/services/downloadCsv";
import { options } from "@constants/options";
import TableFooter from "../TableFooter";
import { flagThisUserAPI } from "@/services/flagUser";
import { updateMember } from "@/services/updateMembership";
import showToast from "../cogo-toast";
import { getMembershipTypes } from "@/services/getMembershipTypes";
import GrandModal from "@/modals/Modal";
import ViewApplicantModal from "../ViewApplicants";

const Table: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedRow, setSelectedRow] = useState<TableRow | null>(null);
  const [activeOption, setActiveOption] = useState<string | null>(null);
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [sortBy, setSortBy] = useState<string>("firstName");
  const [showSpinner, setShowSpinner] = useState(false);
  const [search, setsearch] = useState("");
  const [searchOn, setsearchOn] = useState<string>("firstName");
  const [sortingOrder, setSortingOrder] = useState<"asc" | "desc">("asc");
  const [memberTypes, setMemberTypes] = useState([""]);
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const [viewApplicant, setViewApplicant] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [viewTableData, setViewTableData] = useState<ApplicantData[]>([]);
  const [viewMembers, setViewMembers] = useState<MembersData>();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownRef2 = useRef<HTMLDivElement>(null);

  const latestDate = new Date();
  const formattedLatestDate = latestDate.toLocaleString();

  const dropdownOption = useSelector(
    (state: any) => state.applicant.dropdownOption
  );

  const pageLimit = options.includes(dropdownOption) ? dropdownOption : "25";

  const applicantStatus = useSelector(
    (state: any) => state.applicant_status.applicantStatus
  );

  const tableHeadersToDisplay =
    applicantStatus === "Approved" ? approvedMembers : tableHeaders;

  //to change result per page value
  useEffect(() => {
    setCurrentPage(1);
  }, [dropdownOption]);

  useEffect(() => {
    const pl =
      typeof dropdownOption === "number" && !isNaN(dropdownOption)
        ? dropdownOption
        : 25;

    fetchData(
      currentPage,
      pl, //here
      applicantStatus,
      sortingOrder,
      sortBy
    );
  }, [applicantStatus, dropdownOption, sortingOrder, sortBy, sortingOrder]);

  //Close dropdown menu on outside click
  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      handleDropdownClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // get member types
  useEffect(() => {
    if (applicantStatus === "Approved") {
      getMemberTypes();
    }
  }, [applicantStatus]);

  //on edit button dropdown filtered membership types
  useEffect(() => {
    if (selectedRow) {
      const filtered = memberTypes.filter(
        (type) => type !== selectedRow.membershipType
      );
      setFilteredOptions(filtered);
    }
  }, [selectedRow, memberTypes]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //searching
  const handleSearch = (value: string) => {
    if (value.length >= 3 || value === "") {
      setCurrentPage(1);
      fetchData(
        1,
        pageLimit, //here
        applicantStatus,
        sortingOrder,
        sortBy,
        value,
        searchOn
      );
    }
  };

  //On page change fetch page data
  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const pl =
      typeof dropdownOption === "number" && !isNaN(dropdownOption)
        ? dropdownOption
        : 25;
    fetchData(
      pageNumber,
      pl, //here
      applicantStatus,
      sortingOrder,
      sortBy
    );
  };

  //previous page
  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  //next page
  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  //fetch Table Data
  const fetchData = async (
    pageNumber: number,
    pageLimit: number,
    membershipStatus: string,
    sortBy: string,
    sortOn: string,
    search?: string,
    searchOn?: string
  ) => {
    try {
      let response;
      setShowSpinner(true);

      setTableData([]); //setting table data empty so that loader is shown only instead of showing at the bottom of the table where loader is not visible
      if (search && searchOn) {
        response = await getApplicants(
          pageNumber,
          pageLimit,
          membershipStatus,
          sortBy,
          sortOn,
          search,
          searchOn
        );
      } else {
        response = await getApplicants(
          pageNumber,
          pageLimit,
          membershipStatus,
          sortBy,
          sortOn
        );
      }
      setTableData(response.data.docs);
      setTotalRecords(response.data.totalDocs);
      setTotalPages(response.data.totalPages);
    } catch (error: any) {
      setShowSpinner(false);
      if (error.message === "Network Error") {
        showToast(
          "It seems you're offline. Please check your connection and try again.",
          "error"
        );
      } else {
        showToast("Something went wrong. ", "error");
      }
    }
    setShowSpinner(false);
  };

  const handleEditClick = (rowData: TableRow) => {
    setIsDropdownOpen(rowData._id !== isDropdownOpen ? `${rowData._id}` : "");
    setSelectedRow(rowData);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen("");
    setSelectedRow(null);
  };

  const handleOptionClick = async (
    option: string,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
    setActiveOption(option);
    handleDropdownClose();

    //update membership status accordong to selected option
    if (
      ["Waiting List", "Approved", "Not Approved"].includes(option) &&
      selectedRow
    ) {
      const { email } = selectedRow;
      const response = await apiCallToUpdateStatus(email, option);
    }
    if (selectedRow && option === "Flag") {
      const _id = selectedRow._id ?? "";
      const response = await apiCallToFlagUser(_id, true, "flag");
    } else if (selectedRow && option === "UnFlag") {
      const _id = selectedRow._id ?? "";
      const response = await apiCallToFlagUser(_id, false, "flag");
    }
    await fetchData(
      currentPage,
      pageLimit, //here
      applicantStatus,
      sortingOrder,
      sortBy
    );
  };

  const handleApprovedOptionClick = async (
    option: string,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    try {
      event.stopPropagation();
      setActiveOption(option);
      handleDropdownClose();
      if (selectedRow) {
        const _id = selectedRow._id ?? "";
        const response = await updateMembershipType(
          _id,
          "membershipType",
          option
        );
      }
      await fetchData(
        currentPage,
        pageLimit, //here
        applicantStatus,
        sortingOrder,
        sortBy
      );
    } catch (error) {
      showToast("Error updating type", "error");
    }
  };

  //pills color and Background color
  const getStatusColor = (status: string): string => {
    switch (status.toLowerCase()) {
      case "standard":
        return "#BBA383";
      case "paused":
        return "#8F98A1";
      case "black":
        return "#FFFFFF";
      case "lite":
        return "#74DFAB";
      case "platinum":
        return "#6F5BBF";
      default:
        return "";
    }
  };
  const getStatusTextColor = (status: string): string => {
    switch (status.toLowerCase()) {
      case "standard":
        return "#FFFFFF";
      case "paused":
        return "#FFFFFF";
      case "black":
        return "#121212";
      case "lite":
        return "#121212";
      case "platinum":
        return "#FFFFFF";
      default:
        return "";
    }
  };

  //update applicant status
  const apiCallToUpdateStatus = async (email: string, option: string) => {
    try {
      const response = await editApplicantStatus(email, option);
    } catch (error) {
      showToast("Something went wrong. ", "error");
    }
  };
  const getMemberTypes = async () => {
    try {
      const response = await getMembershipTypes();
      setMemberTypes(response.data);
    } catch (error) {
      // console.log("error")
    }
  };

  const apiCallToFlagUser = async (
    id: string | number,
    option: boolean,
    key: string
  ) => {
    try {
      const response = await flagThisUserAPI(id, key, option);
    } catch (error) {
      showToast("Something went wrong. ", "error");
    }
  };

  //for members edit button click
  const updateMembershipType = async (
    id: string | number,
    key: string,
    option: string
  ) => {
    try {
      const response = await updateMember(id, key, option);
    } catch (error) {
      showToast("Error updating type", "error");
    }
  };

  //sorting in asc or desc order
  const handleHeaderClick = (headerKey: string) => {
    if (sortBy === headerKey) {
      setSortingOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortingOrder("desc");
      setSortBy(headerKey);
    }
  };

  const formatDateString = (dateString: string): string => {
    const dateTime = new Date(dateString);
    return dateTime.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  function calculateAge(dob: string) {
    const birthDate = new Date(dob);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  //form submission
  const handleSubmit2 = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const downloadFile = async () => {
    try {
      setShowSpinner(true);
      const response = await downloadFileAPI(applicantStatus);
      const url = window.URL.createObjectURL(response);
      const a = document.createElement("a");
      a.href = url;
      //download csv file
      if (applicantStatus === "Pending") {
        a.download = `PM_Pending_${formattedLatestDate}.csv`;
      }
      if (applicantStatus === "Waiting List") {
        a.download = `PM_Waiting_List_${formattedLatestDate}.csv`;
      }
      if (applicantStatus === "Not Approved") {
        a.download = `PM_Not_Approved_${formattedLatestDate}.csv`;
      }
      if (applicantStatus === "Approved") {
        a.download = `PM_Members_${formattedLatestDate}.csv`;
      }
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      showToast("Error in downloading file.", "error");
    } finally {
      setShowSpinner(false);
      setShowDropdown(false);
    }
  };

  const downloadPdfFile = async () => {
    try {
      setShowSpinner(true);
      // const response = await downloadPdfAPI();
      // console.log(response)
      // const url = window.URL.createObjectURL(response);
      // const a = document.createElement("a");
      // a.href = url;
      // a.download = `Export-${formattedLatestDate}.csv`;
      // document.body.appendChild(a);
      // a.click();
      // window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setShowSpinner(false);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  //email the user
  const handleEmailClick = (email: string) => {
    const mailtoLink = `mailto:${email}`;
    const anchor = document.createElement("a");
    anchor.href = mailtoLink;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleViewClick = (rowData: MembersData) => {
    setViewMembers(rowData);
    setViewApplicant(true);
    setIsEditModalOpen(true);
  };

  const handleEditApplicant = (rowData: MembersData) => {
    setViewMembers(rowData);
    setViewApplicant(false);
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    fetchData(
      currentPage,
      pageLimit, //here
      applicantStatus,
      sortingOrder,
      sortBy
    );
  };

  return (
    <section className="table-section d-flex flex-column justify-content-between">
      <div className="table-header d-flex flex-1 align-items-center flex-wrap justify-content-between">
        <h4 className="t-head">
          {applicantStatus === "Pending" && "Pending applicants"}
          {applicantStatus === "Not Approved" && "Not approved"}
          {applicantStatus === "Approved" && "Pendulum Members"}
          {applicantStatus === "Waiting List" && "Waiting list"}
        </h4>
        <div className="d-flex justify-content-end">
          <div className="search-bar d-flex flex-wrap gap-3">
            <form data-testid="search-form" onSubmit={handleSubmit2}>
              <div className="search-content">
                <FontAwesomeIcon
                  className="search-icon"
                  icon={faSearch}
                  style={{}}
                />

                <input
                  data-testid="search-input"
                  type="text"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => {
                    setsearch(e.target.value);
                    const { value } = e.target;
                    handleSearch(value);
                  }}
                />
              </div>
              <button type="submit" style={{ display: "none" }}>
                Search
              </button>
            </form>
          </div>
          <div
            className="download-icon"
            data-testid="download"
            onClick={toggleDropdown}
          >
            {DownloadIcon()}
          </div>
        </div>
      </div>
      {showDropdown && (
        <div id="dropdown" className="csv-dropdown" ref={dropdownRef2}>
          <div onClick={downloadFile}>Export as .CSV</div>
          {/* <div className="pdf-container">Export as .PDF</div>  commenting for future use*/}
        </div>
      )}
      <div className="table-container flex-grow">
        <table
          id="table"
          className="table table-hover"
          style={{ backgroundColor: "#1A1A1C" }}
        >
          <thead className="t-header">
            <tr>
              {tableHeadersToDisplay.map((header) => (
                <th
                  className="t-columns"
                  key={header.key}
                  scope="col"
                  style={header.style}
                  onClick={() => handleHeaderClick(header.key)}
                >
                  {header.name}
                </th>
              ))}
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
            </tr>
          </thead>
          <tbody className="t-body">
            {/* in case of searching */}
            {!showSpinner && tableData.length === 0 && search.length > 0 ? (
              <td colSpan={tableHeadersToDisplay.length + 1}>
                <div className="no-records-found">No records found.</div>
              </td>
            ) : !showSpinner &&
              tableData.length === 0 &&
              search.length === 0 ? (
              <td colSpan={tableHeadersToDisplay.length + 1}>
                {/* If table has no data to display */}
                <div className="no-records-found">
                  There are currently no applicants.
                </div>
              </td>
            ) : (
              <>
                {tableData.map((rowData, index) => (
                  <tr key={index}>
                    {tableHeadersToDisplay.map((header) => (
                      <td key={header.key}>
                        {header.key === "firstName" ? (
                          <div className="my-key">
                            <div
                              style={{
                                borderRadius: "100px",
                                minWidth: "40px",
                                width: "40px",
                                height: "40px",
                              }}
                            >
                              <img
                                style={{
                                  borderRadius: "12px",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={
                                  rowData.image
                                    ? rowData.image
                                    : rowData.gender === "Female"
                                    ? pink
                                    : rowData.gender === "Male"
                                    ? blue
                                    : purple
                                }
                                alt=""
                              />
                            </div>
                            <div className="name-data">
                              {rowData.firstName} {rowData.lastName}{" "}
                              <span
                                className="email-data email-hover"
                                onClick={() => handleEmailClick(rowData.email)}
                              >
                                {rowData.email}
                              </span>
                            </div>
                          </div>
                        ) : header.key === "job" ? (
                          <>
                            <div className="user-job"> {rowData.job} </div>
                            <span className="email-data">
                              {rowData.industry}
                            </span>
                          </>
                        ) : header.key === "phoneNo" ? (
                          <div className="user-no">{rowData.phoneNo}</div>
                        ) : header.key === "gender" ? (
                          <div className="user-gender">{rowData.gender}</div>
                        ) : header.key === "dob" ? (
                          <>
                            <div className="user-age">
                              {" "}
                              {calculateAge(rowData.dob)} years old
                            </div>
                            <span className="email-data">{rowData.dob}</span>
                          </>
                        ) : header.key === "primaryCity" ? (
                          <>
                            <div className="user-city">
                              {rowData.primaryCity}
                            </div>
                          </>
                        ) : header.key === "salary" ? (
                          <>
                            <div className="user-income"> {rowData.salary}</div>
                          </>
                        ) : header.key === "instagram" ? (
                          <>
                            {rowData.instagram ? (
                              <a
                                className="linkedin-url"
                                href={`https://www.instagram.com/${rowData.instagram}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className="user-income">
                                  {rowData.instagram}
                                </div>
                              </a>
                            ) : (
                              <div className="user-income">-</div>
                            )}
                          </>
                        ) : header.key === "linkedin" ? (
                          <>
                            {rowData.linkedin ? (
                              <a
                                className="linkedin-url"
                                href={rowData.linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className="truncate-one-line-small user-income">
                                  {rowData.linkedin}
                                </div>
                              </a>
                            ) : (
                              <div className="truncate-one-line-small user-income">
                                -
                              </div>
                            )}
                          </>
                        ) : header.key === "dateCreated" ? (
                          <div className="user-date">
                            {formatDateString(rowData.dateCreated)}{" "}
                          </div>
                        ) : header.key === "approvedDate" ? (
                          <div className="user-date">
                            {formatDateString(rowData.approvedDate)}{" "}
                          </div>
                        ) : header.key === "membershipType" ? (
                          <>
                            <span
                              className="badge"
                              style={{
                                backgroundColor: getStatusColor(
                                  rowData.membershipType
                                ),
                                color: getStatusTextColor(
                                  rowData.membershipType
                                ),
                              }}
                            >
                              {rowData.membershipType}
                            </span>{" "}
                          </>
                        ) : header.key === "flag" ? (
                          <span className="Flagged">
                            {rowData.flag ? "FLAGGED" : "-"}
                          </span>
                        ) : header.key === "userStatus" ? (
                          <span className="Flagged">{rowData.userStatus}</span>
                        ) : (
                          rowData[header.key as keyof typeof rowData]
                        )}
                      </td>
                    ))}
                    {/* {applicantStatus === 'Approved' && ( */}
                    <td>
                      <button
                        onClick={() => handleViewClick(rowData)}
                        className={`${
                          isDropdownOpen === rowData?._id
                            ? "edit-button-clicked"
                            : "edit-button"
                        }`}
                      >
                        View
                      </button>
                    </td>
                    {/* )} */}
                    <td>
                      <button
                        onClick={() => handleEditApplicant(rowData)}
                        className={`${
                          isDropdownOpen === rowData?._id
                            ? "edit-button-clicked"
                            : "edit-button"
                        }`}
                      >
                        Edit
                      </button>
                    </td>

                    <td style={{ position: "relative" }}>
                      <button
                        data-testid="edit-button"
                        className={`${
                          isDropdownOpen === rowData?._id
                            ? "edit-button-clicked"
                            : "edit-button"
                        }`}
                        role="button"
                        name="Edit"
                        onClick={() => handleEditClick(rowData)}
                      >
                        Action
                      </button>

                      {/* edit button dropdown options according to applicant status*/}
                      {isDropdownOpen === rowData?._id && (
                        <div
                          ref={dropdownRef}
                          className="dropdown flex-1"
                          id="dropdown-option"
                        >
                          {applicantStatus === "Pending" && (
                            <>
                              <div
                                data-testid="approve"
                                className={`dropdown-option ${
                                  activeOption === "Approved"
                                    ? "active-option"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  handleOptionClick("Approved", e)
                                }
                              >
                                Approve membership
                              </div>
                              <div
                                className={`dropdown-option ${
                                  activeOption === "Waiting List"
                                    ? "active-option"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  handleOptionClick("Waiting List", e)
                                }
                              >
                                Move to waiting list
                              </div>
                              <div
                                className={`dropdown-option ${
                                  activeOption === "Not Approved"
                                    ? "active-option"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  handleOptionClick("Not Approved", e)
                                }
                              >
                                Move to Not approved
                              </div>
                              <div
                                className={`dropdown-option ${
                                  activeOption === "Email"
                                    ? "active-option"
                                    : ""
                                }`}
                                onClick={() => handleEmailClick(rowData.email)}
                              >
                                Email user
                              </div>
                              <div
                                className={`dropdown-option ${
                                  activeOption === "Flag" ? "active-option" : ""
                                }`}
                                onClick={(e) =>
                                  handleOptionClick(
                                    rowData.flag ? "UnFlag" : "Flag",
                                    e
                                  )
                                }
                              >
                                {rowData.flag
                                  ? "Unflag this user"
                                  : "Flag this user"}
                              </div>
                            </>
                          )}
                          {applicantStatus === "Not Approved" && (
                            <>
                              <div
                                className={`dropdown-option ${
                                  activeOption === "Approved"
                                    ? "active-option"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  handleOptionClick("Approved", e)
                                }
                              >
                                Approve membership
                              </div>
                              <div
                                className={`dropdown-option ${
                                  activeOption === "Waiting List"
                                    ? "active-option"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  handleOptionClick("Waiting List", e)
                                }
                              >
                                Move to waiting list
                              </div>
                              <div
                                className={`dropdown-option ${
                                  activeOption === "Email"
                                    ? "active-option"
                                    : ""
                                }`}
                                onClick={() => handleEmailClick(rowData.email)}
                              >
                                Email user
                              </div>
                              <div
                                className={`dropdown-option ${
                                  activeOption === "Flag" ? "active-option" : ""
                                }`}
                                onClick={(e) =>
                                  handleOptionClick(
                                    rowData.flag ? "UnFlag" : "Flag",
                                    e
                                  )
                                }
                              >
                                {rowData.flag
                                  ? "Unflag this user"
                                  : "Flag this user"}
                              </div>
                            </>
                          )}
                          {applicantStatus === "Approved" && (
                            <>
                              {filteredOptions.map((type) => (
                                <div
                                  key={type}
                                  className={`dropdown-option ${
                                    activeOption === type ? "active-option" : ""
                                  }`}
                                  onClick={(e) =>
                                    handleApprovedOptionClick(type, e)
                                  }
                                >
                                  {type}
                                </div>
                              ))}
                            </>
                          )}
                          {applicantStatus === "Waiting List" && (
                            <>
                              <div
                                className={`dropdown-option ${
                                  activeOption === "Approved"
                                    ? "active-option"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  handleOptionClick("Approved", e)
                                }
                              >
                                Approve membership
                              </div>
                              <div
                                className={`dropdown-option ${
                                  activeOption === "Not Approved"
                                    ? "active-option"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  handleOptionClick("Not Approved", e)
                                }
                              >
                                Move to Not approved
                              </div>
                              <div
                                className={`dropdown-option ${
                                  activeOption === "Email"
                                    ? "active-option"
                                    : ""
                                }`}
                                onClick={() => handleEmailClick(rowData.email)}
                              >
                                Email user
                              </div>
                              <div
                                className={`dropdown-option ${
                                  activeOption === "Flag" ? "active-option" : ""
                                }`}
                                onClick={(e) =>
                                  handleOptionClick(
                                    rowData.flag ? "UnFlag" : "Flag",
                                    e
                                  )
                                }
                              >
                                {rowData.flag
                                  ? "Unflag this user"
                                  : "Flag this user"}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        {showSpinner && (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )}
      </div>
      <TableFooter
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        handlePrev={handlePrev}
        handleNext={handleNext}
        totalPages={totalPages}
        currentPage={currentPage}
      />

      <GrandModal isOpen={isEditModalOpen} onClose={handleCloseModal}>
        {" "}
        <ViewApplicantModal
          handleClose={handleCloseModal}
          viewMember={viewApplicant}
          membersData={viewMembers}
          applicantStatus={applicantStatus}
        />
      </GrandModal>
    </section>
  );
};

export default Table;
