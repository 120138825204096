import URLS from '@/constants/api';
import { post} from "./api";

export const readNotificationsAdmin = async (
    id: string,
    isRead: boolean
  ) => {
    const requestData= {
        id: id,
        isRead: isRead,
      };
    const response = await post(URLS.READ_NOTIFICATIONS, requestData);
    return response;
  };
  
