import { post} from './api';
import URLS from '@/constants/api';

export const imageUpdate = async (data: any) => {
  try{
    const response = await post(URLS.UPDATE_IMAGES, data);
    return response;
  }
  catch (error) {
         throw new Error("An error occurred.");
      }     
};

export const businessImageUpdate = async (data: any) => {
  try{
    const response = await post(URLS.UPDATE_BUSINESS_IMAGES, data);
    return response;
  }
  catch (error) {
    throw new Error("An error occurred.");
      }     
};
