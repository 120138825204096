import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formData: {
  },
};

const formSlice = createSlice({
  name: 'campaign-form',
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetFormState: (state) => {
      state.formData = initialState.formData;
    },

  },

});

export const { updateFormData, resetFormState } = formSlice.actions;
export default formSlice.reducer;
