import { createSlice } from '@reduxjs/toolkit';

const locationSlice = createSlice({
  name: 'location',
  initialState: {
    eventDate: '',
    endDate: '',
    benefitUrl: '',
    isOnline: '',
    eventTime:'',
    country: '',
    city: '',
    address: '',
    addressLine1: '',
    addressLine2:'',
    postalCode: '',
    latitudes: null,
    longitudes: null,
  },
  reducers: {
    updateLocation: (state, action) => {
        const { isOnline,  benefitUrl, eventDate, endDate, eventTime, country, city, addressLine1,addressLine2, postalCode, longitudes, latitudes, address  } = action.payload;
        state.eventDate = eventDate;
        state.endDate = endDate;
        state.isOnline= isOnline;
        state.benefitUrl = benefitUrl;
        state.eventTime = eventTime;
        state.country = country;
        state.city = city;
        state.address =address;
        state.addressLine1 = addressLine1;
        state.addressLine2 = addressLine2;
        state.postalCode = postalCode;
        state.longitudes = longitudes;
        state.latitudes = latitudes;
      },
      resetLocationState: (state) => {
        state.eventDate = '';
        state.endDate = '';
        state.eventTime = '';
        state.benefitUrl = '',
        state.isOnline= '',
        state.country = '';
        state.city = '';
        state.address = '';
        state.addressLine1 = '';
        state.addressLine2 = '';
        state.postalCode = '';
        state.latitudes = null;
        state.longitudes = null;
      },
  },
});

export const { updateLocation, resetLocationState } = locationSlice.actions;
export default locationSlice.reducer;
