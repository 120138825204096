import { DocumentFormData } from '@/@Interfaces/BusinessStepFourProps';
import { uploadDocuments} from './api';
import URLS from '@/constants/api';

export const uploadDocumentFile = async (documents: DocumentFormData[], businessId: string | undefined) => { 

    const endpoint = `${URLS.UPLOAD_BULK_DOCUMENTS}/${businessId}/upload-docs-bulk`;
    const response = await uploadDocuments(endpoint, documents);
    return response;
};
