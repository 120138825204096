import { post, patch } from "./api";
import URLS from "../constants/api";
import { ICreateBusiness } from "@/@Interfaces/CreateBusiness";

export const getBusinesses = async (
  pageNumber: number,
  pageLimit: number,
  searchOn: string,
  search: string,
  sortBy: string,
  sortOn: string
) => {
  // Create the requestData object with all parameters
  const requestData = {
    pageNumber,
    pageLimit,
    ...(searchOn !== "" && { searchOn }), // Include searchOn if not an empty string
    ...(search !== "" && { search }), // Include search if not an empty string
    ...(sortBy !== "" && { sortBy }),
    ...(sortOn !== "" && { sortOn }),
  };

  const response = await post(URLS.BUSINESSES, requestData);
  return response;
};

export const createBusiness = async (businessData: any) => {
  const response = await post(URLS.CREATE_BUSINESS, businessData);
  return response;
};

export const updateBusiness = async (
  businessId: string | undefined,
  updatedBusinessData: any
) => {
  const endpoint = `${URLS.UPDATE_BUSINESS}?id=${businessId}`;
  const response = await patch(endpoint, updatedBusinessData);
  return response;
};
