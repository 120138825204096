import { post_2 } from './api';
import URLS from "../constants/api";

export const forgotPasswordApi = async (
  email: string,
) => {
    const requestData={
        email: email
    }
    
  const response = await post_2(URLS.FORGOT_PASSWORD, requestData);
  return response;
};

