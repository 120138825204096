import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formData: {
    businessName: '',
    businessWebsite: '',
    primaryEmailAddress: '',
    instagramHandler: '',
    linkedInURL: '',
    description: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: '',
    postalCode: '',
    businessCategory: '',
  },
};

const formSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetBusinessData: (state) => {
      state.formData = {
        businessName: '',
        businessWebsite: '',
        primaryEmailAddress: '',
        instagramHandler: '',
        linkedInURL: '',
        description: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '',
        postalCode: '',
        businessCategory: '',
      };
    },
  },
});

export const { updateFormData, resetBusinessData } = formSlice.actions;
export default formSlice.reducer;
