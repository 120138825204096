type TableColumn = {
  name: string;
  style: {
    width: string;
  };
  key: string;
};

export const enquiryTableHeaders: TableColumn[] = [
  { name: "First Name", style: { width: "25%" }, key: "firstName" },
  { name: "Last Name", style: { width: "25%" }, key: "lastName" },
  { name: "Email", style: { width: "25%" }, key: "email" },
  { name: "Date Added", style: { width: "25%" }, key: "dateCreated" },
];
