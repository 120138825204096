import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import { CustomSelectProps, Option, CSSProperties } from "@/@Interfaces/Option";
import { useDispatch } from "react-redux";
import { setdropdownOption } from "@redux/slices/applicantSlice";

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  width,
  color,
  border,
  backgroundColor,
  text,
  top,
  padding,
  height,
  id,
  register,
  fontSize,
  fontWeight,
  option: initialOption,
  required,
  label,
  formSubmitted,
  onChange,
  disabled,
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const [isSelectOpen, setSelectOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialOption || "");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  interface CustomStyles extends CSSProperties {
    zIndex?: number;
  }

  const customStyles: CustomStyles = {
    width: width || "100px",
    color: color || "black",
    backgroundColor: backgroundColor || "white",
    border: border || "none",
    padding: padding || "10px 40px",
    top: top || "12px",
    fontSize: fontSize || "12px",
    fontWeight: fontWeight || "600",
    zIndex: 1000,
  } as CustomStyles; // Type assertion

  const customStyles2: CSSProperties = {
    color: color || "#8E929A",
    backgroundColor: backgroundColor || "#1F1F22",
    border: "none",
    fontSize: "14px",
    height: height || "250px",
    maxHeight: "400px",
    overflowY: "scroll",
    zIndex: 1000,
  };

  useEffect(() => {
    setSelectedOption(initialOption);
    dispatch(setdropdownOption(initialOption));
  }, [initialOption]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setSelectOpen(false);
      }
    };

    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [initialOption]);

  useEffect(() => {
    if (formSubmitted && required && !selectedOption) {
      setError(`${label} required`);
    } else if (selectedOption) {
      setError("");
    }
  }, [required, selectedOption, label, formSubmitted]);

  const handleSelectClick = () => {
    if (!disabled) {
      setSelectOpen(!isSelectOpen);
    }
  };

  const handleOptionClick = (option: Option) => {
    if (!disabled) {
      setSelectedOption(option.label);
      dispatch(setdropdownOption(option.label));
      setSelectOpen(false);
      setError("");

      if (onChange) {
        onChange(option.label);
      }
    }
  };

  return (
    <div className="custom-select" ref={selectRef}>
      <div
        className={`select-selected ${
          isSelectOpen ? "select-arrow-active" : ""
        } ${selectedOption ? "text-white select-font" : "text-gray"}`}
        onClick={handleSelectClick}
        tabIndex={0}
        // style={{ height: height ? height : 'auto' }}
      >
        {selectedOption || text}
        <div className={`select-items ${isSelectOpen ? "" : "select-hide"}`}>
          {options.map((option: Option) => (
            <div
              key={option.value}
              className={`custom-option ${
                selectedOption === option.label ? "same-as-selected" : ""
              }`}
              onClick={() => {
                if (!disabled) {
                  handleOptionClick(option);
                  if (register) {
                    register(id || "", { required: true, value: option.value });
                  }
                }
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
      {error && <span className="error-color">*{error}</span>}
    </div>
  );
};

export default CustomSelect;
