import { IStatus } from "@/@Interfaces/status";

export const statusDropdown: IStatus []= [
    { value: "Live", label: "Live" },
    { value: "Draft", label: "Draft" },
    { value: "Paused", label: "Paused" },
  ];

  export const status: IStatus []= [
    { value: "Live", label: "Live" },
    { value: "Draft", label: "Draft" },
    { value: "Paused", label: "Paused" },
  ];