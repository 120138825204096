import React, { useState } from "react";
import "./style.css";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormData } from "@/@Interfaces/SignIn";
import { setTokens } from "@services/setTokens";
import { Link, useNavigate } from "react-router-dom";
import { logIn } from "@services/logIn";
import { ILoginResponse } from "@/@Interfaces/LoginResponse";
import Toast from "@components/Toast";
import logo from "@images/logo.svg";
import Footer from "@components/Footer";
import Input from "@components/Input";
import Loader from "../Spinner";
import CryptoJS from "crypto-js";

const LogIn: React.FC = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [email1, setEmail1] = useState("");
  const [pw, setPw] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: formValues,
  } = useForm<FormData>({
    mode: "onChange",
  });

  const navigate = useNavigate();
  const encryptionKey = process.env.REACT_APP_BASE_PRIVATE_KEY || "";

  // Encrypts a string using AES encryption with the specified key.
  const encryptString = (string: string, key: string) => {
    const keyUtf8 = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.AES.encrypt(string, keyUtf8, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  };

  // Submission of login Form
  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    setLoading(true);
    try {
      const encryptedString = encryptString(data.password, encryptionKey);
      const response = await logIn({
        email: data.email,
        password: encryptedString,
      });
      const loginResponse = response as ILoginResponse;
      setLoading(false);

      setTokens(
        loginResponse.data.accessToken,
        loginResponse.data.refreshToken
      );
      navigate("/home");
    } catch (error: any) {
      setLoading(false);
      setShowToast(true);
      const errorMessage =
        error.response?.data?.message || "Error during authentication";
      setToastMessage(errorMessage);
    }
  };

  const handleEmailChange = (value: string) => {
    setEmail1(value);
  };

  const handlePasswordChange = (value: string) => {
    setPw(value);
  };

  const onClose = () => {
    setShowToast(false);
  };

  const handleNext = () => {
    formValues("email", email1);
    formValues("password", pw);
  };

  return (
    <section className="login-section">
      <div className="form-content">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-9">
            <div className="login-content">
              <div className="login-header">
                <img src={logo} />
                <Toast
                  role="toast"
                  content={toastMessage}
                  show={showToast}
                  onClose={onClose}
                />
              </div>
              <div style={{ backgroundColor: " #1a1a1c", borderRadius: "6px" }}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="login-form-parent"
                >
                  <div className="login-form">
                    <Input
                      type="email"
                      id="email"
                      placeholder="Enter your email address"
                      register={register}
                      label="Email Address"
                      error={(errors as any).email?.message}
                      value={email1}
                      onChange={handleEmailChange}
                      maxLength={50}
                    />
                    <Input
                      type="password"
                      id="password"
                      placeholder="Enter your password"
                      register={register}
                      label="Password"
                      error={(errors as any).password?.message}
                      value={pw}
                      onChange={handlePasswordChange}
                      maxLength={100}
                    />
                    <Link to="/portal/forgot-password">
                      {" "}
                      <div className="form-text">Forgot your password?</div>
                    </Link>
                  </div>
                  <div className="btn-wrapper">
                    <button
                      type="submit"
                      onClick={handleNext}
                      className="login-button"
                      style={{
                        padding: loading ? "0px 176.5px" : "0px 167.5px",
                      }}
                    >
                      {loading === true ? <Loader /> : "LOG IN"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "5rem" }}> </div>
      </div>
      <Footer></Footer>
    </section>
  );
};

export default LogIn;
