import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../Button";
import "./style.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NavigationPaths } from "@/constants/paths";
import { CampaignIcon, ExitIcon } from "@/constants/utils/svgs";
import SelectWrapper from "../selectWrapper";
import Modal from "../ExitModal";
import { BusinessCategory } from "@/constants/BusinessCategory";
import BusinessImage from "../BusinessImage";
import { StepOneProps } from "@/@Interfaces/BusinessStepOneProps";

const StepOneBusiness: React.FC<StepOneProps> = ({
  setSteps,
  businessCategory,
  setBusinessCategory,
  businessName,
  setBusinessName,
  businessWebsite,
  setBusinessWebsite,
  descriptionProp,
  setDescription,
  primaryEmailAddress,
  setPrimaryEmailAddress,
  businessLogo,
  setBusinessLogo,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [businessLogoSelected, setBusinessLogoSelected] = useState(false);
  const [websiteError, setWebsiteError] = useState("");

  const business_name = useSelector(
    (state: any) => state.business_form.formData.businessName
  );
  const business_website = useSelector(
    (state: any) => state.business_form.formData.businessWebsite
  );
  const description = useSelector(
    (state: any) => state.business_form.formData.description
  );
  const pea = useSelector(
    (state: any) => state.business_form.formData.primaryEmailAddress
  );
  const business_category = useSelector(
    (state: any) => state.business_form.formData.businessCategory
  );

  const initialValues = {
    name: business_name,
    business_website: business_website,
    description: description,
    business_category: business_category,
    pea: pea,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("*Business name required"),
    business_website: Yup.string().required("*Business website required"),
    description: Yup.string().required("*Business description required"),
    business_category: Yup.string().required("*Business category required"),
    pea: Yup.string().required("*Primary Email Address required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setSteps(2);
    },
  });

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const validateDescription = (input: string) => {
    const regex = /^[a-zA-Z0-9\s#\-_,()]*$/;
    return input.split('').filter(char => regex.test(char)).join('');
  };

  const handleDescriptionChange = (e: any) => {
    const input = e.target.value;
    const validatedInput = validateDescription(input);
    setDescription(validatedInput);
  }

  const handleExit = () => {
    navigate(NavigationPaths.BUSINESSES);
  };

  const handleNext = () => {
    setFormSubmitted(true);
  };

  const onImageSelected = (imageType: string, file: File) => {
    if (imageType === "business") {
      setBusinessLogoSelected(true);
    }
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      name: business_name,
      business_website: business_website,
      description: description,
      business_category: business_category,
      pea: pea,
    });
  }, [business_name, business_website, description, business_category, pea]);

  //if none of the field is empty go to step 2 of form
  const handleSubmitForm = (e: any) => {
    e.preventDefault();
    if (
      businessName &&
      businessWebsite &&
      descriptionProp &&
      businessCategory &&
      primaryEmailAddress &&
      !emailError &&
      websiteError === ""
    ) {
      setSteps(2);
    }
  };
  const validateEmail = (email: string) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setPrimaryEmailAddress(email);

    const isValid = validateEmail(email);
    setEmailError(isValid ? "" : "*Invalid email address");
  };

  return (
    <section className="create-campaign-section">
      <div className="row">
        <div className="col-lg-6 col-md-9 adjust-height-fhbsh">
          <div className="form-section">
            <div className="form-header">
              <h2>Add a business</h2>
              <p>Step 1 of 4</p>
            </div>

            <form onSubmit={handleSubmitForm}>
              <div className="row mb-8">
                {businessLogo ? ( // Check if an image is selected
                  <>
                    <div className="col-5 d-flex flex-column justify-content-start b-imgg">
                      {" "}
                      {/* If image is selected, use col-6 */}
                      <BusinessImage
                        required={false}
                        text="Business Logo"
                        formSubmitted={formSubmitted}
                        imageType="business"
                        onImageSelected={onImageSelected}
                        value={businessLogo}
                        setValue={setBusinessLogo}
                      />
                    </div>
                    <div className="col-7 d-flex flex-column justify-content-center">
                      {" "}
                      {/* If image is selected, use col-6 */}
                      <div
                        style={{
                          color: "#ffffff",
                          fontSize: "16px",
                          marginLeft: "20px",
                        }}
                      >
                        Business Logo
                      </div>
                      <span
                        style={{
                          color: "#8E929A",
                          fontSize: "11px",
                          marginLeft: "20px",
                          marginTop: "5px",
                        }}
                      >
                        This should be your primary logo and should be no more
                        than 50 KB
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-4 d-flex flex-column justify-content-start b-imgg">
                      {" "}
                      {/* If no image is selected, use col-4 */}
                      <BusinessImage
                        required={false}
                        text="Business Logo"
                        formSubmitted={formSubmitted}
                        imageType="business"
                        onImageSelected={onImageSelected}
                        value={businessLogo}
                        setValue={setBusinessLogo}
                      />
                    </div>
                    <div className="col-8 d-flex flex-column justify-content-center">
                      {" "}
                      {/* If no image is selected, use col-8 */}
                      <div
                        style={{
                          color: "#ffffff",
                          fontSize: "16px",
                          marginLeft: "20px",
                        }}
                      >
                        Business Logo
                      </div>
                      <span
                        style={{
                          color: "#8E929A",
                          fontSize: "11px",
                          marginLeft: "20px",
                          marginTop: "5px",
                        }}
                      >
                        This should be your primary logo and should be no more
                        than 50 KB.
                      </span>
                    </div>
                  </>
                )}
              </div>

              <div className="row mb-8">
                <div className="business-name-field d-flex flex-column col-6 ">
                  <label className="form-label text-truncate">
                    Business Name *
                  </label>
                  <input
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    name="business name"
                    className="input-value-field"
                    placeholder="Please enter your company name"
                    maxLength={50}
                  />
                  {formSubmitted && !businessName && (
                    <div className="my-1 error-text-asd errorField">
                      *Business name required
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column col-6 ">
                  <label className="form-label">Website *</label>
                  <input
                    type="text"
                    value={businessWebsite}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setBusinessWebsite(inputValue);

                      if (
                        !inputValue ||
                        !/^www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(inputValue)
                      ) {
                        setWebsiteError("Invalid format");
                      } else {
                        setWebsiteError("");
                      }
                    }}
                    name="website name"
                    className="input-value-field"
                    placeholder="www.yourwebsite.com"
                    maxLength={200}
                  />
                  {formSubmitted && !businessWebsite && (
                    <div className="my-1 error-text-asd errorField">
                      *Website required
                    </div>
                  )}
                  {websiteError && (
                    <div className="my-1 error-text-asd errorField">
                      {websiteError}
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-8">
                <div className="d-flex flex-column col-6 ">
                  <label className="form-label text-truncate">
                    Primary Email Address *
                  </label>
                  <input
                    type="text"
                    value={primaryEmailAddress}
                    onChange={handleEmailChange}
                    name="primary email address"
                    className="input-value-field"
                    placeholder="Most suitable contact"
                    maxLength={50}
                  />
                  {formSubmitted && !primaryEmailAddress && (
                    <div className="my-1 error-text-asd errorField">
                      *Email address required
                    </div>
                  )}
                      {primaryEmailAddress && emailError && (
                            <div className="my-1 error-text-asd errorField">
                              {emailError}
                            </div>
                          )}
                </div>
                <div className="d-flex flex-column col-6 categories-wrapper">
                  <label className="form-label">Business Category *</label>
                  <SelectWrapper
                    options={BusinessCategory}
                    value={businessCategory}
                    setValue={setBusinessCategory}
                    placeholder="Please select a category"
                  />
                  {formSubmitted && !businessCategory && (
                    <div className="my-1 error-text-asd errorField">
                      *Category required
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-8">
                <div className="col-12">
                  <label className="form-label">Business Description *</label>
                  <textarea
                    value={descriptionProp}
                    onChange={handleDescriptionChange}
                    id="businessDesign"
                    className="text-area-2"
                    placeholder="Description of the business"
                    maxLength={999}
                  />
                  {formSubmitted && !descriptionProp && (
                    <div className="my-1 error-text-asd errorField">
                      *Business description required
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-8">
                <div className="next-button-wrapper">
                  <Button
                    title={"NEXT"}
                    type={"submit"}
                    backgroundColor="white"
                    color="black"
                    border="none"
                    borderRadius="6px"
                    padding="10px 48px"
                    fontSize="12px"
                    fontWeight="600"
                    onButtonClick={handleNext}
                  />
                  <Modal
                    showModal={showModal}
                    handleClose={handleCloseModal}
                    buttonTitle="EXIT"
                    handleExit={handleExit}
                    title="Are you sure you want to exit?"
                    message="Are you sure you want to exit creating a new business? The information will not be saved."
                  />
                </div>
              </div>
            </form>
          </div>
          {/* progress bar */}
          <div style={{ position: "relative" }}>
            {" "}
            <div className="mt-4 row stage-bar">
              <div className="col-3 step-1"></div>
              <div className="col-3"></div>
              <div className="col-3"></div>
              <div className="col-3"></div>
            </div>
          </div>
        </div>
        {/* Exit Modal */}
        <div className="col-lg-6 col-md-3 p-0 form-image-right">
          <div className="image-section">
            <div className="overlay"></div>
            <span onClick={handleOpenModal} className="exit-icon">
              {ExitIcon()}
            </span>
            <div className="campaign-icon">{CampaignIcon()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepOneBusiness;
