import cogoToast from 'cogo-toast';    

const showToast = (message: string, type: keyof typeof cogoToast = 'success'): void => {
    const options: any = {
        position: 'top-right',
        hideAfter: 5, // seconds
        type: type,
        theme: 'dark',
    };

    if (type in cogoToast) {
        cogoToast[type](message, options);
    } else {
        console.error(`Invalid toast type: ${type}`);  
    }
};

export default showToast;
