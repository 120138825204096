import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NavigationPaths } from "@/constants/paths";
import { CampaignIcon, ExitIcon } from "@/constants/utils/svgs";
import SelectWrapper from "../selectWrapper";
import Image from "../Image";
import Toggle from "react-toggle";
import Modal from "../ExitModal";
import { status } from "@/constants/status";
import { categories } from "@/constants/categories";
import { BenefitStepOneProps } from "@/@Interfaces/BenefitsProps";
import { benefit_types } from "@/constants/benefitTypes";
import MultiSelectWrapper from "../MultiSelect";
import { event_available } from "@/constants/eventAvailable";

const BenefitStepOne: React.FC<BenefitStepOneProps> = ({
  isToggled,
  setIsToggled,
  setSteps,
  select,
  setSelect,
  categoriesOption,
  setCategoriesOption,
  benefitTypeOption,
  setBenefitTypeOption,
  benefitName,
  setBenefitName,
  benefitDescription,
  setBenefitDescription,
  feedImage,
  setFeedImage,
  pageImage,
  setPageImage,
  availableTo,
  setAvailableTo,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [feedImageSelected, setFeedImageSelected] = useState(false);
  const [pageImageSelected, setPageImageSelected] = useState(false);

  const event_name = useSelector(
    (state: any) => state.campaign_form.formData.name
  );
  const status_status = useSelector(
    (state: any) => state.campaign_form.formData.status
  );
  const description = useSelector(
    (state: any) => state.campaign_form.formData.description
  );
  const categories_categories = useSelector(
    (state: any) => state.campaign_form.formData.category
  );
  const type_type = useSelector(
    (state: any) => state.campaign_form.formData.type
  );

  const initialValues = {
    name: event_name,
    status_status: status_status,
    description: description,
    categories_categories: categories_categories,
    type_type: type_type,
    isToggled: isToggled,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("*Benefit name required"),
    status_status: Yup.string().required("*Benefit required"),
    description: Yup.string().required("*Benefit description required"),
    categories_categories: Yup.string().required("*Category required"),
    type_type: Yup.string().required("*Benefit type required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setSteps(2);
    },
  });

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExit = () => {
    navigate(NavigationPaths.BENEFITS);
  };

  const handleNext = () => {
    setFormSubmitted(true);
  };

  const onImageSelected = (imageType: string, file: File) => {
    if (imageType === "feed") {
      setFeedImageSelected(true);
    } else if (imageType === "page") {
      setPageImageSelected(true);
    }
  };

  //set values of form using formik
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      name: event_name,
      status_status: status_status,
      description: description,
      categories_categories: categories_categories,
      type_type: type_type,
      isToggled: isToggled,
    });
  }, [
    event_name,
    status_status,
    description,
    categories_categories,
    type_type,
    isToggled,
  ]);

  //if all values are filled go to step 2
  const handleSubmitForm = (e: any) => {
    e.preventDefault();
    if (
      benefitName &&
      status &&
      benefitDescription &&
      benefitTypeOption &&
      categoriesOption &&
      feedImage &&
      pageImage &&
      availableTo &&
      benefitName.length <= 100
    ) {
      setSteps(2);
    }
  };

  return (
    <section className="create-benefit-section">
      <div className="row">
        <div className="col-lg-6 col-md-9 adjust-height-fhbsh">
          <div className="benefit-form-section">
            <div className="benefit-form-header">
              <h2>Create a Benefit</h2>
              <p>Step 1 of 2</p>
            </div>
            <form onSubmit={handleSubmitForm}>
              <div className="row mb-8">
                <div className="d-flex flex-column col-6 ">
                  <label className="form-label">Benefit Name *</label>
                  <input
                    value={benefitName}
                    onChange={(e) => setBenefitName(e.target.value)}
                    name="name"
                    className="input-value-field"
                    placeholder="Name of the benefit"
                    maxLength={50}
                  />
                  {formSubmitted && !benefitName && (
                    <div className="my-1 error-text-asd errorField">
                      *Benefit name required
                    </div>
                  )}
                  {formSubmitted && benefitName && benefitName.length > 100 && (
                    <div className="my-1 error-text-asd errorField">
                      Benefit name should not exceed 100 characters
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column col-6 status-wrapper ">
                  <label className="form-label">Status *</label>
                  <SelectWrapper
                    options={status}
                    value={select}
                    setValue={setSelect}
                    placeholder="Please Select Status"
                  />
                  {formSubmitted && !select && (
                    <div className="my-1 error-text-asd errorField">
                      *Status required
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-8">
                <div className="col-12">
                  <label className="form-label">Benefit Description *</label>
                  <textarea
                    value={benefitDescription}
                    onChange={(e) => setBenefitDescription(e.target.value)}
                    id="benefitDesign"
                    className="text-area-2"
                    placeholder="Description of the benefit"
                    maxLength={999}
                  />
                  {formSubmitted && !benefitDescription && (
                    <div className="my-1 error-text-asd errorField">
                      *Benefit description required
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-8">
                <div className="d-flex flex-column col-6 benefits-categories-wrapper">
                  <label className="form-label">Categories *</label>
                  <SelectWrapper
                    options={categories}
                    value={categoriesOption}
                    setValue={setCategoriesOption}
                    placeholder="Choose benefit categories"
                  />
                  {formSubmitted && !categoriesOption && (
                    <div className="my-1 error-text-asd errorField">
                      *Category required
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column col-6 benefit-wrapper">
                  <label className="form-label">Type of Benefit *</label>
                  <SelectWrapper
                    options={benefit_types}
                    value={benefitTypeOption}
                    setValue={setBenefitTypeOption}
                    placeholder="Choose benefit type"
                  />
                  {formSubmitted && !benefitTypeOption && (
                    <div className="my-1 error-text-asd errorField">
                      *Type of benefit required
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-8">
                <div className="d-flex flex-column col-12 categories-wrapper">
                  <label className="form-label">Available to *</label>
                  <MultiSelectWrapper
                    value={availableTo}
                    options={event_available}
                    placeholder="Select benefit access for members"
                    setValue={setAvailableTo}
                  />
                  {formSubmitted && !availableTo && (
                    <div className="my-1 error-text-asd errorField">
                      *Available to required
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-8">
                <div className="col-6 d-flex flex-column justify-content-start ">
                  <label className="form-label">Benefit Feed Image *</label>
                  <div className="img-desc-cni2 mb-3">
                    This will be shown as the main image on your offer feed in
                    the app.
                    {/* Size 300px x 360px. */}
                  </div>
                  <Image
                    required={true}
                    text="Benefit Feed Image"
                    formSubmitted={formSubmitted}
                    imageSize={{ width: 300, height: 360 }}
                    imageType="feed"
                    onImageSelected={onImageSelected}
                    value={feedImage}
                    setValue={setFeedImage}
                  />
                </div>
                <div className="col-6 d-flex flex-column justify-content-start ">
                  <label className="form-label">Benefit Page Image *</label>
                  <div className="img-desc-cni2 mb-3">
                    This will be shown as the primary image on the benefit page
                    in the app.
                    {/* Size 320px x 480px. */}
                  </div>
                  <Image
                    required={true}
                    text="Benefit Page Image"
                    formSubmitted={formSubmitted}
                    imageSize={{ width: 320, height: 480 }}
                    imageType="page"
                    onImageSelected={onImageSelected}
                    value={pageImage}
                    setValue={setPageImage}
                  />
                </div>
              </div>

              <div className="row mt-8 mb-4">
                <div className="col-lg-6">
                  <div className="gap-4 d-flex justify-content-start align-items-center toggle-button-wrap-4">
                    <div className="feature-campaign-de3">
                      Featured Benefits
                    </div>
                    <div className="toggle-button-wrap f-benefit">
                      <Toggle
                        id="cheese-status"
                        defaultChecked={isToggled}
                        icons={false}
                        onChange={(e) =>
                          setIsToggled((prevState) => !prevState)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="next-button-wrapper">
                    <Button
                      title={"NEXT"}
                      type={"submit"}
                      backgroundColor="white"
                      color="black"
                      border="none"
                      borderRadius="6px"
                      padding="10px 48px"
                      fontSize="12px"
                      fontWeight="600"
                      onButtonClick={handleNext}
                    />
                    {/* Exit Modal */}
                    <Modal
                      showModal={showModal}
                      handleClose={handleCloseModal}
                      buttonTitle="EXIT"
                      handleExit={handleExit}
                      title="Are you sure you want to exit?"
                      message="Are you sure you want to exit creating a new benefit? The information will not be saved."
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div style={{ position: "relative" }}>
            {" "}
            {/* Progress bar at the bottom*/}
            <div className="stage-bar-2 row">
              <div className="col-4 step-1"></div>
              <div className="col-4"></div>
              <div className="col-4"></div>
            </div>
          </div>
        </div>
        {/* Exit Modal Button*/}
        <div className="col-lg-6 col-md-3 p-0 form-image-right">
          <div className="image-section">
            <div className="overlay"></div>
            <span onClick={handleOpenModal} className="exit-icon">
              {ExitIcon()}
            </span>
            <div className="campaign-icon">{CampaignIcon()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitStepOne;
