import URLS from '@/constants/api';
import { post_2  } from "./api";

export const downloadFiles = async (url: string
) => {
  const data ={
    url: url
  }
  const response = await post_2(URLS.DOWNLOAD_BUSINESS_FILES, data);
  return response;
};
