export const campaignTableHeaders: any = [
  { name: "EVENT", style: { width: "16%" }, key: "name" },
  { name: "DESCRIPTION", style: { width: "16%" }, key: "description" },
  { name: "CATEGORY", style: { width: "7%" }, key: "category" },
  {
    name: "TYPE",
    style: { width: "8%", paddingLeft: "1rem" },
    key: "type",
  },
  { name: "DATE / TIME", style: { width: "15%" }, key: "eventDate" },
  { name: "ADDRESS", style: { width: "15%" }, key: "addressLine1" },
  { name: "PRICE", style: { width: "8%" }, key: "ticketPrice" },
  { name: "REDEMPTION", style: { width: "7%" }, key: "redemption" },
  { name: "STATUS", style: { width: "8%" }, key: "status" },
];
