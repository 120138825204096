type TableColumn = {
  name: string;
  style: {
    width: string;
  };
  key: string;
};

export const contactUsTableHeader: TableColumn[] = [
  { name: "FULL NAME", style: { width: "25%" }, key: "fullName" },
  { name: "PHONE NO", style: { width: "25%" }, key: "phoneNo" },
  { name: "ENQUIRY", style: { width: "25%" }, key: "enquiry" },
  { name: "DATE ADDED", style: { width: "25%" }, key: "dateCreated" },
];
