export const industryOptions = [
    { value: "Art", label: "Art" },
    { value: "Beauty", label: "Beauty" },
    { value: "Design", label: "Design" },
    { value: "Fashion", label: "Fashion" },
    { value: "Photography", label: "Photography" },
    { value: "Business", label: "Business" },
    { value: "Entrepreneur", label: "Entrepreneur" },
    { value: "Government", label: "Government" },
    { value: "Media", label: "Media" },
    { value: "Medicine", label: "Medicine" },
    { value: "Science", label: "Science" },
    { value: "Technology", label: "Technology" },
    { value: "Athletics", label: "Athletics" },
    { value: "Film", label: "Film" },
    { value: "Food", label: "Food" },
    { value: "Journalism", label: "Journalism" },
  ];