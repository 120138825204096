export function convertDateFormat(inputDate: string): string {
    const dateObject = new Date(inputDate);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear(); 

    const formattedDate = `${month < 10 ? "0" : ""}${month}/${
      day < 10 ? "0" : ""
    }${day}/${year}`;

    return formattedDate;
  }

  export const getStatusColor = (status: string): string => {
    switch (status.toLowerCase()) {
      case "draft":
        return "#6F5BBF";
      case "live":
        return "#74DFAB";
      case "paused":
        return "#8F98A1";
      default:
        return "";
    }
  };
 
  export const getStatusTextColor = (status: string): string => {
    switch (status.toLowerCase()) {
      case "draft":
      case "paused":
        return "#FFFFFF";
      case "live":
        return "#121212";
      default:
        return "";
    }
  };
