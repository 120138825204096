import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  dropdownOption: '25'
}

const applicantSlice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    setdropdownOption: (state, action) => {
      state.dropdownOption = action.payload
    },
  },
})

export const { setdropdownOption} = applicantSlice.actions
export default applicantSlice.reducer
