import React, { useState, useEffect } from "react";
import Button from "../Button";
import { format } from "date-fns";
import Toggle from "react-toggle";
import Loader from "../Spinner";
import SelectWrapper from "../selectWrapper";
import { statusDropdown } from "@/constants/status";
import { categories } from "@/constants/categories";
import { types } from "@/constants/eventTypes";
import EventImage from "../EventImage";
import showToast from "../cogo-toast";
import "./style.css";
import TimezoneSelect from "react-timezone-select";
import { AddIcon, timeIcon } from "@/constants/utils/svgs";
import { searchIcon } from "@/constants/utils/svgs";
import { StylesConfig } from "react-select";
import { countries } from "@/constants/countries";
import { Line } from "@/constants/utils/svgs";
import { EditModalProps } from "@/@Interfaces/ModalProps";
import { calenderIcon } from "@/constants/utils/svgs";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { uploadFile } from "@/services/uploadFiles";
import { updateEvent } from "@/services/editCampaign";
import { deleteTicket } from "@/services/deleteTicket";
import MultiSelectWrapper from "../MultiSelect";
import DatePicker from "react-datepicker";

import { TicketFormData } from "@/@Interfaces/EditCampaignProps";
import { event_available } from "@/constants/eventAvailable";
import { classification_options } from "@/constants/classification";
import axios from "axios";
import { customTimezones } from "@/constants/timezones";
import URLS from "@/constants/api";
import Video from "../Video";
import TimeRangePicker from "../TimeRangePicker";

const phoneRegex = /^(?:\+|00)1[-.\s]?\d{3}[-.\s]?\d{3}[-.\s]?\d{4}$/;
const colorCodeRegex = /^#[a-zA-Z0-9]{6}$/;

const EditCampaignModal: React.FC<EditModalProps> = ({
  handleClose,
  editEventData,
  viewEvent,
}) => {
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [ticketAvailableOptions, setTicketAvailableOptions] =
    useState<string[]>(event_available);

  const [phoneError, setphoneError] = useState("");
  const [urlError, setUrlError] = useState("");

  const [editButton, setEditButton] = useState(false);

  const [ticketPriceError, setTicketPriceError] = useState<string>("");
  const [dateError, setDateError] = useState<string | null>(null);
  const [quantityError, setQuantityError] = useState<string>("");
  const [quantityErrorIndex, setQuantityErrorIndex] = useState(0);
  const [colorError, setcolorError] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [timeError, setTimeError] = useState<string | null>(null);
  const [postError, setPostError] = useState("");
  const [ticketNameError, setTicketNameError] = useState<string>("");

  const [externalUrlError, setExternalUrlError] = useState("");

  const [editedFormData, seteditedFormData] = useState<any>({
    accessEvent: editEventData.event?.accessEvent,
    addressLine1: "",
    addressLine2: "",
    availableTo: [""],
    cardColor: "",
    category: [""],
    city: "",
    classification: "",
    country: "",
    postalCode: "",
    description: "",
    endDate: "",
    eventDate: "",
    eventEndDateAndTime: "",
    eventStartDateAndTime: "",
    eventTime: "",
    externalSiteUrl: "",
    feedImage: "",
    guestLimit: 0,
    image: "",
    latitudes: null,
    longitudes: null,
    name: "",
    pageImage: "",
    phoneNo: "",
    status: "",
    thumbnail: "",
    ticketType: "",
    timezone: "",
    topEvent: editEventData.event?.topEvent,
    type: "",
    videoUrl: "",
    website: "",
    _id: "",
    venueImage: "",
  });

  const {
    accessEvent,
    addressLine1,
    addressLine2,
    availableTo,
    cardColor,
    category,
    city,
    classification,
    country,
    postalCode,
    description,
    endDate,
    eventDate,
    eventTime,
    externalSiteUrl,
    feedImage,
    guestLimit,
    latitudes,
    longitudes,
    name,
    pageImage,
    phoneNo,
    status,
    timezone,
    topEvent,
    type,
    videoUrl,
    website,
    venueImage,
  } = editedFormData;

  useEffect(() => {
    if (editEventData.event) {
      seteditedFormData(editEventData.event);
    }
  }, [editEventData]);

  const handleChange = (
    value: number | string | boolean | string[],
    name: string
  ) => {
    seteditedFormData((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  //event details data

  const [tickets, setTickets] = useState<TicketFormData["tickets"]>(
    editEventData.tickets.map((ticket: any) => ({
      ...ticket,
      eventId: editEventData.event._id,
    }))
  );
  useEffect(() => {
    if (availableTo.length === 1) {
      setTicketAvailableOptions(availableTo);
    } else {
      setTicketAvailableOptions(event_available); // Reset to all options if no specific selection
    }
  }, [availableTo]);

  //Add the ticket
  useEffect(() => {
    if (type === "Ticketed" && tickets.length === 0) {
      handleAddTicket();
    }
  }, [type, tickets]);

  useEffect(() => {
    if (type === "Ticketed") {
      handleChange(0, "guestLimit");
    }
  }, [type]);

  const API_KEY = process.env.REACT_APP_BASE_API_KEY;

  const customStyles2: StylesConfig<any, false> = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      input: {
        marginLeft: "10px !important",
      },
      backgroundColor: "transparent",
      borderColor: state.isFocused ? "none" : "#8E929A",
      boxShadow: state.isFocused ? "0 0 0 1px #BBA383" : "none",
      borderRadius: "6px",
      padding: "5px 0px",
      height: "48px",
      "&:focus": {
        outline: "1px solid #BBA383",
        border: "none !important",
      },
      "&:hover": {
        outline: "none",
        borderColor: "#8E929A",
        boxShadow: "none !important",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#BBA383" : "#1f1f22",
      fontSize: "12px",
      color: state.isSelected ? "white" : "inherit",
      "&:hover": {
        backgroundColor: "#BBA383",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      marginLeft: "10px",
      fontSize: "12px",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "8px !important",
      backgroundColor: "#1f1f22",
      color: "white",
      zIndex: 9999,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      // display: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      // display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "12px",
      marginLeft: "10px",
    }),
  };

  const fetchTimezone = async (lat: any, lng: any) => {
    try {
      const response = await axios.get(URLS.GET_TIMEZONE as string, {
        params: {
          location: `${lat},${lng}`,
          timestamp: Math.floor(Date.now() / 1000),
          key: API_KEY,
        },
      });
      const timezoneId = response.data.timeZoneId;
      const rawOffset = response.data.rawOffset;
      handleChange(
        {
          value: timezoneId,
          label: timezoneId,
          offset: rawOffset,
        } as any,
        "timezone"
      );
    } catch (error) {
      // showToast('Error fetching timezone:', "error");
    }
  };

  //upload images to cloud front
  useEffect(() => {
    const uploadFiles = async () => {
      const feedImageChange =
        typeof feedImage !== "string" &&
        feedImage !== null &&
        videoUrl !== undefined;
      const pageImageChange =
        typeof pageImage !== "string" &&
        pageImage !== null &&
        videoUrl !== undefined;
      const videoUrlChange =
        typeof videoUrl !== "string" &&
        videoUrl !== null &&
        videoUrl !== undefined;

      const venueImageChange =
        typeof venueImage !== "string" &&
        venueImage !== null &&
        venueImage !== undefined;

      if (feedImageChange) {
        const uploadFiles = {
          type: "Events",
          id: editEventData.event._id,
          feedImage: feedImage,
        };

        try {
          const upload = await uploadFile(uploadFiles);

          handleChange(upload[0], "feedImage");

          setEditButton(false);
        } catch (error: any) {
          setEditButton(false);
          if (error.message === "Network Error") {
            showToast(
              "It seems you're offline. Please check your connection and try again.",
              "error"
            );
          } else {
            showToast(
              "Error in uploading files. Please upload again.",
              "error"
            );
          }
        }
      }

      if (pageImageChange) {
        const uploadFiles = {
          type: "Events",
          id: editEventData.event._id,
          pageImage: pageImage,
        };

        try {
          const upload = await uploadFile(uploadFiles);

          handleChange(upload[0], "pageImage");

          setEditButton(false);
        } catch (error: any) {
          setEditButton(false);
          if (error.message === "Network Error") {
            showToast(
              "It seems you're offline. Please check your connection and try again.",
              "error"
            );
          } else {
            showToast(
              "Error in uploading files. Please upload again.",
              "error"
            );
          }
        }
      }

      if (videoUrlChange) {
        const uploadFiles = {
          type: "Events",
          id: editEventData.event._id,
          videoUrl: videoUrl,
        };

        try {
          const upload = await uploadFile(uploadFiles);
          handleChange(upload[0], "videoUrl");
          setEditButton(false);
        } catch (error: any) {
          setEditButton(false);
          if (error.message === "Network Error") {
            showToast(
              "It seems you're offline. Please check your connection and try again.",
              "error"
            );
          } else {
            showToast(
              "Error in uploading files. Please upload again.",
              "error"
            );
          }
        }
      }

      if (venueImageChange) {
        const uploadFiles = {
          type: "Events",
          id: editEventData.event._id,
          venueImage: venueImage,
        };

        try {
          const upload = await uploadFile(uploadFiles);
          handleChange(upload[0], "venueImage");
          setEditButton(false);
        } catch (error: any) {
          setEditButton(false);
          if (error.message === "Network Error") {
            showToast(
              "It seems you're offline. Please check your connection and try again.",
              "error"
            );
          } else {
            showToast(
              "Error in uploading files. Please upload again.",
              "error"
            );
          }
        }
      }
      // if (feedImageChange && pageImageChange) {
      //   const uploadFiles = {
      //     type: "Events",
      //     id: editEventData.event._id,
      //     pageImage: pageImage,
      //     feedImage: feedImage,
      //   };

      //   try {
      //     const upload = await uploadFile(uploadFiles);
      //     setFeedImageS3(upload[0]);
      //     setPageImageS3(upload[1]);
      //     setEditButton(false);
      //   } catch (error: any) {
      //     if (error.message === "Network Error") {
      //       showToast(
      //         "It seems you're offline. Please check your connection and try again.",
      //         "error"
      //       );
      //     } else {
      //       showToast(
      //         "Error in uploading files. Please upload again.",
      //         "error"
      //       );
      //     }
      //     setEditButton(false);
      //   }
      // }
    };

    uploadFiles();
  }, [pageImage, feedImage, videoUrl, venueImage]);

  //add a new ticket
  const handleAddTicket = () => {
    setTickets((prevTickets) => [
      ...prevTickets,
      {
        eventId: editEventData.event._id,
        name: "",
        price: "",
        description: "",
        availableTo: [],
        quantityAvailable: "",
        quantityRedeemed: 0,
      },
    ]);
  };

  //get image file name from image's cdn url
  function processLogoUrl(logoUrl: string): string {
    const lastSlashIndex = logoUrl.lastIndexOf("/");
    const processedUrl =
      lastSlashIndex !== -1 ? logoUrl.substring(lastSlashIndex + 1) : logoUrl;
    const finalProcessedUrl = processedUrl.replace(/%20/g, " ");
    return finalProcessedUrl;
  }

  const onImageSelected = async (imageType: string, file: File) => {
    console.log("");
  };

  const customStyles: StylesConfig<any, false> = {
    container: (provided) => ({
      ...provided,
      paddingRight: "unset",
    }),
    control: (provided, state) => ({
      ...provided,
      opacity: 1,
      backgroundColor: "transparent",
      // backgroundColor: "#1f1f22",
      borderColor: state.isFocused ? "#8E929A" : "#8E929A",
      boxShadow: state.isFocused ? "0 0 0 1px #BBA383" : "none",
      borderRadius: "6px",
      height: "48px",
      padding: "5px 0px",
      "&:focus": {
        outline: "1px solid #BBA383",
        border: "none !important",
      },
      "&:hover": {
        outline: "none",
        borderColor: "#8E929A",
        boxShadow: "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#BBA383" : "#1f1f22",
      fontSize: "12px",
      color: state.isSelected ? "white" : "inherit",
      "&:hover": {
        backgroundColor: "#BBA383",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      marginLeft: "25px",
      fontSize: "12px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#1f1f22",
      color: "white",
      fontSize: "12px",
      zIndex: 9999,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      // display: "none",
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      // display: "none",
    }),
  };

  function countOccurrences(str: any, char: any) {
    // Split the string by the character and get the length of the resulting array minus 1
    return str.split(char).length - 1;
  }

  //get latitudes and longitudes with location change

  const handleGoogleLocationChange = (value: any) => {
    if (value && value.label) {
      let address2 = "";
      let splitValue;
      let address = "";

      const formattedAddress = value.label;
      if (
        countOccurrences(formattedAddress, ",") >
        countOccurrences(formattedAddress, "-")
      ) {
        splitValue = ",";
      } else {
        splitValue = "-";
      }
      const addressParts = formattedAddress.split(splitValue);
      const country = addressParts.pop() || "";
      const city = addressParts.pop() || "";
      if (addressParts.length > 1) {
        address2 = (addressParts.pop() || "").trim();
      }
      if (addressParts.length > 0) {
        address = addressParts.join(splitValue);
      }

      geocodeByAddress(formattedAddress)
        .then((results) => {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          handleChange(address2, "addressLine2");
          handleChange(lat, "latitudes");
          handleChange(lng, "longitudes");

          fetchTimezone(lat, lng);
        })
        .catch((error) => console.error(error));
      handleChange(address, "addressLine1");
      handleChange(city, "city");
      handleChange(country, "country");
    }
  };

  //delete ticket
  const handleRemoveTicket = async (index: number) => {
    if (tickets.length > 1 && index >= 0 && index < tickets.length) {
      setTickets((prevTickets) => {
        const updatedTickets = [...prevTickets];
        updatedTickets.splice(index, 1);
        return updatedTickets;
      });

      try {
        const response = await deleteTicket(tickets[index]._id);
      } catch (error) {
        // console.error("Error deleting ticket:", error);
      }
    }
  };

  //update the event
  const handleUpdateEvent = async () => {
    setLoading(true);
    setFormSubmitted(true);
    let formattedStartDate, formattedEndDate;
    if (eventDate) {
      formattedStartDate = format(eventDate, "MM-dd-yy");
    }
    if (endDate === null) {
      formattedEndDate = formattedStartDate;
    }
    if (endDate) {
      formattedEndDate = format(endDate, "MM-dd-yy");
    }

    const eventData = {
      name: name,
      description: description,
      eventDate: formattedStartDate,
      endDate: formattedEndDate,
      eventTime: eventTime,
      topEvent: topEvent,
      availableTo: availableTo,
      timezone: timezone && timezone.label ? timezone.label : timezone,
      classification: classification,
      ...(classification === "External" &&
        externalSiteUrl !== "" && { externalSiteUrl: externalSiteUrl }),

      addressLine1: addressLine1,

      ...(addressLine2 !== "" && {
        addressLine2: addressLine2,
      }),
      city: city,
      ...(country !== "" && { country: country }),
      ...(postalCode !== "" && {
        postalCode: postalCode,
      }),

      latitudes: latitudes,

      longitudes: longitudes,

      status: status,
      category: category,
      type: type,
      guestLimit: type === "Ticketed" ? 0 : guestLimit,
      feedImage: feedImage,
      venueImage: venueImage,
      pageImage: pageImage,
      videoUrl: videoUrl,
      phoneNo: phoneNo,
      accessEvent: accessEvent,
      website: website,
      cardColor: cardColor,
    };

    //if event is ticketed or a guest list
    const ticketData = tickets.map((ticket, index) => {
      if (editEventData.tickets[index] && editEventData.tickets[index]._id) {
        return {
          eventId: editEventData.event._id,
          _id: editEventData.tickets[index]._id,
          name: ticket?.name,
          description: ticket.description,
          availableTo: ticket.availableTo,
          price: parseFloat(ticket.price) || 0,
          quantityAvailable: parseInt(ticket.quantityAvailable) || 0,
          // quantityRedeemed: parseInt(ticket.quantityRedeemed) || 0,
        };
      } else {
        return {
          eventId: editEventData.event._id,
          name: ticket?.name,
          description: ticket.description,
          availableTo: ticket.availableTo,
          price: parseFloat(ticket.price) || 0,
          quantityAvailable: parseInt(ticket.quantityAvailable) || 0,
          // quantityRedeemed: parseInt(ticket.quantityRedeemed) || 0,
        };
      }
    });
    const isEventDataValid = Object.entries(eventData).every(([key, value]) => {
      if (
        [
          "country",
          "postalCode",
          "videoUrl",
          "venueImage",
          "website",
          "phoneNo",
        ].includes(key) ||
        key === "addressLine2"
      ) {
        return true;
      } else if (key === "availableTo") {
        return Array.isArray(value) && value.length > 0;
      } else {
        return value !== null && value !== "";
      }
    });

    const isTicketDataValid = ticketData.every((ticket) =>
      Object.values(ticket).every(
        (value) => value !== null && value !== "" && value !== 0
      )
    );

    if (
      isEventDataValid && type === "Ticketed"
        ? isTicketDataValid
        : true && ticketPriceError === "" && quantityError === ""
    ) {
      try {
        let combinedData = {};
        if (type === "Ticketed") {
          combinedData = {
            event: eventData,
            tickets: ticketData,
          };
        } else {
          combinedData = {
            event: eventData,
          };
        }
        //update event request
        const response = await updateEvent(
          editEventData.event._id,
          combinedData
        );
        setLoading(false);
        handleClose();
      } catch (error: any) {
        setLoading(false);
        if (error.message === "Network Error") {
          showToast(
            "It seems you're offline. Please check your connection and try again.",
            "error"
          );
        }
        if (error.response.data.code === 10038) {
          showToast(error.response.data.message, "error");
        } else {
          showToast(`Error Updating event. Please try again.`, "error");
        }
      }
    } else {
      setLoading(false);
      showToast("Cannot update! Please complete all required fields.", "error");
    }
  };

  const handleTicketChange = (
    index: number,
    field: keyof TicketFormData["tickets"][0],
    value: string
  ) => {
    if (field === "name" && value.length > 100) {
      setTicketNameError("Ticket name cannot exceed 100 characters");
    }
    if (
      field === "quantityAvailable" &&
      (value === null || value === undefined || value === "0")
    ) {
      setQuantityError("Quantity available required");
    }
    if (field === "quantityAvailable") {
      const newValue = parseInt(value, 10); // Convert value to integer

      // Check if quantityAvailable is greater than or equal to quantityRedeemed
      if (newValue < tickets[index].quantityRedeemed) {
        setQuantityError(
          "Quantity available cannot be less than quantity redeemed"
        );
        setQuantityErrorIndex(index);
        setTickets((prevTickets) => {
          const updatedTickets = [...prevTickets];
          updatedTickets[index] = {
            ...updatedTickets[index],
            [field]: value,
          };
          return updatedTickets;
        });
      } else {
        setQuantityError("");
        setQuantityErrorIndex(-1);
        setTickets((prevTickets) => {
          const updatedTickets = [...prevTickets];
          updatedTickets[index] = {
            ...updatedTickets[index],
            [field]: value,
          };
          return updatedTickets;
        });
      }
    } else if (field === "price") {
      let formattedPrice = value.replace(/[^\d.]/g, "");
      const periodsCount = formattedPrice.split(".").length - 1;
      if (periodsCount > 1) {
        return;
      }
      const decimalIndex = formattedPrice.indexOf(".");
      if (decimalIndex !== -1) {
        const decimalPart = formattedPrice.substring(decimalIndex + 1);
        formattedPrice =
          formattedPrice.substring(0, decimalIndex + 1) +
          decimalPart.slice(0, 2);
      }
      if (formattedPrice.length > 7) {
        formattedPrice = formattedPrice.slice(0, 7);
        setTicketPriceError("Ticket price cannot exceed 7 characters");
      } else {
        const price = parseFloat(formattedPrice);
        setTicketPriceError("");
      }
      setTickets((prevTickets) => {
        const updatedTickets = [...prevTickets];
        updatedTickets[index] = {
          ...updatedTickets[index],
          [field]: formattedPrice,
        };
        return updatedTickets;
      });
    } else {
      // For other fields, update the state as usual
      setQuantityError("");
      setTickets((prevTickets) => {
        const updatedTickets = [...prevTickets];
        updatedTickets[index] = {
          ...updatedTickets[index],
          [field]: value,
        };
        return updatedTickets;
      });
    }
  };

  //delete image file
  const handleFileDelete = (imageType: any) => {
    if (imageType === "page") {
      handleChange(null as any, "pageImage");
    } else if (imageType === "feed") {
      handleChange(null as any, "feedImage");
    } else if (imageType === "venue") {
      handleChange(null as any, "venueImage");
    }
  };

  const handleImageChange = (imageType: any, image: any) => {
    if (imageType === "page") {
      handleChange(image, "pageImage");

      setEditButton(true);
    } else if (imageType === "feed") {
      handleChange(image, "feedImage");

      setEditButton(true);
    } else if (imageType === "venue") {
      handleChange(image, "venueImage");

      setEditButton(true);
    }
  };
  return (
    <div
      style={{
        width: "100%",
        marginTop: "5.73vh",
        padding: "0 4.6vw",
        overflowY: "scroll",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="modelHeading-oi9">
        {!viewEvent ? "Edit Event" : "View Event"}
      </div>

      <div className="mt-4 row">
        <div className="col-6">
          <label className="form-label">Event Name *</label>
          <input
            value={name}
            onChange={(e) => handleChange(e.target.value, e.target.name)}
            name="name"
            className="w-100 input-value-field mt-2"
            disabled={viewEvent}
            placeholder="Name of the event"
          />
          {formSubmitted && !name && (
            <div className="my-1 error-text-asd errorField">
              *Event name required
            </div>
          )}
        </div>
        <div className="col-6">
          <label className="form-label">Status *</label>
          <div className="status-wrap mt-2">
            <SelectWrapper
              options={statusDropdown}
              value={status}
              setValue={(value: string) => handleChange(value, "status")}
              disabled={viewEvent}
              placeholder="Please Select Status"
            />
          </div>
          {formSubmitted && !status && (
            <div className="my-1 error-text-asd errorField">
              *Status required
            </div>
          )}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <label className="form-label">Event Description *</label>
          <textarea
            value={description}
            onChange={(e) => handleChange(e.target.value, e.target.name)}
            name="description"
            className="text-area-2 p-2"
            placeholder="Description of the event"
            maxLength={999}
            disabled={viewEvent}
          />
          {formSubmitted && !description && (
            <div className="my-1 error-text-asd errorField">
              *Event description required
            </div>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex flex-column col-6 categories-wrapper">
          <label className="form-label">Available to *</label>
          <MultiSelectWrapper
            value={availableTo}
            options={event_available}
            placeholder="Select event access for members"
            setValue={(value: any) => handleChange(value, "availableTo")}
            disabled={viewEvent}
          />
          {formSubmitted && availableTo.length === 0 && (
            <div className="my-1 error-text-asd errorField">
              *Available to required
            </div>
          )}
        </div>
        <div className="d-flex flex-column col-6 event-wrapper">
          <label className="form-label">Type of Event *</label>
          <SelectWrapper
            options={types}
            value={type}
            disabled={viewEvent}
            setValue={(value: any) => handleChange(value, "type")}
            placeholder="Choose event type"
          />
          {formSubmitted && !type && (
            <div className="my-1 error-text-asd errorField">
              *Type of event required
            </div>
          )}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 categories-wrapper">
          <label className="form-label">Categories *</label>

          <MultiSelectWrapper
            value={category}
            options={categories.map((category) => category.value)}
            placeholder="Select event access for members"
            setValue={(value: any) => handleChange(value, "category")}
            disabled={viewEvent}
          />
          {formSubmitted && !category && (
            <div className="my-1 error-text-asd errorField">
              *Category required
            </div>
          )}
        </div>
      </div>

      <div className="row mt-4">
        <div className="d-flex flex-column col-6 event-wrapper">
          <label className="form-label">Classification of Event *</label>
          <SelectWrapper
            options={classification_options}
            value={classification}
            setValue={(value: any) => handleChange(value, "classification")}
            disabled={viewEvent}
            placeholder="Choose event classification"
          />
          {formSubmitted && !classification && (
            <div className="my-1 error-text-asd errorField">
              *Classification of event required
            </div>
          )}
        </div>
        <div className="d-flex flex-column col-6 categories-wrapper">
          <label className="form-label">Guest Allowed *</label>
          <input
            value={guestLimit}
            onChange={(e) => {
              if (/^[0-9]?$/.test(e.target.value)) {
                handleChange(e.target.value, e.target.name);
              }
            }}
            name="guestLimit"
            className="input-value-field"
            disabled={type === "Ticketed" || type === "" || viewEvent}
            placeholder="Guest Count"
          />
          {formSubmitted && guestLimit === "" && type === "Guest List" && (
            <div className="my-1 error-text-asd errorField">
              *Guest count required
            </div>
          )}
        </div>
      </div>

      {classification === "External" && (
        <div className="row mt-4">
          <div className="d-flex flex-column col-12 categories-wrapper">
            <label className="form-label">External Site URL *</label>
            <input
              value={classification !== "External" ? "" : externalSiteUrl}
              disabled={viewEvent}
              onChange={(e) => {
                const { name, value } = e.target;
                handleChange(value, name);

                const isValidUrl =
                  value.startsWith("http://") || value.startsWith("https://");
                if (!isValidUrl) {
                  setExternalUrlError("Please enter a valid URL");
                } else {
                  setExternalUrlError("");
                }
              }}
              name="externalSiteUrl"
              className="input-value-field"
              placeholder="External Site Url"
            />
            {formSubmitted &&
              classification === "External" &&
              !externalSiteUrl && (
                <div className="my-1 error-text-asd errorField">
                  *External site url required
                </div>
              )}
            {externalUrlError && (
              <div className="my-1 error-text-asd errorField">
                {externalUrlError}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="row mt-4">
        <div className="col-6 d-flex flex-column justify-content-start">
          <label className="form-label">Event Page Image *</label>
          <div className="img-desc-cni2 mb-2">
            This will be shown as the primary image on the event page in the
            app.
            {/* Size 320px x 480px. */}
          </div>

          <div>
            {pageImage && typeof pageImage === "string" ? (
              <div>
                <img
                  className="selected-page-image"
                  src={pageImage}
                  alt="Selected Page Image"
                />
              </div>
            ) : pageImage && pageImage.path ? (
              <div>
                <img
                  className="selected-page-image"
                  src={pageImage.path}
                  alt="Selected Page Image"
                />
              </div>
            ) : (
              <div></div>
            )}
            {!viewEvent && (
              <EventImage
                required={true}
                text="Event Page Image"
                formSubmitted={formSubmitted}
                imageSize={{ width: 320, height: 480 }}
                imageType="page"
                onImageSelected={onImageSelected}
                fileDelete={handleFileDelete}
                fileName={
                  pageImage
                    ? typeof pageImage === "string"
                      ? processLogoUrl(pageImage)
                      : pageImage.name
                    : ""
                }
                value={pageImage}
                setValue={handleImageChange}
              />
            )}
          </div>
        </div>

        <div className="col-6 d-flex flex-column justify-content-start ">
          <label className="form-label">Event Feed Image *</label>
          <div className="img-desc-cni2 mb-2">
            This will be shown as the main image on your offer feed in the app.
            {/* Size 300px x 360px. */}
          </div>
          <div>
            {feedImage && typeof feedImage === "string" ? (
              <div>
                <img
                  className="selected-page-image"
                  src={feedImage}
                  alt="Selected Feed Image"
                />
              </div>
            ) : feedImage && feedImage.path ? (
              <div>
                <img
                  className="selected-page-image"
                  src={feedImage.path}
                  alt="Selected Feed Image"
                />
              </div>
            ) : (
              <div></div>
            )}
            {!viewEvent && (
              <EventImage
                required={true}
                text="Event Feed Image"
                formSubmitted={formSubmitted}
                imageSize={{ width: 300, height: 360 }}
                imageType="feed"
                onImageSelected={onImageSelected}
                fileDelete={handleFileDelete}
                fileName={
                  feedImage
                    ? typeof feedImage === "string"
                      ? processLogoUrl(feedImage)
                      : feedImage.name
                    : ""
                }
                value={feedImage}
                setValue={handleImageChange}
              />
            )}
          </div>
        </div>
      </div>
      {/* /////////// event  video /////////// */}
      {!videoUrl && viewEvent ? null : (
        <div className="row mt-4">
          <div className="col-6 d-flex flex-column justify-content-start">
            <label className="form-label">Event Video</label>
            <div className="img-desc-cni2 mb-2">
              This will be shown as the primary video on the event page in the
              app.
              {/* Size 320px x 480px. */}
            </div>

            <div>
              {videoUrl && typeof videoUrl === "string" ? (
                <div>
                  <video
                    className="selected-page-image"
                    src={videoUrl}
                    controls
                  />
                </div>
              ) : videoUrl && videoUrl.path ? (
                <div>
                  <video
                    className="selected-page-image"
                    src={videoUrl.path}
                    controls
                  />
                </div>
              ) : (
                <div></div>
              )}
              {!viewEvent && (
                <Video
                  required={true}
                  text="Event Video"
                  formSubmitted={formSubmitted}
                  imageSize={{ width: 300, height: 360 }}
                  imageType="event video"
                  onImageSelected={onImageSelected}
                  value={videoUrl}
                  setValue={(videoUrl) => handleChange(videoUrl, "videoUrl")}
                  type="video"
                />
              )}
            </div>
          </div>

          <div className="col-6 d-flex flex-column justify-content-start"></div>
        </div>
      )}
      <div className="row mt-4">
        <div className="col-6 d-flex flex-column justify-content-start">
          <label className="form-label">Card Color *</label>

          <div className="color-box">
            <input
              style={{
                width: "30px",
                height: "30px",
                // backgroundColor: cardColor,
                padding: "0px",
                cursor: "pointer",
              }}
              disabled={viewEvent}
              value={cardColor}
              onChange={(e) => {
                handleChange(e.target.value, "cardColor");
              }}
              type="color"
            />

            <input
              type="text"
              value={cardColor}
              className="input_color"
              disabled={viewEvent}
              onChange={(e) => {
                colorCodeRegex;

                if (!colorCodeRegex.test(e.target.value)) {
                  setcolorError(true);
                } else {
                  setcolorError(false);
                }

                handleChange(e.target.value, "cardColor");
              }}
            />
          </div>
          {colorError && (
            <span className="error-color d-block">
              Please enter valid color
            </span>
          )}
        </div>

        <div className="col-6 d-flex flex-column justify-content-start"></div>
      </div>

      <div className="editModalSubHeading-kid mt-5">Event Details</div>
      <div className="line-spreader-sd mt-4"></div>

      <div className="row mt-4">
        <div className="col-6">
          <label className="form-label">Event Date(s) *</label>
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            icon={calenderIcon()}
            placeholderText="MM.DD.YY - MM.DD.YY"
            dateFormat="MM-dd-yy"
            minDate={new Date()}
            selectsRange={true}
            disabled={viewEvent}
            startDate={eventDate}
            endDate={endDate}
            onChange={(update: any) => {
              if (update) {
                handleChange(update[0], "eventDate");
                handleChange(update[1], "endDate");
              } else {
                setDateError("*Event date required");
              }
            }}
          />
          {dateError && (
            <span className="error-color d-block">{dateError}</span>
          )}
        </div>

        <div className="event-time-wrapper col-6">
          <label className="form-label">Event Time *</label>

          <TimeRangePicker
            handleChange={handleChange}
            eventTime={eventTime}
            disable={viewEvent}
          />
          <span className="time-icon">{timeIcon()}</span>
          {timeError && <span className="error-color">{timeError}</span>}

          {validationError && (
            <span className="error-color">{validationError}</span>
          )}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 position-relative">
          <label className="form-label">Event Address</label>
          {!viewEvent && (
            <div>
              <span className="search-icon-illusion">{searchIcon()}</span>

              <GooglePlacesAutocomplete
                apiKey={API_KEY}
                selectProps={{
                  placeholder: "Search Address",
                  defaultValue: addressLine1,
                  onChange: (values) => {
                    handleGoogleLocationChange(values);
                  },
                  value: addressLine2,
                  styles: customStyles,
                  isDisabled: viewEvent,
                  isClearable: true,
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className={`row mb-4 ${!viewEvent ? "mt-4" : ""}`}>
        <div className="address-line-1 col-6">
          <input
            type="text"
            value={addressLine1}
            onChange={(e) => handleChange(e.target.value, e.target.name)}
            name="addressLine1"
            className="input-value-field w-100"
            placeholder="Address line one"
            disabled={viewEvent}
          />
          {formSubmitted && !addressLine1 && (
            <span className="error-color d-block">*Address is required</span>
          )}
        </div>

        <div className="address-line-2 col-6">
          <input
            type="text"
            value={addressLine2}
            onChange={(e) => handleChange(e.target.value, e.target.name)}
            name="addressLine2"
            className="input-value-field w-100"
            placeholder="Address line two"
            disabled={viewEvent}
          />
        </div>
      </div>
      <div className="row mt-3 mb-4">
        <div className="city col-6">
          <input
            type="text"
            value={city}
            onChange={(e) => handleChange(e.target.value, e.target.name)}
            name="city"
            className="input-value-field w-100"
            placeholder="City"
            disabled={viewEvent}
          />
          {formSubmitted && !city && (
            <span className="error-color d-block">*City is required</span>
          )}
        </div>

        <div className="country col-6">
          <SelectWrapper
            options={countries}
            value={country}
            setValue={(country: any) => handleChange(country, "country")}
            placeholder="Country"
            disabled={viewEvent}
          />
        </div>
      </div>

      <div className="event-form-wrapper row mt-3 mb-2">
        <div className="post-code col-6">
          <input
            type="text"
            value={postalCode}
            onChange={(e) => handleChange(e.target.value, e.target.name)}
            name="postalCode"
            className="input-value-field w-100"
            placeholder="Post or Zip Code"
            disabled={viewEvent}
          />

          <div className="my-1 error-text-asd errorField">{postError}</div>
        </div>
        <div className="longitude col-6">
          <input
            className="input-value-field w-100"
            type="text"
            // onChange={(e)=> {
            //   handleChange(e.target.value , )
            // }}
            id="coordinates"
            readOnly={true}
            placeholder="Longitude, Latitude"
            disabled={viewEvent}
            value={`${longitudes}, ${latitudes}`}
          />
          {formSubmitted && (!longitudes || !longitudes) && (
            <span className="error-color d-block">
              *Latitude and Longitude is required
            </span>
          )}
        </div>
      </div>

      <div className="event-form-wrapper row mt-4">
        <div className="col-12 timezone-wrapper">
          <label className="form-label">Event Timezone </label>
          <TimezoneSelect
            value={timezone ? timezone : ""}
            onChange={(value: any) => handleChange(value, "timezone")}
            styles={customStyles2 as any}
            placeholder="Event Timezone"
            isClearable={true}
            labelStyle={"offsetHidden"}
            isDisabled={viewEvent}
            timezones={customTimezones}
            isMulti={false}
          />
          {formSubmitted && !timezone && (
            <span className="error-color">*Event timezone required</span>
          )}
        </div>
      </div>
      <div className="event-form-wrapper row mt-4">
        <div className="post-code col-6">
          <label className="form-label">Venue Website </label>

          <input
            type="text"
            value={website}
            name="website"
            className="input-value-field w-100"
            placeholder="https://www.example.com"
            disabled={viewEvent}
            onChange={(e) => {
              const { name, value } = e.target;
              handleChange(value, name);
              if (value) {
                const isValidUrl =
                  value.startsWith("http://") || value.startsWith("https://");
                if (!isValidUrl) {
                  setUrlError("Please enter a valid URL");
                } else {
                  setUrlError("");
                }
              } else {
                setUrlError("");
              }
            }}
          />

          <div className="my-1 error-text-asd errorField">{urlError}</div>
        </div>
        <div className="b-longitude longitude col-6">
          <label className="form-label">Venue Phone </label>

          <input
            type="text"
            value={phoneNo}
            name="phoneNo"
            className="input-value-field w-100"
            placeholder="Phone number"
            disabled={viewEvent}
            onChange={(e) => {
              const { name, value } = e.target;
              handleChange(value, name);
              if (value) {
                if (!phoneRegex.test(value)) {
                  setphoneError("Invalid phone number");
                } else {
                  setphoneError("");
                }
              } else {
                setphoneError("");
              }
            }}
          />
          <div className="my-1 error-text-asd errorField">{phoneError}</div>
        </div>
      </div>
      {!venueImage && viewEvent ? null : (
        <div className="row mt-4">
          <div className="col-6 d-flex flex-column justify-content-start">
            <label className="form-label">Venue Image</label>
            {/* <div className="img-desc-cni2 mb-2">
            This will be shown as the primary image on the event page in the
            app.
       
          </div> */}

            <div>
              {venueImage && typeof venueImage === "string" ? (
                <div>
                  <img
                    className="selected-page-image"
                    src={venueImage}
                    alt="Venue Image"
                  />
                </div>
              ) : venueImage && venueImage.path ? (
                <div>
                  <img
                    className="selected-page-image"
                    src={venueImage.path}
                    alt="Venue Image"
                  />
                </div>
              ) : (
                <div></div>
              )}
              {!viewEvent && (
                <EventImage
                  required={false}
                  text="Venue Image"
                  formSubmitted={formSubmitted}
                  imageSize={{ width: 320, height: 480 }}
                  imageType="venue"
                  onImageSelected={onImageSelected}
                  fileDelete={handleFileDelete}
                  fileName={
                    venueImage
                      ? typeof venueImage === "string"
                        ? processLogoUrl(venueImage)
                        : venueImage.name
                      : ""
                  }
                  value={venueImage}
                  setValue={handleImageChange}
                />
              )}
            </div>
          </div>

          <div className="col-6 d-flex flex-column justify-content-start "></div>
        </div>
      )}

      {type === "Ticketed" && (
        <div>
          {/* <div className="editModalSubHeading-kid mt-4">
            Tickets Details
          </div>
          <div className="line-spreader-sd mt-4"></div> */}

          <div className="row">
            {tickets.map((ticket, index) => (
              <div key={index + 1} className="tickets">
                <div className="ticket-header">
                  <div className="d-flex justify-content-between">
                    <h2>Ticket {index + 1}</h2>
                    {index > 0 && (
                      <div
                        className="text-white cursor-pointer"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveTicket(index)}
                      >
                        X
                      </div>
                    )}
                  </div>
                  <div className="breaker">{Line()}</div>
                </div>
                <div className="ticket-form">
                  <form>
                    <div className="ticket-wrapper row mb-4 mt-4">
                      <div className="ticket-name col-6">
                        <label className="form-label">Ticket Name *</label>
                        <input
                          value={ticket?.name}
                          disabled={viewEvent}
                          onChange={(e) =>
                            handleTicketChange(index, "name", e.target.value)
                          }
                          name="event name"
                          className="input-value-field w-100"
                          placeholder="Please enter the ticket name"
                        />
                        {formSubmitted && !ticket.name && (
                          <div className="my-1 error-text-asd errorField">
                            *Ticket name required
                          </div>
                        )}
                      </div>
                      <div className="ticket-price col-6">
                        <label className="form-label">Ticket Price *</label>
                        <input
                          disabled={viewEvent}
                          value={ticket.price === "" ? "" : `$${ticket.price}`}
                          onChange={(e) =>
                            handleTicketChange(
                              index,
                              "price",
                              e.target.value.startsWith("$")
                                ? e.target.value.replace("$", "")
                                : e.target.value
                            )
                          }
                          name="Ticket Price"
                          className="input-value-field w-100"
                          placeholder="$00.00"
                        />
                        {formSubmitted && !ticket.price && (
                          <div className="my-1 error-text-asd errorField">
                            *Ticket price required
                          </div>
                        )}
                        {ticketPriceError && (
                          <div className="my-1 error-text-asd errorField">
                            {ticketPriceError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="ticket-desc row mb-8">
                      <div className="col-12">
                        <label className="form-label">
                          Ticket Description *
                        </label>
                        <textarea
                          value={ticket?.description}
                          disabled={viewEvent}
                          onChange={(e) =>
                            handleTicketChange(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                          name="event name"
                          className="text-area-2 p-2"
                          placeholder="Description of this specific ticket"
                        />
                        {formSubmitted && !ticket.description && (
                          <div className="my-1 error-text-asd errorField">
                            *Ticket description required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="ticket-wrapper row mb-8">
                      <div className="guest-types col-8">
                        <label className="form-label">
                          Available to * (multiple selection)
                        </label>
                        <MultiSelectWrapper
                          value={ticket.availableTo}
                          options={ticketAvailableOptions}
                          disabled={viewEvent}
                          placeholder="Choose which members will have access to this ticket "
                          setValue={(value: any) =>
                            handleTicketChange(index, "availableTo", value)
                          }
                        />
                        {formSubmitted &&
                          Array.isArray(ticket.availableTo) &&
                          ticket.availableTo.length === 0 && (
                            <div className="my-1 error-text-asd errorField">
                              *Available to required
                            </div>
                          )}
                      </div>
                      <div className="ticket-qty col-4">
                        <label className="form-label">
                          Quantity Available *
                        </label>
                        <input
                          value={ticket?.quantityAvailable}
                          disabled={viewEvent}
                          onChange={(e) =>
                            handleTicketChange(
                              index,
                              "quantityAvailable",
                              e.target.value
                            )
                          }
                          name="Quantity Available"
                          className="input-value-field w-100"
                          placeholder="0"
                        />
                        {formSubmitted && quantityErrorIndex === index && (
                          <div className="my-1 error-text-asd errorField">
                            {quantityError}
                          </div>
                        )}
                        {formSubmitted && !ticket.quantityAvailable && (
                          <div className="my-1 error-text-asd errorField">
                            *Quantity available required
                          </div>
                        )}
                        {formSubmitted && ticket.quantityAvailable === "0" && (
                          <div className="my-1 error-text-asd errorField">
                            *Quantity available cannot be 0
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            {!viewEvent && (
              <div className="add-ticket-wrapper">
                <Button
                  title={"Add another ticket"}
                  type={"button"}
                  backgroundColor="#1F1F22"
                  color="white"
                  border="1px solid #8E929A"
                  borderRadius="5px"
                  padding="10px 35px"
                  fontSize="14px"
                  fontWeight="600"
                  onButtonClick={handleAddTicket}
                />
                <div className="add-icon">
                  <span>{AddIcon()}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div className=" event-form-wrapper row mt-4 mb-4">
        <div className="col-lg-6 space">
          <div className="gap-4 d-flex justify-content-start toggle-button-wrap-3">
            <div className="feature-campaign-de3">Featured Event</div>
            <div className="toggle-button-wrap-2 featured-edit-campaign">
              <Toggle
                tabIndex={0}
                id="cheese-status"
                defaultChecked={topEvent}
                disabled={viewEvent}
                icons={false}
                onChange={(e) => handleChange(e.target.checked, "topEvent")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 space">
          <div className="gap-4 d-flex justify-content-start toggle-button-wrap-3">
            <div className="feature-campaign-de3">Access Event</div>
            <div className="toggle-button-wrap-2 featured-edit-campaign">
              <Toggle
                // tabIndex={0}
                // id="cheese-status"
                defaultChecked={accessEvent}
                disabled={viewEvent}
                icons={false}
                onChange={(e) => handleChange(e.target.checked, "accessEvent")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4 mb-4">
        <div className="col-12 space">
          <div className="ticket-footer-wrapper">
            <div className="ticket-back">
              <Button
                title={viewEvent ? "EXIT" : "CANCEL"}
                type={"button"}
                backgroundColor="#1A1A1C"
                color="white"
                border="1px solid white"
                borderRadius="6px"
                padding="10.12px 47.3px"
                fontSize="12px"
                fontWeight="600"
                onButtonClick={handleClose}
              />
            </div>
            {!viewEvent && (
              <div className="ticket-finish">
                <button
                  type="submit"
                  onClick={handleUpdateEvent}
                  className="benefit-submit-button"
                  disabled={editButton || loading}
                >
                  {loading === true ? <Loader /> : "SUBMIT"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCampaignModal;
