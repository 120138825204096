import { IMenuItem } from "@/@Interfaces/IMenuItem";
import { setApplicantStatus } from "@/redux/slices/sidebarSlice";
import line from "@images/line.png";
import logo from "@images/logo.svg";
import {
  BellIcon,
  BenefitsIcon,
  BusinessIcon,
  contactUsIcon,
  DashboardIcon,
  MembersIcon,
  UserIcon,
} from "@svgs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "../ExitModal";
import "./style.css";

const Sidebar: React.FC<IMenuItem> = () => {
  const { tab } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [exitTab, setExitTab] = useState("");
  const applicantStatus = useSelector(
    (state: any) => state.applicant_status.applicantStatus
  );

  const handleNavigation = (path: string) => {
    if (
      location.pathname === "/create-business" ||
      location.pathname === "/create-benefit" ||
      location.pathname === "/create-event" ||
      location.pathname === "/profile-settings" ||
      location.pathname === "/change-password"
    ) {
      setExitTab(path);
      setShowModal(true);
    } else {
      navigate(path);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleExit = () => {
    if (exitTab) {
      navigate(exitTab);
    } else {
      console.log("No exit tab specified");
    }
  };

  useEffect(() => {
    if (tab == "members") {
      dispatch(setApplicantStatus("Approved"));
    } else if (tab == "applications" && applicantStatus == "Approved") {
      dispatch(setApplicantStatus("Pending"));
    }
  }, [tab]);

  //Active sub tabs of Applications Tab
  const handleApplicationItemClick = (application: string) => {
    switch (application) {
      case "pending":
        dispatch(setApplicantStatus("Pending"));
        break;
      case "waiting":
        dispatch(setApplicantStatus("Waiting List"));
        break;
      case "not-approved":
        dispatch(setApplicantStatus("Not Approved"));
        break;
      case "members":
        dispatch(setApplicantStatus("Approved"));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Modal
        showModal={showModal}
        handleClose={handleCloseModal}
        buttonTitle="EXIT"
        handleExit={handleExit}
        title="Are you sure you want to exit?"
        message="Are you sure you want to exit creating a new business? The information will not be saved."
      />{" "}
      <div style={{ backgroundColor: "#1A1A1C", width: "100%" }}>
        <section className="sidebar-upper-section">
          <a href="/home/dashboard">
            <img src={logo} className="logo-img" width={"100%"} alt="Logo" />
          </a>
        </section>
        <img className="divider-image" src={line} width={"100%"} alt="Line" />
        <section className="sidebar-section">
          <div className="list-wrapper">
            <ul className="list-area">
              <li
                onClick={() => handleNavigation("/home/dashboard")}
                className={tab === "dashboard" ? "active-tab" : "s-t-h"}
              >
                <div className="tab-items">
                  <a>{DashboardIcon()}</a>
                  <span className="tab-text">Dashboard</span>
                </div>
              </li>
              <li
                onClick={() => handleNavigation("/home/applications")}
                className={tab === "applications" ? "active-tab" : "s-t-h"}
              >
                <div className="tab-items">
                  <a>{UserIcon()}</a>
                  <span className="tab-text">Applications</span>
                </div>

                {tab === "applications" && (
                  <ul className="applications-options">
                    <li
                      onClick={() => handleApplicationItemClick("pending")}
                      className={
                        applicantStatus === "Pending"
                          ? "active-application"
                          : "s-t-h-subtab"
                      }
                    >
                      <span className="tab-text">Pending</span>
                    </li>
                    <li
                      onClick={() => handleApplicationItemClick("waiting")}
                      className={
                        applicantStatus === "Waiting List"
                          ? "active-application"
                          : "s-t-h-subtab"
                      }
                    >
                      <span className="tab-text">Waiting List</span>
                    </li>
                    <li
                      onClick={() => handleApplicationItemClick("not-approved")}
                      className={
                        applicantStatus === "Not Approved"
                          ? "active-application"
                          : "s-t-h-subtab"
                      }
                    >
                      <span className="tab-text">Not Approved</span>
                    </li>
                  </ul>
                )}
              </li>
              <li
                onClick={() => handleNavigation("/home/members")}
                className={tab === "members" ? "active-tab" : "s-t-h"}
              >
                <div className="tab-items">
                  <a>{MembersIcon()}</a>
                  <span className="tab-text">Members</span>
                </div>
              </li>
              <li className="no-pointer-cursor">
                <span className="tab-text">
                  {" "}
                  <strong>CONTENT MANAGEMENT</strong>
                </span>
              </li>
              <li
                onClick={() => handleNavigation("/home/businesses")}
                className={tab === "businesses" ? "active-tab" : "s-t-h"}
              >
                <div className="tab-items">
                  <a>{BusinessIcon()}</a>
                  <span className="tab-text">Businesses</span>
                </div>
              </li>
              <li
                onClick={() => handleNavigation("/home/events")}
                className={tab === "events" ? "active-tab" : "s-t-h"}
              >
                <div className="tab-items">
                  <a>{BellIcon()}</a>
                  <span className="tab-text">Events</span>
                </div>
              </li>
              <li
                onClick={() => handleNavigation("/home/benefits")}
                className={tab === "benefits" ? "active-tab" : "s-t-h"}
              >
                <div className="tab-items">
                  <a style={{ padding: "8px" }}>{BenefitsIcon()}</a>
                  <span className="tab-text">Benefits</span>
                </div>
              </li>
              <li
                onClick={() => handleNavigation("/home/attendees")}
                className={tab === "attendees" ? "active-tab" : "s-t-h"}
              >
                <div className="tab-items">
                  <a>{UserIcon()}</a>
                  <span className="tab-text">Attendees</span>
                </div>
              </li>
              <li
                onClick={() => handleNavigation("/home/contactUs")}
                className={tab === "contactUs" ? "active-tab" : "s-t-h"}
              >
                <div className="tab-items">
                  <a>{contactUsIcon()}</a>
                  <span className="tab-text">Contact Us</span>
                </div>
              </li>
              <li
                onClick={() => handleNavigation("/home/enquiry")}
                className={tab === "enquiry" ? "active-tab" : "s-t-h"}
              >
                <div className="tab-items">
                  <a>{UserIcon()}</a>
                  <span className="tab-text">Enquiry</span>
                </div>
              </li>
              <li
                onClick={() => handleNavigation("/home/intake")}
                className={tab === "intake" ? "active-tab" : "s-t-h"}
              >
                <div className="tab-items">
                  <a>{UserIcon()}</a>
                  <span className="tab-text">Intake</span>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <div className="sidebar-footer">
          <p>@ 2023 PENDULUM</p>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
