import URLS from '@/constants/api';
import { deleteAPI} from "./api";

export const deleteTicket = async (
    ticketId: string | undefined,
  ) => {
    const endpoint = `${URLS.DELETE_TICKET}?ticketId=${ticketId}`;
    const response = await deleteAPI(endpoint, ticketId);
    return response;
  };
  
