import React from "react";
import "./style.css";

const Footer: React.FC = () => {
  return (
    <div className="row">
      <div className="co-lg-12 col-xl-12 col-md-12">
        <div className="footer">
          <div className="footer-wrapper p-3">
            <a>@ 2023 PENDULUM</a>
          </div>
          {/* <div className="footer-content">
            <ul>
              <a
                href="https://pendulummembers.com/terms"
                className="p-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li>Terms & Conditions</li>
              </a>
              <a
                href="https://pendulummembers.com/privacy"
                className="p-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <li>Privacy Policy</li>
              </a>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
