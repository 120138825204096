import React, { useEffect, useState } from "react";
import Button from "../Button";
import { useNavigate } from "react-router";
import { NavigationPaths } from "@/constants/paths";
import { CampaignIcon, ExitIcon } from "@/constants/utils/svgs";
import SelectWrapper from "../selectWrapper";
import Image from "../Image";
import Toggle from "react-toggle";
import Modal from "../ExitModal";
import { status as statuses } from "@/constants/status";
import { categories } from "@/constants/categories";
import { types } from "@constants/eventTypes";
import { StepOneProps } from "@/@Interfaces/EventStepOneProps";
import MultiSelectWrapper from "../MultiSelect";
import { event_available } from "@/constants/eventAvailable";
import { classification_options } from "@/constants/classification";
import Video from "../Video";

const StepOne: React.FC<StepOneProps> = ({
  setSteps,
  setstepOneFormData,
  stepOneFormData,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const {
    category,
    name,
    description,
    status,
    type,
    availableTo,
    classification,
    guestLimit,
    externalSiteUrl,
    topEvent,
    feedImage,
    pageImage,
    accessEvent,
    videoUrl,
  } = stepOneFormData;
  const [urlError, setUrlError] = useState("");

  const handleChange = (value: number | string | boolean, name: string) => {
    setstepOneFormData((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (type === "Ticketed") {
      handleChange(0, "guestLimit");
    }
  }, [type]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExit = () => {
    navigate(NavigationPaths.CAMPAIGNS);
  };

  const onImageSelected = (imageType: string, file: File) => {
    console.log(imageType);
  };

  //if none of the fields are empty atake the user to step 2
  const handleSubmitForm = (e: any) => {
    console.log("dsadsadsa");
    setFormSubmitted(true);

    e.preventDefault();

    if (
      name &&
      status &&
      description &&
      availableTo?.length !== 0 &&
      category.length > 0 &&
      classification &&
      type &&
      feedImage &&
      pageImage &&
      (type !== "Guest List" || guestLimit !== "") &&
      ((classification === "Hosted" && externalSiteUrl === "") ||
        (classification === "External" && externalSiteUrl !== ""))
    ) {
      setSteps(2);
    }
  };

  return (
    <section className="create-campaign-section">
      <div className="row">
        <div className="col-lg-6 col-md-9 adjust-height-fhbsh">
          <div className="form-section">
            <div className="form-header">
              <h2>Create an Event</h2>
              <p>
                {stepOneFormData.type === "Ticketed"
                  ? "Step 1 of 3"
                  : "Step 1 of 2"}
              </p>
            </div>
            <form onSubmit={handleSubmitForm}>
              <div className="row mb-4">
                <div className="d-flex flex-column col-6 ">
                  <label className="form-label">Event Name *</label>
                  <input
                    value={name}
                    onChange={(e: any) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    name="name"
                    className="input-value-field"
                    placeholder="Name of the event"
                  />
                  {formSubmitted && !name && (
                    <div className="my-1 error-text-asd errorField">
                      *Event name required
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column col-6 status-wrapper ">
                  <label className="form-label">Status *</label>
                  <SelectWrapper
                    options={statuses}
                    value={status}
                    setValue={(value: any) => handleChange(value, "status")}
                    placeholder="Please Select Status"
                  />
                  {formSubmitted && !status && (
                    <div className="my-1 error-text-asd errorField">
                      *Status required
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12">
                  <label className="form-label">Event Description *</label>
                  <textarea
                    value={description}
                    name="description"
                    onChange={(e: any) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    id="campaignDesign"
                    className="text-area-2"
                    placeholder="Description of the event"
                    maxLength={999}
                  />
                  {formSubmitted && !description && (
                    <div className="my-1 error-text-asd errorField">
                      *Event description required
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <div className="d-flex flex-column col-6 categories-wrapper">
                  <label className="form-label">Available to *</label>
                  <MultiSelectWrapper
                    options={event_available}
                    placeholder="Select event access for members"
                    value={availableTo}
                    setValue={(value: any) =>
                      handleChange(value, "availableTo")
                    }
                  />
                  {formSubmitted && availableTo?.length === 0 && (
                    <div className="my-1 error-text-asd errorField">
                      *Available to required
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column col-6 event-wrapper">
                  <label className="form-label">Type of Event *</label>
                  <SelectWrapper
                    options={types}
                    value={type}
                    setValue={(value: any) => handleChange(value, "type")}
                    placeholder="Choose event type"
                  />
                  {formSubmitted && !type && (
                    <div className="my-1 error-text-asd errorField">
                      *Type of event required
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <div className="d-flex flex-column col-12 categories-wrapper">
                  <label className="form-label">Categories *</label>
                  {/* <SelectWrapper
                    options={categories}
                    value={category}
                    setValue={(value: any) => handleChange(value, "category")}
                    placeholder="Choose event categories"
                  /> */}

                  <MultiSelectWrapper
                    options={categories.map((category: any) => category.value)}
                    placeholder="Select categories"
                    value={category}
                    setValue={(value: any) => handleChange(value, "category")}
                  />
                  {formSubmitted && category.length < 1 && (
                    <div className="my-1 error-text-asd errorField">
                      *Category required
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <div className="d-flex flex-column col-6 event-wrapper">
                  <label className="form-label">
                    Classification of Event *
                  </label>
                  <SelectWrapper
                    options={classification_options}
                    value={classification}
                    setValue={(value: any) =>
                      handleChange(value, "classification")
                    }
                    placeholder="Choose event classification"
                  />
                  {formSubmitted && !classification && (
                    <div className="my-1 error-text-asd errorField">
                      *Classification of event required
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column col-6 categories-wrapper">
                  <label className="form-label">Guest Allowed *</label>
                  <input
                    value={guestLimit}
                    onChange={(e: any) => {
                      if (/^[0-9]?$/.test(e.target.value)) {
                        handleChange(e.target.value, e.target.name);
                      }
                    }}
                    name="guestLimit"
                    className="input-value-field"
                    disabled={type === "Ticketed" || type === ""}
                    placeholder="Guest Count"
                  />
                  {formSubmitted &&
                    guestLimit !== "" &&
                    type === "Guest List" && (
                      <div className="my-1 error-text-asd errorField">
                        *Guest Count required
                      </div>
                    )}
                </div>
              </div>
              {classification === "External" && (
                <div className="row mb-4">
                  <div className="d-flex flex-column col-12 categories-wrapper">
                    <label className="form-label">External Site URL *</label>
                    <input
                      value={
                        classification !== "External" ? "" : externalSiteUrl
                      }
                      onChange={(e) => {
                        const { name, value } = e.target;
                        handleChange(value, name);

                        const isValidUrl =
                          value.startsWith("http://") ||
                          value.startsWith("https://");
                        if (!isValidUrl) {
                          setUrlError("Please enter a valid URL");
                        } else {
                          setUrlError("");
                        }
                      }}
                      name="externalSiteUrl"
                      className="input-value-field"
                      placeholder="External Site Url"
                    />
                    {formSubmitted &&
                      classification === "External" &&
                      !externalSiteUrl && (
                        <div className="my-1 error-text-asd errorField">
                          *External site url required
                        </div>
                      )}
                    {urlError && (
                      <div className="my-1 error-text-asd errorField">
                        {urlError}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="row mb-4">
                <div className="col-6 d-flex flex-column justify-content-start ">
                  <label className="form-label">Event Feed Image *</label>
                  <div className="img-desc-cni2 mb-2">
                    This will be shown as the main image on your offer feed in
                    the app.
                  </div>
                  <Image
                    required={true}
                    text="Event Feed Image"
                    formSubmitted={formSubmitted}
                    imageSize={{ width: 300, height: 360 }}
                    imageType="feed"
                    onImageSelected={onImageSelected}
                    value={feedImage}
                    setValue={(feedImage) =>
                      handleChange(feedImage, "feedImage")
                    }
                  />
                </div>
                <div className="col-6 d-flex flex-column justify-content-start ">
                  <label className="form-label">Event Page Image *</label>
                  <div className="img-desc-cni2 mb-2">
                    This will be shown as the primary image on the event page in
                    the app.
                    {/* Size 320px x 480px. */}
                  </div>
                  <Image
                    required={true}
                    text="Event Page Image"
                    formSubmitted={formSubmitted}
                    imageSize={{ width: 320, height: 480 }}
                    imageType="page"
                    onImageSelected={onImageSelected}
                    value={pageImage}
                    setValue={(pageImage) =>
                      handleChange(pageImage, "pageImage")
                    }
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6 d-flex flex-column justify-content-start ">
                  <label className="form-label">Event Video</label>
                  <div className="img-desc-cni2 mb-2">
                    This will be shown as the primary video on the event page in
                    the app
                  </div>
                  <Video
                    required={true}
                    text="Event Video"
                    formSubmitted={formSubmitted}
                    imageSize={{ width: 300, height: 360 }}
                    imageType="event video"
                    onImageSelected={onImageSelected}
                    value={videoUrl}
                    setValue={(videoUrl) => handleChange(videoUrl, "videoUrl")}
                    type="video"
                  />
                </div>
                <div className="col-6 d-flex flex-column justify-content-start "></div>
              </div>
              <div className="row mt-8 mb-4">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 mt-2">
                  <div className="row">
                    <div className=" col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <label className="form-label">Featured Event</label>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                      <div className="toggle-button-wrap f-campaign">
                        <Toggle
                          tabIndex={0}
                          id="cheese-status"
                          defaultChecked={topEvent}
                          icons={false}
                          onChange={(e) =>
                            handleChange(e.target.checked, "topEvent")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 mt-2">
                  <div className="row">
                    <div className=" col-lg-8 col-md-8 col-sm-8 col-xs-8">
                      <label className="form-label">Access Event</label>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                      <div className="toggle-button-wrap f-campaign">
                        <Toggle
                          tabIndex={0}
                          id="cheese-status"
                          defaultChecked={accessEvent}
                          icons={false}
                          onChange={(e) =>
                            handleChange(e.target.checked, "accessEvent")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-8 mb-4">
                <div className="col-12 ">
                  <div className="next-button-wrapper">
                    <Button
                      title={"NEXT"}
                      type={"submit"}
                      backgroundColor="white"
                      color="black"
                      border="none"
                      borderRadius="6px"
                      padding="10px 48px"
                      fontSize="12px"
                      fontWeight="600"
                    />
                    <Modal
                      showModal={showModal}
                      handleClose={handleCloseModal}
                      handleExit={handleExit}
                      buttonTitle="EXIT"
                      title="Are you sure you want to exit?"
                      message="Are you sure you want to exit creating a new event? The information will not be saved."
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div style={{ position: "relative" }}>
            <div className="row stage-bar">
              <div className="col-4 step-1"></div>
              <div className="col-4"></div>
              <div className="col-4"></div>
            </div>
          </div>
        </div>
        <div className="form-image-right col-lg-6 col-md-3 p-0">
          <div className="image-section">
            <div className="overlay"></div>
            <span onClick={handleOpenModal} className="exit-icon">
              {ExitIcon()}
            </span>
            <div className="campaign-icon">{CampaignIcon()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepOne;

// const event_name = useSelector(
//   (state: any) => state.campaign_form.formData.name
// );
// const status_status = useSelector(
//   (state: any) => state.campaign_form.formData.status
// );
// const description = useSelector(
//   (state: any) => state.campaign_form.formData.description
// );
// const categories_categories = useSelector(
//   (state: any) => state.campaign_form.formData.category
// );
// const type_type = useSelector(
//   (state: any) => state.campaign_form.formData.type
// );

// const initialValues = {
//   name: event_name,
//   status_status: status_status,
//   description: description,
//   categories_categories: categories_categories,
//   type_type: type_type,
//   isToggled: isToggled,
// };

// const validationSchema = Yup.object({
//   name: Yup.string().required("*Event name required"),
//   status_status: Yup.string().required("*Status required"),
//   description: Yup.string().required("*Campaign description required"),
//   categories_categories: Yup.string().required("*Category required"),
//   type_type: Yup.string().required("*Event type required"),
// });

// const formik = useFormik({
//   initialValues,
//   validationSchema,
//   onSubmit: (values) => {
//     setSteps(2);
//   },
// });

// useEffect(() => {
//   formik.setValues({
//     ...formik.values,
//     name: event_name,
//     status_status: status_status,
//     description: description,
//     categories_categories: categories_categories,
//     type_type: type_type,
//     isToggled: isToggled,
//   });
// }, [
//   event_name,
//   status_status,
//   description,
//   categories_categories,
//   type_type,
//   isToggled,
// ]);
