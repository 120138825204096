import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import pink from "../../assets/images/events.png";
import { NotificationProps } from "@/@Interfaces/NavbarProps";
import Loader from "../Spinner";
import usePolling from "./notifications";
import { readNotificationsAdmin } from "@/services/readNotifications";
import showToast from "../cogo-toast";

const Notifications: React.FC<NotificationProps> = ({
  isNotify,
  setIsNotify,
}) => {
  const { notificationsData, isLoading, isNewNotification, getNotify } =
    usePolling();
  const [isReadNotifications, setIsReadNotifications] = useState(true);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsNotify(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNotify]);

  const readNotifications = async (id: string) => {
    try {
      const response = await readNotificationsAdmin(id, isReadNotifications);
      getNotify();
    } catch (error: any) {
      if (error.message === "Network Error") {
        showToast(
          "It seems you're offline. Please check your connection and try again.",
          "error"
        );
      } else {
        showToast("Error in updating notifications.", "error");
      }
    }
  };
  return (
    <div ref={dropdownRef} className="notifications-section">
      <ul>
        <li className="row not-header">
          <h4>Notifications</h4>
        </li>
        {isLoading && <Loader />}

        {notificationsData?.length > 0 ? (
          notificationsData.map((notification: any) => (
            <div key={notification._id}>
              <li
                className={`list-notification row ${
                  notification.isRead ? "" : "read-notification"
                }`}
              >
                <div className="col-2">
                  <img
                    src={notification.image ? notification.image : pink}
                    alt={notification?.eventName}
                  />
                </div>
                <div className="col-10">
                  <span className="not-name">
                    {notification.eventName
                      ? notification.eventName
                      : "Test Event"}
                  </span>
                  {notification.isRead ? (
                    <div></div>
                  ) : (
                    <span className="green-signal mt-2"></span>
                  )}
                  <p className="not-description">{notification?.content}</p>
                  <p
                    className="read-only mt-2"
                    onClick={() => readNotifications(notification._id)}
                  >
                    {notification.isRead ? "" : "Mark as read"}
                  </p>
                </div>
              </li>
              <hr className="not-breaker" />
            </div>
          ))
        ) : (
           !isLoading && (
            <p className="d-flex justify-content-center not-message">
            No new Notifications.
          </p>
           )
        )}
      </ul>
    </div>
  );
};

export default Notifications;
