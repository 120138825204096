import { patch } from './api';
import URLS from "../constants/api";

export const editApplicant = async (
    id:string | number,
  data: any
) => {

    const payload = {
        user: data
    }

   const endpoint = `${URLS.EDIT_APPLICANT}?userId=${id}`;
  const response = await patch(endpoint, payload);
  return response;
};

