import axios from "axios";
import URLS from "../constants/api";
import { ILoginPayload } from "../@Interfaces/LoginPayload";
import { ILoginResponse } from "../@Interfaces/LoginResponse";
import { setTokens } from "./setTokens";
export const logIn = (payload: ILoginPayload) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(URLS.LOGIN, payload)
        .then((response) => {
          if (response) {
            
            setTokens(response.data.accessToken, response.data.refreshToken);
            resolve(response as ILoginResponse);
          } else {
            resolve(response as ILoginResponse);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  } catch {
    alert(`Error-404 Not Found`);
  }
};
