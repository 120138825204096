import React, { useState, useEffect, useRef } from "react";
import { ArrowDown } from "@/constants/utils/svgs";
import "./style.css";

const SelectWrapper = ({
  options,
  value,
  setValue,
  placeholder,
  specialCase = false,
  index = 0,
  disabled = false,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const handleSelect = (selectedOption: any) => {
    if (!disabled) {
      if (specialCase) {
        setValue(index, "availableTo", selectedOption);
      } else {
        setValue(selectedOption);
      }
      setIsOpen(false);
    }
  };

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (disabled) return;
    if (event.key === "Enter") {
      setIsOpen((prev) => !prev);
    } else if (event.key === "ArrowDown") {
      setFocusedIndex((prevIndex) =>
        prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === "ArrowUp") {
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (event.key === "Tab") {
      setIsOpen(false);
    }
  };

  const handleOptionSelect = (index: number) => {
    setFocusedIndex(index);
    handleSelect(options[index].value);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (wrapperRef.current && focusedIndex >= 0 && isOpen) {
      const elements =
        wrapperRef.current.querySelectorAll<HTMLDivElement>(
          ".singleOptions-cs3"
        );
      if (elements && elements[focusedIndex]) {
        elements[focusedIndex].focus();
      }
    }
  }, [focusedIndex, isOpen]);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  return (
    <div
      className="position-relative w-100"
      ref={wrapperRef}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      data-testid="select-wrapper"
    >
      <div
        className={`select-show-asd d-flex align-items-center justify-content-between ${
          value ? "text-white select-font" : "text-gray"
        } ${isOpen ? "open-outline" : ""}`}
        onClick={handleToggle}
        style={{ paddingRight: "28px" }} // Add padding to avoid overlap with the arrow
      >
        {truncateText(value ? value : placeholder, 35)}
      </div>
      <div
        className="text-white position-absolute arrowForSelect text-large"
        onClick={handleToggle}
      >
        {ArrowDown()}
      </div>
      {isOpen && (
        <div className="position-absolute w-100 wrapper-select">
          {options.map((option: any, index: any) => (
            <div
              key={index}
              className={`singleOptions-cs3 px-1 ${
                index === focusedIndex ? "focused" : ""
              }`}
              onClick={() => handleOptionSelect(index)}
              onKeyDown={(e) => e.key === "Enter" && handleOptionSelect(index)}
              tabIndex={0}
              data-testid={`option-${index}`}
            >
              {option.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectWrapper;
