import React, { useState, useEffect } from "react";
import { EventImageProps } from "@/@Interfaces/ImageProps";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { setFeedImagePath } from "@/redux/slices/imageSlice";
import { setPageImagePath } from "@/redux/slices/imageSlice";
import { setBusinessImagePath } from "@/redux/slices/businessImageSlice";
import "./style.css";
import { DeleteIcon, ImageIcon, RefreshIcon } from "@/constants/utils/svgs";
const EventImage: React.FC<EventImageProps> = ({
  text,
  formSubmitted,
  required,
  imageSize,
  imageType,
  onImageSelected,
  dropzoneType,
  value,
  fileDelete,
  fileName,
  setValue,
  disabled = false,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (imageType === "feed") {
      setSelectedFile(value);
    } else {
      setSelectedFile(value);
    }
  }, [value]);

  useEffect(() => {
    if (formSubmitted && required && !selectedFile) {
      setError(`${text} required`);
    } else if (selectedFile) {
      onImageSelected(imageType, selectedFile);
      setError("");
    }
  }, [required, text, formSubmitted, selectedFile]);

  const validateFileName = (name: string) => {
    const maxLength = 100;
    const validPattern = /^[a-zA-Z0-9\- ()_]+$/;

    if (name.length > maxLength) {
      return `File name should not exceed ${maxLength} characters.`;
    }
    if (!validPattern.test(name)) {
      return "File name should only contain alphabets, numbers, and hyphens.";
    }
    return "";
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    onDrop: async (files, err, ev) => {
      if (disabled) return;
      if (files.length === 0) {
        return;
      }

      const file = files[0];
      // const fileSizeInKb = file.size / 1024;
      // if (imageType === "business" && fileSizeInKb > 50) {
      //   setError("Image size should be 50KB or less for business logo.");
      //   return;
      // }
      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
      const fileNameError = validateFileName(fileNameWithoutExtension);
      if (fileNameError) {
        setError(fileNameError);
        return;
      }
      const img = document.createElement("img");
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        img.onload = () => {
          // if (
          //   img.width === imageSize?.width &&
          //   img.height === imageSize?.height
          // ) {
          setSelectedFile(file);
          const imageObj = { path: reader.result, name: file.name };
          if (imageType === "feed") {
            dispatch(setFeedImagePath(imageObj));
          } else if (imageType === "page") {
            dispatch(setPageImagePath(imageObj));
          } else if (imageType === "business") {
            dispatch(setBusinessImagePath(imageObj));
          }
          // else {
          //   if (
          //     img.width === imageSize?.width &&
          //     img.height === imageSize?.height
          //   ) {
          //     setSelectedFile(file);
          //     if (imageType === "feed") {
          //       dispatch(setFeedImagePath(imageObj));
          //     } else if (imageType === "page") {
          //       dispatch(setPageImagePath(imageObj));
          //     }
          //     setError("");
          //   } else {
          //     setError(
          //       `Image dimensions should be ${imageSize?.width}x${imageSize?.height}`
          //     );
          //   }
          // }
          imageObj !== undefined
            ? setValue?.(imageType, imageObj)
            : setValue?.(imageType, "");
          setError("");
          // }
          // else {
          //   setError(
          //     `Image dimensions should be ${imageSize?.width}x${imageSize?.height}`
          //   );
          // }
        };
        img.src = URL.createObjectURL(file);
      };
    },
  });

  return (
    <div className={dropzoneType ? "" : "image-dropzone-wrapper"} tabIndex={0}>
      {selectedFile ? (
        <div className="selected-file" tabIndex={0}>
          <div className="file-info-2 row">
            <span className="input-file-name col-8">
              {ImageIcon()}{" "}
              {fileName.length > 25 ? fileName.slice(0, 25) + "..." : fileName}
            </span>
            <label
              htmlFor={imageType}
              className="refresh-icon col-2"
              onClick={(e) => disabled && e.preventDefault()}
            >
              {" "}
              {RefreshIcon()}
            </label>
            <input
              name={imageType}
              id={imageType || "file-input"}
              {...getInputProps()}
            />
            <div
              className=" del-icon col-2"
              onClick={() => !disabled && fileDelete(imageType)}
              data-testid="delete-icon-dummy"
            >
              {DeleteIcon()}
            </div>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`img-wrap ${
            imageType === "business" ? "business-image" : ""
          }`}
          tabIndex={0}
        >
          <input {...getInputProps()} />
          <h6
            className="header-img"
            style={{ marginTop: dropzoneType ? "0px" : undefined }}
          >
            Drop files here
          </h6>
          <p
            className="content-img"
            style={{ marginBottom: dropzoneType ? "0px" : undefined }}
          >
            Or <span className="click-here">click here</span> to browse your
            files
          </p>
        </div>
      )}
      {error && <span className="error-color">*{error}</span>}
    </div>
  );
};

export default EventImage;
