import React, { useEffect, useState } from "react";
import "./style.css";
import Button from "../Button";
import { AddIcon, CampaignIcon, ExitIcon, Line } from "@/constants/utils/svgs";
import Modal from "../ExitModal";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { NavigationPaths } from "@/constants/paths";
import Toast from "../Toast";
import Loader from "../Spinner";
import { available_options } from "@/constants/availableToOptions";
import MultiSelectWrapper from "../MultiSelect";
import { TicketFormData } from "@/@Interfaces/EventStepThreeProps";
import { StepThreeProps } from "@/@Interfaces/EventStepThreeProps";
import { event_available } from "@/constants/eventAvailable";

const StepsThree: React.FC<StepThreeProps> = ({
  loading,
  setSteps,
  tickets,
  setTickets,
  ticketAvailableOptions,
  eventAvailableTo,
  setTicketAvailableOptions,
  handleSubmitCampaign,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [ticketNameError, setTicketNameError] = useState<string>("");
  const [ticketPriceError, setTicketPriceError] = useState<string>("");

  // Hooks
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onChange",
  });

  // Event handlers
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExit = () => {
    navigate(NavigationPaths.CAMPAIGNS);
  };

  const handleBack = () => {
    setSteps(2);
  };

  //delete the ticket
  const handleRemoveTicket = (index: number) => {
    if (tickets.length > 1 && index >= 0 && index < tickets.length) {
      setTickets((prevTickets) => {
        const updatedTickets = [...prevTickets];
        updatedTickets.splice(index, 1);
        return updatedTickets;
      });
    }
  };

  useEffect(() => {
    if (eventAvailableTo.length === 1) {
      setTicketAvailableOptions(eventAvailableTo);
    } else {
      setTicketAvailableOptions(event_available); // Reset to all options if no specific selection
    }
  }, [eventAvailableTo]);
 
  const handleFinish = () => {
    setFormSubmitted(true);
  };
 
  //check if ticket data is correct submit the form
  const handleFinishClick = () => {
    const result = validateTickets();
    setFormSubmitted(true);
    if (result) handleSubmitCampaign();
  };

  const onClose = () => {
    setShowToast(false);
  };

  const handleTicketChange = (
    index: number,
    field: keyof TicketFormData["tickets"][0],
    value: string
  ) => {
    if (field === "name" && value.length > 100) {
      setTicketNameError("Ticket name cannot exceed 100 characters");
      return;
    } else if (field === "price") {
      let formattedPrice = value.replace(/[^\d.]/g, "");
      const periodsCount = formattedPrice.split(".").length - 1;
      if (periodsCount > 1) {
        return;
      }
      const decimalIndex = formattedPrice.indexOf(".");
      if (decimalIndex !== -1) {
        const decimalPart = formattedPrice.substring(decimalIndex + 1);
        formattedPrice =
          formattedPrice.substring(0, decimalIndex + 1) +
          decimalPart.slice(0, 2);
      }
      if (formattedPrice.length > 7) {
        formattedPrice = formattedPrice.slice(0, 7);
        setTicketPriceError("Ticket price cannot exceed 7 characters");
      } else {
        const price = parseFloat(formattedPrice);
        // if (price > 9999.99) {
        //   setTicketPriceError("Ticket price cannot exceed $9999.99");
        //   return;
        // }
        setTicketPriceError("");
      }
      setTickets((prevTickets) => {
        const updatedTickets = [...prevTickets];
        updatedTickets[index] = {
          ...updatedTickets[index],
          [field]: formattedPrice,
        };
        return updatedTickets;
      });
    } else if (field === "quantityAvailable") {
      // Ensure the value is numeric and not more than 6 digits
      const numericValue = value.replace(/\D/g, "");
      if (numericValue.length > 6|| numericValue.startsWith("0")) {
        return;
      }
      setTickets((prevTickets) => {
        const updatedTickets = [...prevTickets];
        updatedTickets[index] = {
          ...updatedTickets[index],
          [field]: numericValue,
        };
        return updatedTickets;
      });
    } else {
      setTickets((prevTickets) => {
        const updatedTickets = [...prevTickets];
        updatedTickets[index] = {
          ...updatedTickets[index],
          [field]: value,
        };
        return updatedTickets;
      });
    }
  };

  const validateTickets = () => {
    return tickets.every((ticket) =>
      Object.entries(ticket).every(([key, value]) =>
        key === "availableTo"
          ? Array.isArray(value) && value.length > 0
          : typeof value === "string" && value.trim() !== ""
      )
    );
  };

  //create a new ticket
  const handleAddTicket = () => {
    setTickets((prevTickets) => [
      ...prevTickets,
      {
        name: "",
        price: "",
        description: "",
        availableTo: [],
        quantityAvailable: "",
      },
    ]);
  };

  // JSX rendering
  return (
    <section className="ticket-details-section">
      <div className="row tkt">
        <div className="col-lg-6 col-md-6 adjust-height-fhbsh p-0">
          <div className="ticket-content-container">
            <div className="ticket-section">
              <div className="ticket-details-header">
                <h2>Ticket Details</h2>
                <p>Step 3 of 3</p>
              </div>
              <Toast
                role="toast"
                content={toastMessage}
                show={showToast}
                onClose={onClose}
              />
              <div className="ticket-content">
                {tickets.map((ticket, index) => (
                  <div key={index + 1} className="tickets">
                    <div className="ticket-header">
                      <div className="d-flex justify-content-between">
                        <h2>Ticket {index + 1}</h2>
                        {index > 0 && (
                          <div
                            className="text-white cursor-pointer"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemoveTicket(index)}
                          >
                            X
                          </div>
                        )}
                      </div>
                      <div className="breaker">{Line()}</div>
                    </div>
                    <div className="ticket-form">
                      <form onSubmit={handleSubmit(handleFinishClick)}>
                        <div className="ticket-wrapper row mb-4">
                          <div className="ticket-name col-8">
                            <label className="form-label">Ticket Name *</label>
                            <input
                              value={ticket?.name}
                              onChange={(e) =>
                                handleTicketChange(
                                  index,
                                  "name",
                                  e.target.value
                                )
                              }
                              name="event name"
                              className="input-value-field w-100"
                              placeholder="Please enter the ticket name"
                            />
                            {formSubmitted && !ticket.name && (
                              <div className="my-1 error-text-asd errorField">
                                Ticket name required
                              </div>
                            )}
                            {ticketNameError && (
                              <div className="my-1 error-text-asd errorField">
                                {ticketNameError}
                              </div>
                            )}
                          </div>
                          <div className="ticket-price col-4">
                            <label className="form-label">Ticket Price *</label>
                            <input
                              value={
                                ticket.price === "" ? "" : `$${ticket.price}`
                              }
                              onChange={(e) =>
                                handleTicketChange(
                                  index,
                                  "price",
                                  e.target.value.startsWith("$")
                                    ? e.target.value.replace("$", "")
                                    : e.target.value
                                )
                              }
                              name="Ticket Price"
                              className="input-value-field w-100"
                              placeholder="$00.00"
                            />
                            {formSubmitted && !ticket.price && (
                              <div className="my-1 error-text-asd errorField">
                                Ticket price required
                              </div>
                            )}
                            {ticketPriceError && (
                              <div className="my-1 error-text-asd errorField">
                                {ticketPriceError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="ticket-desc row mb-4">
                          <div className="col-12">
                            <label className="form-label">
                              Ticket Description *
                            </label>
                            <textarea
                              value={ticket?.description}
                              onChange={(e) =>
                                handleTicketChange(
                                  index,
                                  "description",
                                  e.target.value
                                )
                              }
                              name="event name"
                              className="text-area-2"
                              placeholder="Description of this specific ticket"
                            />
                            {formSubmitted && !ticket.description && (
                              <div className="my-1 error-text-asd errorField">
                                Ticket description required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="ticket-wrapper row mb-4">
                          <div className="guest-types col-8">
                            <label className="form-label">Available to *</label>
                            <MultiSelectWrapper
                              value={ticket.availableTo}
                              options={ticketAvailableOptions}
                              placeholder="Select ticket access for members"
                              setValue={(value: any) =>
                                handleTicketChange(index, "availableTo", value)
                              }
                            />
                            {formSubmitted && !ticket.availableTo && (
                              <div className="my-1 error-text-asd errorField">
                                Available to required
                              </div>
                            )}
                          </div>
                          <div className="ticket-qty col-4">
                            <label className="form-label">
                              Quantity Available *
                            </label>
                            <input
                              value={ticket?.quantityAvailable}
                              onChange={(e) =>
                                handleTicketChange(
                                  index,
                                  "quantityAvailable",
                                  e.target.value
                                )
                              }
                              name="Quantity Available"
                              className="input-value-field w-100"
                              placeholder="0"
                            />
                            {formSubmitted && !ticket.quantityAvailable && (
                              <div className="my-1 error-text-asd errorField">
                                Quantity available required
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                ))}
              </div>
              <div className="add-ticket mt-5">
                <Button
                  title={"Add another ticket"}
                  type={"button"}
                  backgroundColor="#1F1F22"
                  color="white"
                  border="1px solid #8E929A"
                  borderRadius="5px"
                  padding="10px 35px"
                  fontSize="14px"
                  fontWeight="600"
                  onButtonClick={handleAddTicket}
                />
                <div className="add-icon">
                  <span>{AddIcon()}</span>
                </div>
              </div>
              {showSpinner && <Loader />}
              <div className="ticket-button-area">
                <div className="ticket-back">
                  <Button
                    title={"BACK"}
                    type={"button"}
                    backgroundColor="#1A1A1C"
                    color="white"
                    border="1px solid white"
                    borderRadius="6px"
                    padding="10px 48px"
                    fontSize="12px"
                    fontWeight="600"
                    onButtonClick={handleBack}
                  />
                </div>
                <div className="ticket-finish">
                  <button
                    type="submit"
                    onClick={handleSubmit(handleFinishClick, handleFinish)}
                    className="benefit-submit-button"
                    disabled={loading}
                  >
                    {loading === true ? <Loader /> : "FINISH"}
                  </button>
                  {/* exit modal */}
                  <Modal
                    showModal={showModal}
                    handleClose={handleCloseModal}
                    handleExit={handleExit}
                    buttonTitle="EXIT"
                    title="Are you sure you want to exit?"
                    message="Are you sure you want to exit creating a new campaign? The information will not be saved."
                  />
                </div>
              </div>
            </div>
          </div>
          {/* progress bar */}
          <div style={{ position: "relative" }}>
            {" "}
            <div className="mt-8 row stage-bar">
              <div className="col-4 step-1-event"></div>
              <div className="col-4 step-1-event"></div>
              <div className="col-4 step-2"></div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 p-0 form-image-right">
          <div className="image-section-3">
            <div className="overlay"></div>
            <span onClick={handleOpenModal} className="exit-icon">
              {ExitIcon()}
            </span>
            <div className="campaign-icon">{CampaignIcon()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepsThree;
