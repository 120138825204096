import axios, { AxiosInstance } from "axios";
import URLS from "@/constants/api";

const api: AxiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_API_URL,
  baseURL: process.env.REACT_APP_BASE_BK_URL,
});

api.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

const clearTokensAndRedirect = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  // window.location.href = "/portal"; // Redirect to login or appropriate page
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        if (error.response.status === 401) {
          // Handle 401 error - Redirect to /portal
          clearTokensAndRedirect();
          window.location.href = "/portal";
        } else {
          // Handle 403 error - Refresh access token
          const newAccessToken = await refreshAccessToken(api, () => {
            // Callback function for navigation logic
            // This could be used if you need additional logic after refreshing the token

          });

          // Update the stored access token
          localStorage.setItem("accessToken", newAccessToken);

          // Retry the original request with the new access token
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        }
      } catch (refreshError) {
        // Handle refresh error, e.g., redirect to login
        // Redirect to login or show an error message
        throw new Error("An error occurred.");
      }
    }

    return Promise.reject(error);
  }
);


// Function to refresh access token using the refresh token
const refreshAccessToken = async (apiInstance: any, navigateCallback: any) => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    // Handle the scenario where there is no refresh token
    navigateCallback();
    throw new Error("No refresh token found.");
  }

  try {
    const response = await apiInstance.post(URLS.REFRESH_TOKEN, {
      refreshToken,
    });
    // Extract and return the new access token from the response
    return response.data.accessToken;
  } catch (error) {
    // Handle the refresh request error
    throw new Error("An error occurred.");
  }
};

export default api;
