import React from "react";
import "./style.css";
import { InputProps, customStyleProps } from "@/@Interfaces/Input";

const Input: React.FC<InputProps> = ({
  type,
  id,
  placeholder,
  register,
  label,
  error,
  padding,
  value,
  height,
  fontSize,
  disabled = false,
  text,
  maxLength,
  onChange, 
  backgroundColor,
  isError,
  passwordValidation,
}) => {

  const inputClassName = isError || passwordValidation ? "form-control i-field input-error" : "form-control i-field";


  const customStyles: customStyleProps = {
    padding: padding || "",
    height: height || "",
    fontSize: fontSize,
    backgroundColor: backgroundColor || "transparent",
  };

  const validateMaxLength = (value: string) => {
    if (maxLength && value.length > maxLength) {
      return `${label} cannot exceed ${maxLength} characters`;
    }
    return;
  };
  
  const validateEmail = (value: string) => {
    // Basic email validation regex
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(value);
  };

  const emailValidation = (value: string) => {
    if (id === "emailAddress" || id === 'primaryEmailAddress' || id === 'email') {
      if(validateEmail(value) === false){
      return  "Invalid email address";
      }
    }
    return true; // Return true if not primaryEmailAddress or no validation needed
  };

  return (
    <div className="mb-4">
      {label && (
        <label htmlFor={id} className="form-label">
          {label} *
        </label>
      )}
      <input
        width={"100%"}
        type={type}
        className={inputClassName}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={value}
        {...register(id, {
          required: `${label || text} required`,
          validate: {
            maxLength: validateMaxLength,
            emailValidation: emailValidation,
           
          }
        })}
        style={customStyles}
        onChange={(e) => {
        
          if (onChange) {
            onChange(e.target.value);
          }
       
        }}
       
      />
      {error && <span className="error-color">*{error}</span>}
    </div>
  );
};

export default Input;
