import React from "react";
import "./style.css";
import { TextBoxProps, customStyleProps } from "@/@Interfaces/Input";

const TextBox: React.FC<TextBoxProps> = ({
  id,
  placeholder,
  register,
  label,
  error,
  backgroundColor,
  border,
  color,
  value,
  onChange,
  maxLength,
  disabled,
}) => {
  const customStyles: customStyleProps = {
    color: color || "black",
    backgroundColor: backgroundColor || "white",
    border: border || "none",
  };

  const validateDescription = (value: string) => {
    if (value.trim().length > 1000) {
      return "Description must not exceed 1000 characters.";
    }
    return true;
  };
  
  return (
    <div className="mb-4">
      <label htmlFor={id} className="form-label">
        {label} *
      </label>
      <textarea
        className="form-control text-box"
        placeholder={placeholder}
        id={id}
        defaultValue={value}
        disabled={disabled}
        style={customStyles}
        {...register(id || "", {
          required: "Campaign description required",
          validate: validateDescription,
        })}
        rows={3}
        onChange={(e) => {
          const value = e.target.value;
          if (onChange) {
            onChange(value); 
          }
        }}
        maxLength={maxLength}
      ></textarea>
      {error && <span className="error-color">*{error}</span>}
    </div>
  );
};

export default TextBox;
