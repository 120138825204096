import React, { useState, useEffect, useRef } from "react";
import InputMask from "react-input-mask";
import "./timePicker.css";
import DatePicker from "react-datepicker";

interface TimeRangePickerProps {
  handleChange: (name: string, value: any) => void;
  eventTime: string;
  disable?: boolean;
}

const TimeRangePicker: React.FC<TimeRangePickerProps> = ({
  handleChange,
  eventTime,
  disable = false,
}) => {
  console.log(disable);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null); // End time state
  const [showPicker, setShowPicker] = useState(false); // State to show/hide picker
  const [startError, setStartError] = useState("");
  const [endError, setEndError] = useState("");

  // Ref to the picker container
  const pickerRef = useRef<HTMLDivElement | null>(null);

  const handleStartTimeChange = (date: any) => {
    if (endTime && endTime < date) {
      setStartError("Start time must be earlier than end time");
    } else {
      setStartError("");
      setStartTime(date);
    }
  };
  function formatTime(date: any) {
    // Ensure the input is a Date object
    if (!(date instanceof Date)) {
      throw new Error("Input must be a Date object");
    }

    // Get hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format hours and minutes as 'HH:mm'
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  }

  const handleEndTimeChange = (date: any) => {
    if (startTime && startTime > date) {
      setEndError("End time must be later than start time");
    } else {
      setEndError("");
      setEndTime(date);
    }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!startTime || !endTime || startError || endError) {
      // Optionally handle invalid form state here
      return;
    }
    const formattedStartTime = formatTime(startTime);
    const formattedEndTime = formatTime(endTime);
    handleChange(`${formattedStartTime} - ${formattedEndTime}`, "eventTime");
    setShowPicker(false);
  };

  // Hide picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      {/* Masked Input Field */}
      <InputMask
        mask="99:99 - 99:99"
        className="input-mask position-relative"
        placeholder="00:00 - 00:00"
        disabled={disable}
        value={eventTime}
        onClick={() => setShowPicker((prev) => !prev)}
        readOnly
      />

      {/* Conditionally render the time pickers for start and end time */}
      {showPicker && (
        <div
          ref={pickerRef}
          className="time-picker-popup"
          style={{
            position: "absolute", // Fixed position to make it float
            zIndex: 99999, // Very high z-index to stay on top
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional: Add shadow for better visibility
            padding: "15px", // Optional: Padding for spacing
            borderRadius: "8px", // Optional: Rounded corners for a clean look
          }}
        >
          <form>
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <div className="time-picker-section">
                <label className="time-label">Start Time</label>
                <DatePicker
                  selected={startTime}
                  onChange={(date: Date) => handleStartTimeChange(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                />
                {startError && (
                  <span className="error-color">{startError}</span>
                )}
              </div>
              <div className="time-picker-section">
                <label className="time-label">End Time</label>
                <DatePicker
                  selected={endTime}
                  onChange={(date: Date) => handleEndTimeChange(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                />
                {endError && <span className="error-color">{endError}</span>}
              </div>
            </div>
            <button
              type="button"
              className="btn btn-dark mt-3"
              disabled={!startTime || !endTime || startError || endError}
              onClick={handleSubmit}
            >
              Set Time
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default TimeRangePicker;
