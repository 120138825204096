import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import {
  NotificationIcon,
  SettingsIcon,
  UsersIcon,
} from "@/constants/utils/svgs";
import { useNavigate } from "react-router-dom";
import { setTokens } from "@/services/setTokens";
import { NavbarProps } from "@/@Interfaces/NavbarProps";
import Notifications from "../Notifications";
import usePolling from "@/components/Notifications/notifications";

const Navbar: React.FC<NavbarProps> = ({ breadcrumbs }) => {
  const [isSelectVisible, setIsSelectVisible] = useState<boolean>(false);
  const [isNotify, setIsNotify] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const navigate = useNavigate();
  const { notificationsData, isLoading, isNewNotification } = usePolling();

  const applicantStatus = useSelector(
    (state: any) => state.applicant_status.applicantStatus
  );

  //to handle navbar breadcrumbs
  function processInputs(
    input1: string,
    input2?: string
  ): [string, string | undefined] {
    if (input1 === "Dashboard") {
      return [input1, undefined];
    } else if (input1 === "Applications") {
      return [input1, applicantStatus || undefined];
    } else if (
      input1 === "Events" ||
      input1 === "Businesses" ||
      input1 === "Benefits" ||
      input1 === "Attendees" ||
      input1 === "Contact Us" ||
      input1 === "Enquiry" ||
      input1 === "Intake"
    ) {
      return ["Content Management", input1];
    } else if (input1 === "Members") {
      return ["Applications", input1];
    } else {
      return ["Unknown Input", undefined];
    }
  }

  // Define a mapping of breadcrumb strings to dynamic content
  const dynamicContentMap: { [key: string]: string } = {
    "Content Management": "Events",
  };

  // Use dynamic content based on the breadcrumbs value
  const dynamicContent = dynamicContentMap[breadcrumbs] || applicantStatus;

  const handleNotificationsIcon = () => {
    setIsNotify((prev) => !prev);
  };

  const handleUserIconClick = () => {
    setIsSelectVisible(!isSelectVisible);
  };

  const handleSettingsIconClick = () => {
    setIsSettings(!isSettings);
  };

  const handleLogout = () => {
    setTokens("", "");
    navigate("/portal");
  };

  const handleChangePassword = () => {
    navigate("/change-password");
  };

  const handleProfileSettings = () => {
    navigate("/profile-settings");
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownSettingsRef = useRef<HTMLDivElement>(null);

  //to close dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsSelectVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownSettingsRef.current &&
        !dropdownSettingsRef.current.contains(event.target as Node)
      ) {
        setIsSettings(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className="navbar-section">
      <div className="row">
        <div
          className="col-lg-4"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="breadcrumb-wrapper">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {processInputs(breadcrumbs)[0]}
                </li>
                {processInputs(breadcrumbs)[1] ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {processInputs(breadcrumbs)[1]}
                  </li>
                ) : null}
              </ol>
            </nav>
          </div>
        </div>
        <div className="col-lg-4"></div>
        <div className="col-lg-4">
          <div className="icon-wrapper">
            <ul className="icon-list">
              <li className="pointer position-relative">
                {" "}
                <a onClick={handleNotificationsIcon}>{NotificationIcon()}</a>
                {isNewNotification && (
                  <span className="position-absolute dot-message translate-middle bg-danger border border-light rounded-circle">
                    <span className="visually-hidden">New alerts</span>
                  </span>
                )}
              </li>
              {isNotify && (
                <Notifications isNotify={isNotify} setIsNotify={setIsNotify} />
              )}

              <li className="pointer">
                {" "}
                <a onClick={handleSettingsIconClick}>{SettingsIcon()}</a>
              </li>
              {isSettings && (
                <div ref={dropdownSettingsRef} className="settings-dropdown">
                  <ul>
                    <li
                      className="logout-wrapper pointer"
                      onClick={handleChangePassword}
                    >
                      Change Password
                    </li>
                    <li
                      className="logout-wrapper pointer"
                      onClick={handleProfileSettings}
                    >
                      Profile Settings
                    </li>
                  </ul>
                </div>
              )}

              <li className="pointer">
                {" "}
                <a className="user-icon-wrapper" onClick={handleUserIconClick}>
                  {UsersIcon()}
                </a>
              </li>
              {isSelectVisible && (
                <div ref={dropdownRef} className="user-dropdown">
                  <ul>
                    <li
                      className="logout-wrapper pointer"
                      onClick={handleLogout}
                    >
                      Log Out
                    </li>
                  </ul>
                </div>
              )}
              {/* Commenting for later use */}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
