import { Categories } from "@/@Interfaces/Categories";

export const categories: Categories[] = [
  { value: "Fashion", label: "Fashion" },
  { value: "Sports", label: "Sports" },
  { value: "Music", label: "Music" },
  { value: "Arts & Film", label: "Arts & Film" },
  { value: "Photography", label: "Photography" },
  { value: "Business", label: "Business" },
  { value: "Networking", label: "Networking" },
  { value: "Culture", label: "Culture" },
];
