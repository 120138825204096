import React, { useEffect, useState } from "react";
import "./style.css";
import { format } from "date-fns";
import Input from "../Input";
import CustomSelect from "../Dropdown";
import { BusinessCategory } from "@/constants/BusinessCategory";
import { SubmitHandler, useForm } from "react-hook-form";
import TextBox from "../Text Box";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { StylesConfig } from "react-select";
import { BusinessDocumentProp } from "@/@Interfaces/ViewBusinessWithDocs";
import briefcase from "../../assets/images/dark-briefcase.jpg";
import {
  DeleteIcon,
  ImageIcon,
  Line,
  searchIcon,
} from "@/constants/utils/svgs";
import { countries } from "@/constants/countries";
import Button from "../Button";
import { updateBusiness } from "@/services/businesses";
import BusinessImage from "../BusinessImage";
import { useSelector, useDispatch } from "react-redux";
import showToast from "../cogo-toast";
import { get_2, uploadBusinessLogo } from "@/services/api";
import URLS from "@/constants/api";
import { resetBusinessImage } from "@/redux/slices/businessImageSlice";
import Loader from "../Spinner";
import BusinessDocument from "../BusinessDocument";
import SelectWrapper from "../selectWrapper";
import { documentTypes } from "@/constants/docTypes";
import { BusinessResponse } from "@/@Interfaces/ViewBusinessWithDocs";
import {
  DocumentFormData,
  DocumentImages,
} from "@/@Interfaces/BusinessStepFourProps";
import { reUploadDocumentFile } from "@/services/reUploadDocs";
import { downloadFiles } from "@/services/docsDownload";

interface BusinessFormProps {
  selectedBusiness: BusinessResponse | null;
  onClose: () => void;
  refetchToggle: any;
  viewBusiness?: boolean;
  setRefetchToggle: any;
}

const EditBusinessForm: React.FC<BusinessFormProps> = ({
  selectedBusiness,
  onClose,
  refetchToggle,
  viewBusiness,
  setRefetchToggle,
}) => {
  const dispatch = useDispatch();

  const [businessCategory1, setBusinessCategory1] = useState(
    selectedBusiness?.business.businessCategory || ""
  );
  const [businessLogoSelected, setBusinessLogoSelected] = useState(false);
  const [docFiles, setDocFiles] = useState<DocumentImages>({
    file: null,
    name: "",
  });
  const [emailError, setEmailError] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");
  const [businessDocument, setBusinessDocument] = useState(null);
  const [editText, setEditText] = useState("");
  const [businessLogo, setBusinessLogo] = useState(null);
  const [postError, setPostError] = useState<string>("");
  const [addressLine1, setAddressLine1] = useState(
    selectedBusiness?.business.addressLine1 || ""
  );
  const [city, setCity] = useState(selectedBusiness?.business.city || "");
  const [country, setCountry] = useState(
    selectedBusiness?.business.country || ""
  );
  const [selectedFile, setSelectedFile] = useState<any>(
    selectedBusiness?.business.logo
  );
  const [loading, setLoading] = useState(false);
  const [businessName, setBusinessName] = useState<any>(
    selectedBusiness?.business.businessName
  );
  const [refreshBusinessImageError, setRefreshBusinessImageError] = useState();
  const [businessDocs, setBusinessDocs] = useState<BusinessDocumentProp[]>(
    selectedBusiness?.businessDocuments
      ? selectedBusiness.businessDocuments.map((document) => ({
          ...document,
          _id: document._id,
        }))
      : []
  );

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue: formValues,
  } = useForm<any>({
    mode: "onChange",
  });
  const [value, setValue1] = useState<any | null>(null);
  const businessImage = useSelector(
    (state: any) => state.business_image_path.businessImage
  );
  const API_KEY = process.env.REACT_APP_BASE_API_KEY;

  const handleSelect = (e: any) => {
    setValue1(e);
  };

  useEffect(() => {
    if (value && value.label) {
      const formattedAddress = value.label;
      const addressParts = formattedAddress.split(", ");
      const country = addressParts.pop() || "";
      const city = addressParts.pop() || "";
      const address = addressParts.join(", ");
      setCountry(country);
      formValues("country", country);

      setCity(city);
      formValues("city", city);

      setAddressLine1(address);
      formValues("addressLine1", address);
    }
  }, [value]);

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    setLoading(true);
    const accountManagerData = {
      firstName: getValues().firstName,
      lastName: getValues().lastName,
      email: getValues().emailAddress,
      phoneNumber: getValues().phoneNumber,
      jobTitle: getValues().jobTitle,
    };

    const businessData: any = {};
    const values = getValues();

    businessData.logo = values.logo;
    if (values.businessName) businessData.businessName = values.businessName;
    if (values.businessWebsite)
      businessData.businessWebsite = values.businessWebsite;
    if (values.primaryEmailAddress)
      businessData.primaryEmailAddress = values.primaryEmailAddress;
    if (values.businessCategory)
      businessData.businessCategory = values.businessCategory;
    if (values.description) businessData.description = values.description;
    if (values.instagramHandler)
      businessData.instagramHandler = values.instagramHandler;
    if (values.linkedInURL) businessData.linkedInURL = values.linkedInURL;
    if (values.addressLine1) businessData.addressLine1 = values.addressLine1;
    businessData.addressLine2 = values.addressLine2 || "";
    if (values.city) businessData.city = values.city;
    if (values.country) businessData.country = values.country;
    if (values.postalCode) businessData.postalCode = values.postalCode;

    const documentsToAdd: DocumentFormData[] = businessDocs
      .filter((doc) => doc.flag === "added")
      .map(({ type, description, file, name, createdAt }) => ({
        type,
        description,
        name,
        file: file || null, // Ensure file is either File or null
        createdAt,
      }));

    if (documentsToAdd.length > 0) {
      const uploadDoc = await reUploadDocumentFile(
        documentsToAdd,
        selectedBusiness?.business._id
      );

      const updatedBusinessDocs = businessDocs.map((doc) => {
        const matchedResponse = uploadDoc.urlArray.find(
          (res: any) => res.fileName === doc.name
        );
        if (matchedResponse) {
          return {
            ...doc,
            businessId: selectedBusiness?.business._id,
            url: matchedResponse.value,
            file: null, // Set file to empty string as per your requirement
          };
        }

        return doc;
      });
      setBusinessDocs(updatedBusinessDocs);
      setTimeout(async () => {
        await updateBusiness(selectedBusiness?.business._id, {
          business: businessData,
          accountManager: accountManagerData,
          businessDocuments: updatedBusinessDocs, // Use updatedBusinessDocs here
        })
          .then((response) => {
            setRefetchToggle(!refetchToggle);
            setLoading(false);
            onClose();
            showToast("Business updated successfully!", "success");
            dispatch(resetBusinessImage());
          })
          .catch((error) => {
            setLoading(false);
            onClose();
            showToast("Error in updating business", "error");
          });
      }, 3000); // Delay of 3 seconds
    } else {
      setTimeout(async () => {
        await updateBusiness(selectedBusiness?.business._id, {
          business: businessData,
          accountManager: accountManagerData,
          businessDocuments: businessDocs, // Use updatedBusinessDocs here
        })
          .then((response) => {
            setRefetchToggle(!refetchToggle);
            setLoading(false);
            onClose();
            showToast("Business updated successfully!", "success");
            dispatch(resetBusinessImage());
          })
          .catch((error) => {
            setLoading(false);
            onClose();
            showToast("Error in updating business", "error");
          });
      }, 3000);
    }

    // Update state with updated businessDocs

    // Delay of 3 seconds
  };

  const customStyles: StylesConfig<any, false> = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      opacity: 1,
      backgroundColor: "#1f1f22",
      borderColor: state.isFocused ? "#8E929A" : "#8E929A",
      boxShadow: state.isFocused ? "0 0 0 1px #BBA383" : "none",
      borderRadius: "6px",
      height: "48px",
      padding: "5px 0px",
      "&:focus": {
        outline: "1px solid #BBA383",
        border: "none !important",
      },
      "&:hover": {
        outline: "none",
        borderColor: "#8E929A",
        boxShadow: "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#BBA383" : "#1f1f22",
      fontSize: "12px",
      color: state.isSelected ? "white" : "inherit",
      "&:hover": {
        backgroundColor: "#BBA383",
        color: "white",
      },
    }),

    menu: (provided) => ({
      ...provided,
      backgroundColor: "#1f1f22",
      color: "white",
      fontSize: "12px",
      zIndex: 9999,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      marginLeft: "5%",
      fontSize: "12px",
    }),
  };

  function processLogoUrl(logoUrl: string): string {
    const lastSlashIndex = logoUrl.lastIndexOf("/");
    const processedUrl =
      lastSlashIndex !== -1 ? logoUrl.substring(lastSlashIndex + 1) : logoUrl;
    const finalProcessedUrl = processedUrl.replace(/%20/g, " ");

    return finalProcessedUrl;
  }

  const onImageSelected = (imageType: string, file: File) => {
    if (imageType === "business") {
      setBusinessLogoSelected(true);
    }
    if (businessLogo) {
      const uploadFiles = {
        type: "Businesses",
        id: selectedBusiness?.business._id,
        logo: businessImage,
      };
      uploadBusinessLogo(URLS.UPLOAD_FILES, uploadFiles).then((upload) => {
        formValues("logo", upload[0]);
        setSelectedFile(upload[0]);
      });
    }
  };

  const onImageSelected2 = (imageType: string, file: File) => {
    if (imageType === "business") {
      setBusinessLogoSelected(true);
      setDocFiles((prevState: any) => ({
        ...prevState,
        file: file,
        name: file.name,
      }));
    }
  };

  const handleDocType = (docType: string) => {
    setDocumentType(docType);
  };

  function extractFilename(url: string) {
    if (url) {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname;
      // Decode the pathname to handle %20 and other encoded characters
      const decodedPathname = decodeURIComponent(pathname);
      return decodedPathname.split("/").pop();
    }
  }

  const downloadFileFromEndpoint = async (url: string, e: any) => {
    e.preventDefault();
    if (url) {
      try {
        const blob = await downloadFiles(url);
        const link = document.createElement("a");
        const objectURL = URL.createObjectURL(blob);
        link.href = objectURL;
        const filename = extractFilename(url);
        link.download = filename || "";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(objectURL);
      } catch (error) {
        showToast("Error downloading the file", "error");
      }
    }
  };

  const handleFileDelete = () => {
    dispatch(resetBusinessImage());
    setSelectedFile(null);
    setBusinessLogo(null);
    formValues("logo", null);
  };

  function formatDate(dateString: any) {
    const date = new Date(dateString);
    return format(date, "MM/dd/yyyy HH:mm:ss");
  }

  const handleAddDocument = () => {
    const newDoc: BusinessDocumentProp = {
      flag: "added",
      file: docFiles.file,
      type: documentType,
      description: documentDescription,
      name: docFiles.file.name,
      createdAt: new Date().toLocaleString(),
    };

    setBusinessDocs((prevDocs) => [...prevDocs, newDoc]);
    setDocumentDescription("");
    setDocumentType("");
    //changes
    setDocFiles({ file: null, name: "" });
    setBusinessDocument(null);
    setEditText("Set Null");
    setTimeout(() => {
      setEditText("");
    }, 3000);
  };

  const handleDeleteDocEntry = (index: number) => {
    setBusinessDocs((prevDocs) => {
      // Mark the document as deleted
      const updatedDocs = [...prevDocs];
      updatedDocs[index] = {
        ...updatedDocs[index],
        flag: "deleted",
      };
      return updatedDocs;
    });
  };

  const isAddButtonDisabled = !documentType || !docFiles.name;

  return (
    <div
      style={{
        width: "100%",
        marginTop: "5.73vh",
        padding: "0 4.6vw",
        overflowY: "scroll",
      }}
    >
      <div className="form-header-business form-header">
        <h2>{!viewBusiness ? "Edit Business" : "View Business"}</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-wrapper-3 row mb-4">
          <div className="image-wrapper col-4">
            {selectedFile ? (
              <div
                style={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  width: "10vw",
                  height: "10vw",
                }}
              >
                <img
                  style={{
                    borderRadius: "32px",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={selectedFile}
                  alt=""
                />
              </div>
            ) : viewBusiness ? (
              <div
                style={{
                  borderRadius: "32px",
                  overflow: "hidden",
                  width: "10vw",
                  height: "10vw",
                }}
              >
                <img
                  style={{
                    borderRadius: "32px",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={briefcase} // Ensure `briefcase` is a valid image source
                  alt=""
                />
              </div>
            ) : (
              <BusinessImage
                required={false}
                text="Business Logo"
                imageType="business"
                onImageSelected={onImageSelected}
                value={businessLogo}
                setValue={setBusinessLogo}
              />
            )}
          </div>
          <div
            className="image-wrapper col-8"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ color: "#ffffff", fontSize: "16px" }}>
              Business Logo
            </div>
            <span className="business-text" style={{ color: "#8E929A" }}>
              This should be your primary logo and should be no more than 50 KB
            </span>
            {selectedFile && !viewBusiness && (
              <div className="image-dropzone-wrapper col-8">
                <div className="selected-file">
                  <div className="file-info-business d-flex row">
                    <div className="d-flex flex-row col-8 p-2">
                      <div className="d-flex align-items-center justify-content-center b-img-icon">
                        {ImageIcon()}
                      </div>

                      <div className="p-1 text-truncate b-img-name">
                        {processLogoUrl(selectedFile)}
                      </div>
                    </div>

                    <div className="b-refresh-icon col-2 zzzz">
                      <BusinessImage
                        required={false}
                        text="Business Logo"
                        imageType="business"
                        onImageSelected={onImageSelected}
                        value={businessLogo}
                        setValue={setBusinessLogo}
                        dropzoneType="refresh"
                        setRefreshBusinessImageError={
                          setRefreshBusinessImageError
                        }
                      />
                    </div>
                    <div
                      className="b-del-icon col-2"
                      onClick={handleFileDelete}
                    >
                      <div>{DeleteIcon()}</div>
                    </div>
                  </div>
                </div>
                {refreshBusinessImageError && (
                  <div className="error-color">{refreshBusinessImageError}</div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="form-wrapper row">
          <div className="col-6">
            <Input
              type="text"
              id="businessName"
              placeholder="Please enter your company name"
              register={register}
              padding="9px 12px 9px"
              label="Business Name"
              error={(errors as any).businessName?.message}
              value={businessName}
              disabled={viewBusiness}
              onChange={(e) => {
                setBusinessName(e);
              }}
              maxLength={50}
            />
          </div>
          <div className="col-6">
            <Input
              type="text"
              id="businessWebsite"
              placeholder="http://www.yourwebsite.com"
              register={register}
              padding="9px 12px 9px"
              label="Website"
              disabled={viewBusiness}
              error={(errors as any).businessWebsite?.message}
              value={selectedBusiness?.business.businessWebsite}
              maxLength={50}
            />
          </div>
        </div>
        <div className="form-wrapper row">
          <div className="col-6">
            <Input
              type="text"
              id="primaryEmailAddress"
              value={selectedBusiness?.business.primaryEmailAddress}
              placeholder="Most suitable contact"
              register={register}
              disabled={viewBusiness}
              padding="9px 12px 9px"
              label="Primary Email Address"
              maxLength={100}
              error={
                (errors as any).primaryEmailAddress && "Email address required"
              }
            />
          </div>
          <div className="dropdown-wrapper col-6 business-categories">
            <label className="form-label">Business Category *</label>
            {viewBusiness ? (
              <input
                value={businessCategory1}
                className="input-value-field w-100"
                disabled
              />
            ) : (
              <CustomSelect
                options={BusinessCategory}
                width="300px"
                color="#8E929A"
                backgroundColor="#1F1F22"
                border="1px solid #8E929A"
                text="Please select a category"
                padding="10px 15px"
                top="16px"
                register={register}
                disabled={viewBusiness}
                option={businessCategory1}
                id="businessCategory"
                required={true}
                label="Business Category"
              />
            )}
          </div>
        </div>
        <div className="textBox-wrapper form-wrapper-2 row">
          <TextBox
            id="description"
            placeholder="Description of the business"
            register={register}
            label="Business Description"
            error={
              (errors as any).description && "Business Description required"
            }
            disabled={viewBusiness}
            border="1px solid #8E929A"
            color="#FFFFFF"
            backgroundColor="#1F1F22"
            value={selectedBusiness?.business.description}
            maxLength={999}
          />
        </div>
        <div className="form-wrapper row">
          <div className="col-6">
            <Input
              type="text"
              id="instagramHandler"
              placeholder="Please enter your business Instagram handle"
              register={register}
              padding="10px 12px 11px"
              disabled={viewBusiness}
              label="Instagram Handle"
              error={(errors as any).instagramHandler?.message}
              value={selectedBusiness?.business.instagramHandler}
              maxLength={50}
            />
          </div>
          <div className="col-6">
            <Input
              type="text"
              id="linkedInURL"
              placeholder="Please enter your business LinkedIn URL"
              register={register}
              padding="10px 12px 11px"
              label="LinkedIn URL"
              disabled={viewBusiness}
              error={(errors as any).linkedInURL?.message}
              value={selectedBusiness?.business.linkedInURL}
              maxLength={500}
            />
          </div>
        </div>
        <div className="event-address position-relative row ">
          <label className="form-label">Business Address *</label>
          <div>
            <span className="search-icon-illusion">{searchIcon()}</span>

            <GooglePlacesAutocomplete
              apiKey={API_KEY}
              selectProps={{
                placeholder: "Search Address",
                value: value,
                // value,
                onChange: handleSelect,
                styles: customStyles,
                isDisabled: viewBusiness,
              }}
            />
          </div>
        </div>
        <div className="event-form-wrapper row mt-4">
          <div className="address-line-1 col-6">
            <Input
              type="text"
              id="addressLine1"
              placeholder="Address line one"
              text="Address line one"
              register={register}
              padding="10px 12px 11px"
              disabled={viewBusiness}
              value={addressLine1}
              error={(errors as any).addressLine1?.message}
              maxLength={200}
            />
          </div>
          <div className="b-address-line-2 address-line-2 col-6">
            <input
              className="form-control i-field"
              type="text"
              disabled={viewBusiness}
              id="addressLine2"
              placeholder="Address line two"
              {...register("addressLine2", { required: false })}
              defaultValue={selectedBusiness?.business.addressLine2}
            />
          </div>
        </div>

        <div className="event-form-wrapper row">
          <div className="city col-6">
            <Input
              type="text"
              id="city"
              padding="9px 12px 9px"
              placeholder="City"
              disabled={viewBusiness}
              text="City"
              register={register}
              error={(errors as any).city?.message}
              value={city}
              maxLength={40}
            />
          </div>
          <div className="dropdown-wrapper col-6 business-countries">
            {viewBusiness ? (
              <input
                value={country}
                className="input-value-field w-100"
                disabled
              />
            ) : (
              <CustomSelect
                options={countries}
                width="300px"
                color="#8E929A"
                backgroundColor="#1F1F22"
                border="1px solid #8E929A"
                text="Country"
                padding="10px 15px"
                disabled={viewBusiness}
                top="16px"
                option={country}
                register={register}
                id="country"
                required={true}
                label="Country"
                height="100%"
              />
            )}
          </div>
        </div>

        <div className="event-form-wrapper row">
          <div className="post-code col-6">
            <Input
              type="text"
              id="postalCode"
              placeholder="Post or Zip Code"
              padding="10px 12px 11px"
              register={register}
              disabled={viewBusiness}
              error={(errors as any).postalCode && "Postal code required"}
              value={selectedBusiness?.business.postalCode}
              maxLength={10}
            />
            <div className="my-1 error-text-asd errorField">{postError}</div>
          </div>
        </div>
        <div className="ticket-header ticket-manager">
          <h2>Account Manager</h2>

          <div className="row">
            <div className="breaker col-12" style={{ width: "100%" }}>
              {Line()}
            </div>
          </div>
        </div>
        <div className="form-wrapper mt-4 row">
          <div className="col-6">
            <Input
              type="text"
              id="firstName"
              placeholder="First Name"
              register={register}
              padding="10px 12px 11px"
              disabled={viewBusiness}
              label="First Name"
              error={(errors as any).firstName?.message}
              value={selectedBusiness?.accountManager?.firstName}
              maxLength={50}
            />
          </div>
          <div className="col-6">
            <Input
              type="text"
              id="lastName"
              placeholder="Last Name"
              register={register}
              padding="10px 12px 11px"
              disabled={viewBusiness}
              label="Last Name"
              error={(errors as any).lastName?.message}
              value={selectedBusiness?.accountManager?.lastName}
              maxLength={50}
            />
          </div>
        </div>
        <div className="form-wrapper row">
          <div className="col-6">
            <Input
              type="text"
              id="emailAddress"
              placeholder="Business email address"
              register={register}
              disabled={viewBusiness}
              padding="10px 12px 11px"
              label="Email Address"
              error={(errors as any).emailAddress?.message}
              value={selectedBusiness?.accountManager?.email}
              maxLength={50}
            />
          </div>
          <div className="col-6">
            <Input
              type="text"
              id="phoneNumber"
              placeholder="Business phone number"
              register={register}
              disabled={viewBusiness}
              padding="10px 12px 11px"
              label="Phone Number"
              error={(errors as any).phoneNumber?.message}
              value={selectedBusiness?.accountManager?.phoneNumber}
              maxLength={30}
            />
          </div>
        </div>
        <div className="form-wrapper row">
          <div className="col-6">
            <Input
              type="text"
              id="jobTitle"
              placeholder="Title (role) "
              register={register}
              padding="10px 12px 11px"
              disabled={viewBusiness}
              label="Job Title"
              error={(errors as any).jobTitle?.message}
              value={selectedBusiness?.accountManager?.jobTitle}
              maxLength={50}
            />
          </div>
        </div>

        <div className="ticket-header ticket-manager">
          <div className="attached-docs-header">
            <h2>Attached Documents</h2>
            <h6
              className="mt-2"
              style={{ color: "#8E929A", fontSize: "0.8rem" }}
            >
              Please find the attached business documents in the table below.
            </h6>
            <div className="breaker col-12" style={{ width: "100%" }}>
              {Line()}
            </div>
          </div>
          <table
            className="table table-hover"
            style={{ backgroundColor: "#1A1A1C" }}
          >
            <thead className="t-doc-header">
              <tr>
                <th>TYPE</th>
                <th>DESCRIPTION</th>
                <th>FILENAME</th>
                <th>DATE CREATED</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody className="file-table">
              {businessDocs.map(
                (document, index) =>
                  document.flag !== "deleted" && (
                    <tr key={index}>
                      <td
                        style={{
                          color: "white",
                          fontSize: "11px",
                          width: "20%",
                        }}
                      >
                        {document.type}
                      </td>
                      <td
                        style={{
                          color: "white",
                          fontSize: "11px",
                          width: "20%",
                        }}
                      >
                        {document.description}
                      </td>
                      <td
                        style={{
                          color: "white",
                          fontSize: "11px",
                          width: "15%",
                        }}
                      >
                        {document.name ? document.name : document.file?.name}
                      </td>
                      <td
                        style={{
                          color: "white",
                          fontSize: "11px",
                          width: "20%",
                        }}
                      >
                        {formatDate(document.createdAt)}
                      </td>
                      {!viewBusiness && (
                        <td style={{ width: "5%" }}>
                          <button
                            className="edit-button"
                            role="button"
                            name="download"
                            onClick={(e) =>
                              downloadFileFromEndpoint(
                                document.url ? document.url : "",
                                e
                              )
                            }
                          >
                            Download
                          </button>
                        </td>
                      )}
                      {!viewBusiness && (
                        <td style={{ width: "5%" }}>
                          <button
                            className="edit-button"
                            role="button"
                            name="delete"
                            onClick={() => handleDeleteDocEntry(index)}
                          >
                            Delete
                          </button>
                        </td>
                      )}
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>

        {!viewBusiness && (
          <div>
            <div className="attached-docs-header">
              <h2>Attach Documents</h2>
              <div className="breaker col-12" style={{ width: "100%" }}>
                {Line()}
              </div>
            </div>
            <div className="row mb-8">
              <div className="col-4 d-flex flex-column justify-content-start b-imgg">
                {" "}
                {/* If image is selected, use col-6 */}
                <BusinessDocument
                  required={false}
                  text="Business Document"
                  formSubmitted={false}
                  imageType="business"
                  onImageSelected={onImageSelected2}
                  value={businessDocument}
                  setValue={setBusinessDocument}
                  editText={editText}
                />
              </div>
              <div className="col-8 d-flex flex-column justify-content-center">
                {" "}
                {/* If image is selected, use col-6 */}
                <div
                  style={{
                    color: "#ffffff",
                    fontSize: "16px",
                    marginLeft: "20px",
                  }}
                >
                  Business Document *
                </div>
                <span
                  style={{
                    color: "#8E929A",
                    fontSize: "11px",
                    marginLeft: "20px",
                    marginTop: "5px",
                  }}
                >
                  Please upload business documents and should be no more than 10
                  MB.
                </span>
              </div>
            </div>
            <form>
              <div className="row mb-8">
                <div className="d-flex flex-column col-8 categories-wrapper">
                  <label className="form-label text-truncate">
                    Document Type *
                  </label>
                  <SelectWrapper
                    options={documentTypes}
                    value={documentType}
                    setValue={handleDocType}
                    placeholder="Please select document type"
                  />
                </div>
              </div>
              <div className="row mb-8">
                <div className="col-12">
                  <label className="form-label">Document Description</label>
                  <textarea
                    value={documentDescription}
                    onChange={(e) => setDocumentDescription(e.target.value)}
                    id="businessDesign"
                    className="text-area-2"
                    placeholder="Description of the document"
                    maxLength={999}
                  />
                </div>
              </div>
              <div className="row">
                <div className="next-button-wrapper">
                  <Button
                    title={"ADD"}
                    type={"submit"}
                    backgroundColor="white"
                    color="black"
                    border="none"
                    borderRadius="6px"
                    padding="10px 48px"
                    fontSize="12px"
                    fontWeight="600"
                    onButtonClick={handleAddDocument}
                    disabled={isAddButtonDisabled}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
        <div
          className="button-wrapper"
          style={{
            marginBottom: "5.73vh",
          }}
        >
          <div className="back-btn-area">
            <Button
              title={viewBusiness ? "EXIT" : "CANCEL"}
              type={"button"}
              backgroundColor="#1A1A1C"
              color="white"
              border="1px solid white"
              borderRadius="6px"
              padding="10.12px 47.3px"
              fontSize="12px"
              fontWeight="600"
              onButtonClick={() => {
                onClose();
              }}
            />
          </div>
          {!viewBusiness && (
            <div className="next-btn-area">
              <button
                disabled={loading}
                type="submit"
                className="benefit-submit-button"
              >
                {loading === true ? <Loader /> : "SUBMIT"}
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default EditBusinessForm;
