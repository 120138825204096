import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import persistConfig from "./persistConfig";
import rootReducer from "./slices";

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({reducer:persistedReducer,
    
});
const persistor = persistStore(store);

export { persistor, store };
