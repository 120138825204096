import { post } from "./api";
import { ISortedMembershipsRequest } from "@/@Interfaces/IMembership";
import URLS from "../constants/api";


export const getApplicants = async (
  page: number,
  pageLimit: number,
  membershipStatus: string,
  sortBy: string,
  sortOn: string,
  search?: string,
  searchOn?: string
) => {
  const requestData: ISortedMembershipsRequest = {
    pageNumber: page,
    pageLimit: pageLimit,
    membershipStatus: membershipStatus,
    sortBy: sortBy,
    sortOn: sortOn,
    search: search,
    searchOn : searchOn
  };

  const response = await post(URLS.APPLICATIONS, requestData);
  return response;
};
