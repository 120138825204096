import React, { useState } from "react";
import "./style.css";
import Button from "../Button";
import { CampaignIcon, ExitIcon, Line } from "@/constants/utils/svgs";
import Modal from "../ExitModal";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { NavigationPaths } from "@/constants/paths";
import Toast from "../Toast";
import Loader from "../Spinner";
import { StepThreeProps } from "@/@Interfaces/BusinessStepThreeProps";

const StepThreeBusiness: React.FC<StepThreeProps> = ({
  loading,
  setSteps,
  handleSubmitBusiness,
  accountManagerFirstName,
  setAccountManagerFirstName,
  accountManagerLastName,
  setAccountManagerLastName,
  accountManagerEmailAddress,
  setAccountManagerEmailAddress,
  accountManagerPhoneNumber,
  setAccountManagerPhoneNumber,
  accountManagerJobTitle,
  setAccountManagerJobTitle,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();

  // Form hook
  const {
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onChange",
  });

  // Event handlers
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleExit = () => {
    navigate(NavigationPaths.CAMPAIGNS);
  };

  const handleBack = () => {
    setSteps(2);
  };

  const handleNext = () => {
    setSteps(4);
  };
  
  
  //call the submit form function if all fields contains valid data
  const handleFinishClick = () => {
    const isValid = validateEmail(accountManagerEmailAddress);
    setEmailError(isValid ? "" : "*Invalid email address");
    if (isValid) {
      setFormSubmitted(true);
    }
    setFormSubmitted(true);

    if (
      accountManagerFirstName &&
      accountManagerEmailAddress &&
      isValid &&
      accountManagerLastName &&
      accountManagerJobTitle &&
      accountManagerPhoneNumber
    ) {
      setSteps(4)
      // handleSubmitBusiness();
    }
  };

  const onClose = () => {
    setShowToast(false);
  };

  //check email format
  const validateEmail = (email: string) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setAccountManagerEmailAddress(email);

    const isValid = validateEmail(email);
    setEmailError(isValid ? "" : "*Invalid email address");
  };

  const handleJobChange = (e: React.ChangeEvent<HTMLInputElement>) => { 
    const inputText = e.target.value.replace(/[^a-zA-Z0-9\s-_]/g, ''); // Remove special characters
    setAccountManagerJobTitle(inputText);

  };


  // JSX rendering
  return (
    <section className="ticket-details-section">
      <div className="row tkt">
        <div className="col-lg-6 col-md-9 p-0 adjust-height-fhbsh ">
          <div className="ticket-content-container">
            <div className="ticket-section section-profile">
              <div className="ticket-details-header">
                <h2>Add a business</h2>
                <p>Step 3 of 4</p>
              </div>
              <Toast
                role="toast"
                content={toastMessage}
                show={showToast}
                onClose={onClose}
              />
              <div className="ticket-content">
                <div className="tickets">
                  <div className="ticket-header">
                    <h2>Account Manager</h2>
                    <h6 style={{ color: "#8E929A", fontSize: "0.8rem" }}>
                      This should be the person managing the account and our
                      first point of contact.
                    </h6>
                    <div className="breaker w-100">{Line()}</div>
                  </div>
                  <div className="ticket-form">
                    <form >
                      <div className="event-form-wrapper row mb-4">
                        <div className="d-flex flex-column col-6 ">
                          <div className="form-label input-label-f3s mb-3">
                            First Name *
                          </div>
                          <input
                            type="text"
                            value={accountManagerFirstName}
                            onChange={(e) =>
                              setAccountManagerFirstName(e.target.value)
                            }
                            name="firstName"
                            className="input-value-field"
                            placeholder="First name"
                            maxLength={40}
                          />
                          {formSubmitted && !accountManagerFirstName && (
                            <div className="my-1 error-text-asd errorField">
                              *First name required
                            </div>
                          )}
                        </div>

                        <div className="d-flex flex-column col-6 ">
                          <div className="form-label input-label-f3s mb-3">
                            Last Name *
                          </div>
                          <input
                            type="text"
                            value={accountManagerLastName}
                            onChange={(e) =>
                              setAccountManagerLastName(e.target.value)
                            }
                            name="lastName"
                            className="input-value-field"
                            placeholder="Last name"
                            maxLength={40}
                          />
                          {formSubmitted && !accountManagerLastName && (
                            <div className="my-1 error-text-asd errorField">
                              *Last name required
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="event-form-wrapper row mb-4">
                        <div className="d-flex flex-column col-6 ">
                          <div className="form-label input-label-f3s mb-3">
                            Email Address *
                          </div>
                          <input
                            type="email"
                            value={accountManagerEmailAddress}
                            onChange={handleEmailChange}
                            name="emailAddress"
                            className="input-value-field"
                            placeholder="Business email address"
                            maxLength={50}
                          />
                          {accountManagerEmailAddress != "" && emailError && (
                            <div className="my-1 error-text-asd errorField">
                              {emailError}
                            </div>
                          )}
                          {accountManagerEmailAddress == "" &&
                            formSubmitted && (
                              <div className="my-1 error-text-asd errorField">
                                *Email address required
                              </div>
                            )}
                        </div>

                        <div className="d-flex flex-column col-6 ">
                          <div className="form-label input-label-f3s mb-3">
                            Phone Number *
                          </div>
                          <input
                            type="text"
                            value={accountManagerPhoneNumber}
                            onChange={(e) => {
                              const inputText = e.target.value.replace(
                                /\D/g,
                                ""
                              ); // Remove non-numeric characters
                              setAccountManagerPhoneNumber(inputText);
                            }}
                            name="phoneNumber"
                            className="input-value-field"
                            placeholder="Business phone number"
                            pattern="[0-9]*" // Allow only numeric characters
                            maxLength={30}
                          />

                          {formSubmitted && !accountManagerPhoneNumber && (
                            <div className="my-1 error-text-asd errorField">
                              *Phone number required
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="event-form-wrapper row mb-4">
                        <div className="d-flex flex-column col-6 ">
                          <div className="form-label input-label-f3s mb-3">
                            Job Title *
                          </div>
                          <input
                            type="text"
                            value={accountManagerJobTitle}
                            onChange={handleJobChange}
                            name="jobTitle"
                            className="input-value-field"
                            placeholder="Title (role)"
                            maxLength={40}
                          />
                          {formSubmitted && !accountManagerJobTitle && (
                            <div className="my-1 error-text-asd errorField">
                              *Job title required
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {showSpinner && <Loader />}
              <div className="button-wrapper">
                <div className="ticket-back">
                  <Button
                    title={"BACK"}
                    type={"button"}
                    backgroundColor="#1A1A1C"
                    color="white"
                    border="1px solid white"
                    borderRadius="6px"
                    padding="10px 48px"
                    fontSize="12px"
                    fontWeight="600"
                    onButtonClick={handleBack}
                  />
                </div>
                <div className="ticket-finish">
                  <button
                    type="submit"
                    onClick={handleSubmit(handleFinishClick)}
                    // onClick={handleNext}
                    className="benefit-submit-button"
                  >
                    NEXT
                    {/* {loading === true ? <Loader /> : "NEXT"} */}
                  </button>
                  <Modal
                    showModal={showModal}
                    handleClose={handleCloseModal}
                    handleExit={handleExit}
                    buttonTitle="EXIT"
                    title="Are you sure you want to exit?"
                    message="Are you sure you want to exit creating a new business? The information will not be saved."
                  />
                </div>
              </div>
            </div>
          </div>
          {/* progress bar */}
          <div style={{ position: "relative" }}>
            <div className="mt-4 row stage-bar">
              <div className="col-3 step-1-event"></div>
              <div className="col-3 step-1-event"></div>
              <div className="col-3 step-2"></div>
              <div className="col-3"></div>
            </div>
          </div>
        </div>
        {/* Exit Icon */}
        <div className="col-lg-6 col-md-3 p-0 form-image-right">
          <div className="image-section-3">
            <div className="overlay"></div>
            <span onClick={handleOpenModal} className="exit-icon">
              {ExitIcon()}
            </span>
            <div className="campaign-icon">{CampaignIcon()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepThreeBusiness;
