import URLS from '@/constants/api';
import { post } from "./api";

export const getIndividualBenefit = async (id:string | undefined) => {
  const requestData = {
      id: id
      };

  const response = await post(URLS.GET_INDIVIDUAL_BENEFIT, requestData);
  return response;
};

