import React from "react";
import "./style.css";
import PropTypes from "prop-types";
import IToastProps from "@/@Interfaces/Toast";

const Toast: React.FC<IToastProps> = (props) => {
  return (
    <div
      className={`toast align-items-center ${props.show ? "show" : ""}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="d-flex">
        <div className="toast-body">{props.content}</div>
        <button
          type="button"
          className="btn-close me-2 m-auto"
          aria-label="Close"
          onClick={() => props.onClose()}
        ></button>
      </div>
    </div>
  );
};

Toast.propTypes = {
  show: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Toast;
