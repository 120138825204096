import React from "react";
import "./style.css"; 
import { ModalProps } from "@/@Interfaces/ModalProps";
import Button from "../Button";

const Modal: React.FC<ModalProps> = ({
  showModal,
  handleClose,
  handleExit,
  title,
  message,
  buttonTitle
}) => {
  return (
    <div
      className={`modal fade custom-modal ${showModal ? "show" : ""}`}
      id="exampleModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <h5 className="modal-title">{title}</h5>
            <p className="modal-message">{message}</p>
          </div>
          <div className="modal-footer-2">
            <Button
              title={"BACK"}
              type={"button"}
              backgroundColor="#1A1A1C"
              color="white"
              border="1px solid white"
              borderRadius="6px"
              padding="6px 35px"
              fontSize="12px"
              fontWeight="700"
              onButtonClick={handleClose}
            />
            <Button
              title={buttonTitle}
              type={"button"}
              backgroundColor="white"
              color="black"
              border="none"
              borderRadius="6px"
              padding="6px 35px"
              fontSize="12px"
              fontWeight="700"
              onButtonClick={handleExit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
