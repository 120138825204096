import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formData: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    jobTitle: '',
  },
};

const formSlice = createSlice({
  name: 'account_manager_details',
  initialState,
  reducers: {
    updateAccountManagerData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetAccountManagerData: (state) => {
      state.formData = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        jobTitle: '',
      };
    },
  },
});

export const { updateAccountManagerData, resetAccountManagerData } = formSlice.actions;
export default formSlice.reducer;
