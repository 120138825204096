import URLS from '@/constants/api';
import { patch} from "./api";

export const updateEvent = async (
    id: string,
    updatedEventData: any
  ) => {
    const endpoint = `${URLS.UPDATE_EVENT}/?eventId=${id}`;
    const response = await patch(endpoint, updatedEventData);
    return response;
  };
  
