import React, { useState } from "react";
import SelectWrapper from "../selectWrapper";
import Button from "../Button";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import { NavigationPaths } from "@/constants/paths";
import { CampaignIcon, ExitIcon, Line } from "@/constants/utils/svgs";
import {
  DocumentFormData,
  StepFourProps,
} from "@/@Interfaces/BusinessStepFourProps";
import BusinessDocument from "../BusinessDocument";
import { documentTypes } from "@/constants/docTypes";
import Loader from "../Spinner";

const StepFourBusiness: React.FC<StepFourProps> = ({
  loading,
  setLoading,
  businessDocument,
  setBusinessDocument,
  documents,
  setDocuments,
  docFiles,
  setDocFiles,
  setDocumentType,
  documentType,
  documentDescription,
  setDocumentDescription,
  steps,
  setSteps,
  handleSubmitBusiness,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [editText, setEditText] = useState("");
  const [businessLogoSelected, setBusinessLogoSelected] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const navigate = useNavigate();

  const handleExit = () => {
    navigate(NavigationPaths.BUSINESSES);
  };

  const handleBack = () => {
    setSteps(3);
  };

  const handleFinishBusiness= () => {
    // if(documents.length > 0 ){
    //       handleSubmitBusiness();
    // }
    handleSubmitBusiness();
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onImageSelected = (imageType: string, file: File) => {
    if (imageType === "business") {
      setBusinessLogoSelected(true);
      setDocFiles((prevState: any) => ({
        ...prevState,
        file: file,
        name: file.name
      }));
    }
  };

  const handleAddDocument = (e: any) => {
    setFormSubmitted(true);
    e.preventDefault();
    if(businessDocument && documentType){
      const newDocument: DocumentFormData = {
         file: docFiles,
         type: documentType,
         description: documentDescription,
         createdAt: new Date().toLocaleString(),
       };
        setDocuments([...documents, newDocument]);
        setDocumentDescription('')
        setDocumentType('')
       //changes
       setDocFiles({ file: null, name: '' }); 
       setBusinessDocument(null);
       setEditText("Set Null")
       setFormSubmitted(false);
       setTimeout(() => {
         setEditText("");
       }, 3000);  
    }
 
  };

  const handleDocType = (docType: string) => {
    setDocumentType(docType);
  };

  const handleDeleteDocEntry = (index: number) => {
    const updatedDocuments = [...documents];
    updatedDocuments.splice(index, 1);
    setDocuments(updatedDocuments);
  };

  const isAddButtonDisabled = !documentType || !docFiles.name;



  return (
    <section className="create-campaign-section">
      <div className="row">
        <div className="col-lg-6 col-md-9 adjust-height-fhbsh p-0">
          <div className="form-section">
            <div className="form-header">
              <h2>Attach Documents</h2>
              <p>Step 4 of 4</p>
            </div>
            <div className="row mb-8">
              <div className="col-4 d-flex flex-column justify-content-start b-imgg">
                {" "}
                {/* If image is selected, use col-6 */}
                <BusinessDocument
                  required={false}
                  text="Business Document"
                  formSubmitted={false}
                  imageType="business"
                  onImageSelected={onImageSelected}
                  value={businessDocument}
                  setValue={setBusinessDocument}
                  editText={editText}
                />
                     {formSubmitted && !businessDocument && (
            <div className="my-1 error-text-asd errorField">
              *Business document required
            </div>
          )}
              </div>
              <div className="col-8 d-flex flex-column justify-content-center">
                {" "}
                {/* If image is selected, use col-6 */}
                <div
                  style={{
                    color: "#ffffff",
                    fontSize: "16px",
                    marginLeft: "20px",
                  }}
                >
                  Business Document *
                </div>
                <span
                  style={{
                    color: "#8E929A",
                    fontSize: "11px",
                    marginLeft: "20px",
                    marginTop: "5px",
                  }}
                >
                  Please upload business documents and should be no more
                  than 10 MB.
                </span>
              </div>
            </div>
            <form>
              <div className="row mb-8">
                <div className="d-flex flex-column col-8 categories-wrapper">
                  <label className="form-label text-truncate">
                    Document Type *
                  </label>
                  <SelectWrapper
                    options={documentTypes}
                    value={documentType}
                    setValue={handleDocType}
                    placeholder="Please select document type"
                  />
                    {formSubmitted && !documentType && (
            <div className="my-1 error-text-asd errorField">
              *Document type required
            </div>
          )}
                </div>
              </div>
              <div className="row mb-8">
                <div className="col-12">
                  <label className="form-label">Document Description</label>
                  <textarea
                    value={documentDescription}
                    onChange={(e) => setDocumentDescription(e.target.value)}
                    id="businessDesign"
                    className="text-area-2"
                    placeholder="Description of the document"
                    maxLength={999}
                  />
                </div>
              </div>
              <div className="row">
                <div className="next-button-wrapper">
                  <Button
                    title={"ADD"}
                    type={"submit"}
                    backgroundColor="white"
                    color="black"
                    border="none"
                    borderRadius="6px"
                    padding="10px 48px"
                    fontSize="12px"
                    fontWeight="600"
                    onButtonClick={handleAddDocument}
                    // disabled={isAddButtonDisabled} 
                  />
                </div>
              </div>
            </form>
            <div>
              <div className="attached-docs-header">
                <h2>Attached Documents</h2>
                <h6
                  className="mt-2"
                  style={{ color: "#8E929A", fontSize: "0.8rem" }}
                >
                  Please find the attached business documents in the table
                  below.
                </h6>
                <div className="breaker w-100">{Line()}</div>
              </div>
              <table
                className="table table-hover"
                style={{ backgroundColor: "#1A1A1C" }}
              >
                <thead className="t-doc-header">
                  <tr>
                    <th>TYPE</th>
                    <th>DESCRIPTION</th>
                    <th>FILENAME</th>
                    <th>DATE CREATED</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((document: any, index: any) => (
                    <tr key={index}>
                      <td style={{ color: "white", fontSize: "11px" }}>
                        {document.type}
                      </td>
                      <td style={{ color: "white", fontSize: "11px" }}>
                        {document.description}
                      </td>
                      <td style={{ color: "white", fontSize: "11px" }}>
                        {document.file
                          ? document.file.name
                          : "No file selected"}
                      </td>
                      <td style={{ color: "white", fontSize: "11px" }}> {document.createdAt}</td>
                      <td>
                        <button
                          className="edit-button "
                          role="button"
                          name="delete"
                          onClick={() => handleDeleteDocEntry(index)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="button-wrapper">
                <div className="ticket-back">
                  <Button
                    title={"BACK"}
                    type={"button"}
                    backgroundColor="#1A1A1C"
                    color="white"
                    border="1px solid white"
                    borderRadius="6px"
                    padding="10px 48px"
                    fontSize="12px"
                    fontWeight="600"
                    onButtonClick={handleBack}
                  />
                </div>
                <div className="ticket-finish">
                    <button
                    type="submit"
                    onClick={handleFinishBusiness}
                    disabled={loading}
                    className="benefit-submit-button"
                  >
                    {loading === true ? <Loader /> : "FINISH"}
                  </button>
                     
                  <Modal
                    showModal={showModal}
                    handleClose={handleCloseModal}
                    buttonTitle="EXIT"
                    handleExit={handleExit}
                    title="Are you sure you want to exit?"
                    message="Are you sure you want to exit creating a new business? The information will not be saved."
                  />
                </div>
              </div>
            </div>
          </div>

          {/* progress bar */}
          <div style={{ position: "relative" }}>
            {" "}
            <div className="mt-4 row stage-bar">
              <div className="col-3 step-1"></div>
              <div className="col-3 step-1"></div>
              <div className="col-3 step-1"></div>
              <div className="col-3 step-2"></div>
            </div>
          </div>
        </div>
        {/* Exit Modal */}
        <div className="col-lg-6 col-md-3 p-0 form-image-right">
          <div className="image-section">
            <div className="overlay"></div>
            <span onClick={handleOpenModal} className="exit-icon">
              {ExitIcon()}
            </span>
            <div className="campaign-icon">{CampaignIcon()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepFourBusiness;
