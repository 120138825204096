import URLS from '@/constants/api';
import { patch} from "./api";

export const updateEBenefit = async (
    id: string,
    updatedBenefitData: any
  ) => {
    const endpoint = `${URLS.UPDATE_BENEFIT}/?id=${id}`;
    const response = await patch(endpoint, updatedBenefitData);
    return response;
  };
  
