import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-data-grid/lib/styles.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { enquiryTableHeaders } from "@/constants/enquiryTableHeaders";
import { EnquiryTableRow } from "@/@Interfaces/TableProps";
import { getEnquiries } from "@/services/getEnquiries";
import { useSelector } from "react-redux";
import Loader from "../Spinner";
import { options } from "@constants/options";
import TableFooter from "../TableFooter";
import TruncateTextWithTooltip from "../Tooltip";
import showToast from "../cogo-toast";
import { DownloadIcon } from "@/constants/utils/svgs";
import { downloadEnquiryCsvAPI } from "@/services/downloadEnquiry";

const EnquiryTab: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [refetchToggle, setRefetchToggle] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [tableData, setTableData] = useState<EnquiryTableRow[]>([]);

  const [sortOn, setSortOn] = useState<string>("fullName");
  const [sortBy, setSortBy] = useState<"asc" | "desc">("asc");

  const dropdownRef2 = useRef<HTMLDivElement>(null);

  const dropdownOption = useSelector(
    (state: any) => state.applicant.dropdownOption
  );

  const pageLimit = options.includes(dropdownOption) ? dropdownOption : "25";

  useEffect(() => {
    setCurrentPage(1);
  }, [dropdownOption]);

  useEffect(() => {
    const pl =
      typeof dropdownOption === "number" && !isNaN(dropdownOption)
        ? dropdownOption
        : 25;

    fetchData(1, pl, "", sortOn, sortBy);
  }, [dropdownOption, refetchToggle, sortBy, sortOn]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //fetch page Data on the change of page number
  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    const pl =
      typeof dropdownOption === "number" && !isNaN(dropdownOption)
        ? dropdownOption
        : 25;
    fetchData(pageNumber, pl, "", "", sortBy);
  };

  //set previous page number
  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  // get Business data
  const fetchData = async (
    page = 1,
    limit: number,
    search = "",
    sortBy = "",
    sortOrder = ""
  ) => {
    try {
      setShowSpinner(true);
      setTableData([]); //setting table data empty so that loader is shown only instead of showing at the bottom of the table where loader is not visible
      const response = await getEnquiries(
        page,
        limit,
        search,
        sortOrder,
        sortBy
      );

      setTableData(response?.data?.contacts);
      setTotalRecords(response?.data?.total);
      setTotalPages(response?.data?.totalPages);
    } catch (error: any) {
      setShowSpinner(false);
      if (error.message === "Network Error") {
        showToast(
          "It seems you're offline. Please check your connection and try again.",
          "error"
        );
      } else {
        showToast("Something went wrong. ", "error");
      }
    }
    setShowSpinner(false);
  };

  //sorting
  const handleHeaderClick = (headerKey: string) => {
    setCurrentPage(1);
    if (sortOn === headerKey) {
      setSortBy((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortBy("desc");
      setSortOn(headerKey);
    }
  };

  //search business
  const handleSeachContact = (value: string) => {
    if (value.length >= 3 || value === "") {
      setCurrentPage(1);
      fetchData(1, pageLimit, value, sortOn, sortBy);
    }
  };
  const handleSubmit2 = (e: React.FormEvent) => {
    e.preventDefault();
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const downloadEnquiryCsv = async () => {
    try {
      setShowSpinner(true);
      const response = await downloadEnquiryCsvAPI();
      const url = window.URL.createObjectURL(response);
      const a = document.createElement("a");
      a.href = url;
      const latestDate = new Date();
      const formattedLatestDate = latestDate.toLocaleString();
      a.download = `Enquiries_${formattedLatestDate}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setShowSpinner(false);
      showToast("Error in downloading file.", "error");
    } finally {
      setShowSpinner(false);
    }
  };

  //Email the user
  const handleEmailClick = (email: string) => {
    const mailtoLink = `mailto:${email}`;
    const anchor = document.createElement("a");
    anchor.href = mailtoLink;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  return (
    <section className="table-section d-flex flex-column justify-content-between">
      <div className="table-header d-flex flex-1 align-items-center flex-wrap justify-content-between">
        <h4 className="t-head">Enquiry List</h4>
        <div className="search-bar d-flex flex-wrap gap-3">
          <div>
            <form data-testid="search-form" onSubmit={handleSubmit2}>
              <div className="benefit-search-content">
                <FontAwesomeIcon
                  className="search-icon"
                  icon={faSearch}
                  style={{}}
                />
                <input
                  style={{ height: "40px" }}
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    const { value } = e.target;
                    handleSeachContact(value);
                  }}
                />
              </div>
              <button type="submit" style={{ display: "none" }}>
                Search
              </button>
            </form>
          </div>
          <div
            data-testid="download"
            className="download-icon"
            onClick={toggleDropdown}
          >
            {DownloadIcon()}
          </div>
        </div>
      </div>

      {showDropdown && (
        <div
          id="dropdown"
          className="csv-dropdown d-flex flex-column justify-content-center"
          ref={dropdownRef2}
        >
          <div onClick={downloadEnquiryCsv}>Export as .CSV</div>
        </div>
      )}

      <div className="table-container flex-grow">
        <table
          id="table"
          className="table table-hover"
          style={{ backgroundColor: "#1A1A1C" }}
        >
          <thead className="t-header">
            <tr>
              {enquiryTableHeaders.map((header) => (
                <th
                  className="t-columns"
                  key={header.key}
                  scope="col"
                  style={header.style}
                  onClick={() => handleHeaderClick(header.key)}
                >
                  {header.name}
                </th>
              ))}
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
              <th
                className="t-columns"
                scope="col"
                style={{ minWidth: "50px" }}
              ></th>
            </tr>
          </thead>
          <tbody className="t-body">
            {!showSpinner && tableData.length === 0 && searchTerm.length > 0 ? (
              <td colSpan={enquiryTableHeaders.length + 1}>
                <div className="no-records-found">No records found.</div>
              </td>
            ) : !showSpinner &&
              tableData.length === 0 &&
              searchTerm.length === 0 ? (
              <td colSpan={enquiryTableHeaders.length + 1}>
                <div className="no-records-found">
                  There are currently no Contact Us Detail.
                </div>
              </td>
            ) : (
              <>
                {" "}
                {tableData.map((rowData, index) => (
                  <tr key={index}>
                    {enquiryTableHeaders.map((header) => (
                      <td
                        style={{
                          verticalAlign: "middle",
                        }}
                        key={header?.key}
                      >
                        {header?.key === "firstName" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                paddingLeft: "10px",
                                placeSelf: "center",
                              }}
                            >
                              {" "}
                              <TruncateTextWithTooltip
                                text={rowData.firstName}
                              />
                            </div>
                          </div>
                        ) : header.key === "lastName" ? (
                          <div className="t-business-category">
                            <span style={{ fontWeight: 700 }}>
                              {rowData.lastName}
                            </span>
                          </div>
                        ) : header.key === "email" ? (
                          <div
                            onClick={() => handleEmailClick(rowData?.email)}
                            className=""
                          >
                            <span>{rowData?.email}</span>
                          </div>
                        ) : header.key === "dateCreated" ? (
                          <div className="">
                            <span>{formatDate(rowData?.dateCreated)}</span>
                          </div>
                        ) : null}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        {showSpinner && (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )}
      </div>

      <TableFooter
        totalRecords={totalRecords}
        onPageChange={onPageChange}
        handlePrev={handlePrev}
        handleNext={handleNext}
        totalPages={totalPages}
        currentPage={currentPage}
      />
    </section>
  );
};

export default EnquiryTab;
