import { string } from "yup";
import { post } from "./api";
import URLS from "@/constants/api";
// ICreateTicket
export const createEventWithImage = async (
  eventId: string,
  feedImage: string,
  pageImage: string,
  image: string,
  videoUrl: string,
  venueImage: string
) => {
  const requestData = {
    eventId: eventId,
    feedImage: feedImage,
    pageImage: pageImage,
    videoUrl: videoUrl,
    venueImage: venueImage,
    image: image,
  };
  const response = await post(URLS.UPDATE_EVENT_IMAGES, requestData);
  return response;
};
