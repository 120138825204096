type TableColumn = {
  name: string;
  style: {
    width: string;
    textAlign?: "left" | "right" | "center" | "justify" | "initial" | "inherit";
  };
  key: string;
};

export const RSVPTableHeaders: TableColumn[] = [
  { name: "GUEST", style: { width: "15%" }, key: "firstName" },
  { name: "EMAIL", style: { width: "20%" }, key: "email" },
  { name: "INSTAGRAM", style: { width: "10%" }, key: "instagram" },
  { name: "GUEST COUNT", style: { width: "10%" }, key: "additionalGuestCount" },
  { name: "DATE ADDED", style: { width: "10%" }, key: "createdDate" },
  { name: "SOURCE CHANNEL", style: { width: "10%" }, key: "channel" },
];
